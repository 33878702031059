export default {
    // vuetify
    calculateWidths: Boolean,
    caption: String,
    customFilter: Function,
    customGroup: Function,
    customSort: Function,
    dark: Boolean,
    dense: Boolean,
    disableFiltering: Boolean,
    disablePagination: Boolean,
    disableSort: Boolean,
    expandIcon: String,
    expanded: Array,
    fixedHeader: Boolean,
    footerProps: Object,
    groupBy: [String, Array],
    groupDesc: [Boolean, Array],
    headerProps: Object,
    headers: [Array, Object],
    headersLength: Number,
    height: [Number, String],
    hideDefaultFooter: Boolean,
    hideDefaultHeader: Boolean,
    itemClass: [String, Function],
    itemKey: { type: String, default: 'id' },
    items: Array,
    itemsPerPage: Number,
    light: Boolean,
    loaderHeight: [Number, String],
    loading: Boolean,
    loadingText: String,
    locale: String,
    mobileBreakpoint: [Number, String],
    multiSort: Boolean,
    mustSort: Boolean,
    noDataText: String,
    noResultsText: String,
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
    page: Number,
    search: String,
    selectableKey: String,
    serverItemsLength: Number,
    showExpand: Boolean,
    showGroupBy: Boolean,
    showSelect: Boolean,
    singleExpand: Boolean,
    sortBy: [String, Array],
    sortDesc: [Boolean, Array],
    value: Array,
    // custom
    storageKey: String,
    paginationOptions: {
      type: Array,
      default () {
        return paginationOptions
      },
    },
    wordOperations: {
      type: Array,
      default () {
        return wordOperations
      },
    },
}

var paginationOptions = [
  { text: '5 на странице', value: 5 },
  { text: '10 на странице', value: 10 },
  { text: '25 на странице', value: 25 },
  { text: '50 на странице', value: 50 },
  { text: '100 на странице', value: 100 },
  { text: '150 на странице', value: 150 },
  { text: '250 на странице', value: 250 },
  { text: '500 на странице', value: 500 },
]

var wordOperations = ['операция', 'операции', 'операций']
