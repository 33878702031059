import LocaleCurrency from 'locale-currency'

export default {
    data () {
        return {
            // currency: null,
        }
    },
    computed: {
        localeCurrency () {
            const localeCurrency = LocaleCurrency.getCurrency(
                this.$i18n.localeMapped,
            )
            return localeCurrency || 'RUB'
        },
        localeCurrencySymbol () {
            return this.getCurrencySymbol(this.localeCurrency)
        },
        currencySymbol () {
            return this.getCurrencySymbol(this.currencyGetter)
        },
        currencyGetter () {
            return this.currency || this.localeCurrency
        },
    },
    methods: {
        getCurrencySymbol (currency) {
            const locale = this.$i18n.localeMapped
            return (0)
                .toLocaleString(locale, {
                    style: 'currency',
                    currency: currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
                .replace(/\d/g, '')
                .trim()
        },
    },
}
