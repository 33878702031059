
function getDefaultPeriods () {
    return [
        { id: 1, name: 'за сегодня', start: (new Date(Date.now() - new Date().getTimezoneOffset() * 60000)).toISOString().slice(0, -1), end: (new Date(Date.now() - new Date().getTimezoneOffset() * 60000)).toISOString().slice(0, -1) },
        { id: 2, name: 'за последние 7 дней', start: new Date(Date.now() - 7 * 24 * 3600 * 1000).toISOString(), end: new Date(Date.now()).toISOString() },
        { id: 3, name: 'за последние 30 дней', start: new Date(Date.now() - 30 * 24 * 3600 * 1000).toISOString(), end: new Date(Date.now()).toISOString() },
        { id: 4, name: 'за последние 90 дней', start: new Date(Date.now() - 90 * 24 * 3600 * 1000).toISOString(), end: new Date(Date.now()).toISOString() },
        { id: 5, name: 'за последние 180 дней', start: new Date(Date.now() - 180 * 24 * 3600 * 1000).toISOString(), end: new Date(Date.now()).toISOString() },
        { id: 6, name: 'за последние 365 дней', start: new Date(Date.now() - 365 * 24 * 3600 * 1000).toISOString(), end: new Date(Date.now()).toISOString() },
        // { id: 7, name: 'собственный диапазон', start: new Date(Date.now() - 365 * 24 * 3600 * 1000).toISOString(), end: new Date(Date.now()).toISOString() },
    ]
}

// function updatePeriod (period) {
//     if (period.id >= 1 && period.id <= 6) {
//         const list = getDefaultPeriods()
//         return list.find(x => x.id === period.id)
//     } else {
//         return period
//     }
// }

const getDefaultPeriod = () => {
    return getDefaultPeriods()[0]
}

const getDefaultState = () => {
    return {
        period: localStorage.getItem('dateSelection') ? JSON.parse(localStorage.getItem('dateSelection')) : getDefaultPeriod(),
        periods: getDefaultPeriods(),
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state.period, getDefaultPeriod())
        localStorage.setItem('dateSelection', JSON.stringify(getDefaultPeriod()))
    },
    periodId (state, payload) {
        // const period = state.periods.find(item => item.id === payload)
        const period = getDefaultPeriods().find(item => item.id === payload)
        if (period) {
            state.period = period
            localStorage.setItem('dateSelection', JSON.stringify(period))
        }
    },
    period (state, payload) {
        state.period = payload
        localStorage.setItem('dateSelection', JSON.stringify(payload))
    },
}

const actions = {
    async updatePeriod ({ state, commit }) {
        console.log('updatePeriod', state.period)
        if (state.period) commit('periodId', state.period.id)
      },
}

const getters = {
    periodId: (state) => state.period.id,
    period: (state) => state.period,
    periods: (state) => {
        return state.periods
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
