import GuardEmptyPrograms from '@/router/guards/guard-empty-programs'
import GuardAccess from '@/router/guards/guard-access'
import GroupHook from '@/router/guards/group-hook'

export default {
    children: [
        {
            path: '/program/accounts',
            component: () => import('@/views/crm/client/Client'),
            beforeEnter: GroupHook([GuardEmptyPrograms, GuardAccess]),
            name: 'ProgramAccounts',
            props: (route) => ({
                routeSegmentId: +route.query.segmentId || null,
            }),
            meta: { auth: true, title: 'Клиенты', icon: 'dashboard', permission: ['program-account-list', 'program-account-create'] },
        },
        {
            path: '/program/accounts/import',
            component: () => import('@/views/crm/client/import/index'),
            beforeEnter: GroupHook([GuardEmptyPrograms, GuardAccess]),
            meta: { auth: true, title: 'Импорт клиентов', icon: 'dashboard', permission: 'program-account-import' },
        },
        {
            path: '/program/segments',
            component: () => import('@/views/crm/segment/Segment'),
            beforeEnter: GuardEmptyPrograms,
            name: 'ProgramSegments',
            meta: { auth: true, title: 'Сегменты', icon: 'dashboard' },
        },
        {
            path: '/program/sellings',
            beforeEnter: GuardEmptyPrograms,
            component: () => import('@/views/selling/index'),
            name: 'ProgramSellings',
            meta: { auth: true, title: 'Продажи', icon: 'dashboard' },
        },
        {
            path: '/program/sellings/new',
            beforeEnter: GuardEmptyPrograms,
            component: () => import('@/views/account/sale/SaleForm.vue'),
            name: 'SaleForm',
            props: (route) => ({ accountId:  +route.query.accountId || null, }),
            meta: { auth: true,  title: 'label.sale', icon: 'dashboard', permission: 'program-sales-create' },
        },
    ],
}
