import MixinPositionable from '@/mixins/positionable.js'
import { VueMaskDirective as mask } from 'v-mask'
import MixinRender from './components/date-text-field/render'
import { mergedPickerProps, defaultProps as defaultPickerProps } from './components/date-text-field/props'
import { defaultProps as defaultTextFieldProps } from './components/text-field/props'
import { trimLR } from '@/utils'

export default {
  name: 'base-date-text-field',
  inheritAttrs: false,
  directives: { mask },
  mixins: [MixinPositionable, MixinRender],
  props: mergedPickerProps,
  model: {
    prop: 'startDate',
    event: 'updateStartDate',
  },
  data () {
    return {
      showDatePicker: false,
      internalDateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      pickerPlacement: '',
    }
  },
  computed: {
    maskDateFormat () {
      return this.dateFormat.replace(/[DMYHhms]/g, '#')
    },
    propsDateRange () {
      return this.optionsPropsByKeys(
        Object.keys(defaultPickerProps),
        false,
      )
    },
    propsTextField () {
      return this.optionsPropsByKeys(
        Object.keys(defaultTextFieldProps),
      )
    },
    formatedStartDate () {
      if (this.notFilledStartDate) return ''
      console.log('formatedStartDate', this.getFormatedDate(this.startDate))
      return this.getFormatedDate(this.startDate)
    },
    notFilledStartDate () {
      return (
        !this.startDate ||
        (typeof this.startDate === 'string' && !this.startDate.length)
      )
    },
    pickerStyles () {
      const offset = 5

      return this.pickerPlacement === 'right'
        ? {
          left: this.positioned.dateRangePicker.relativedWidth - this.positioned.dateRangePicker.outstandScrollRight + offset + 'px',
          bottom: -this.positioned.dateRangePicker.positionedHeight / 2 + 'px',
        } : this.pickerPlacement === 'top'
        ? {
          bottom: this.positioned.dateRangePicker.relativeHeight + offset + 'px',
          right: 0,
        } : this.pickerPlacement === 'bottom'
        ? {
          top: this.positioned.dateRangePicker.relativeHeight + offset + 'px',
          right: 0,
        } : this.pickerPlacement === 'left'
        ? {
          right: this.positioned.dateRangePicker.relativedWidth + offset + 'px',
          bottom: -this.positioned.dateRangePicker.positionedHeight / 2 + 'px',
        } : {}
    },
    RegExpDateFormat () {
      return this.maskDateFormat.replace(/#/g, '\\d')
    },
  },
  mounted () {
    if (this.singleDatePicker) {
      if (this.startDate) {
        this.internalDateRange.startDate =
          this.internalDateRange.endDate =
          this.parseDate(this.startDate)
      }
    } else {
      if (this.endDate) {
        this.internalDateRange.endDate =
          this.parseDate(this.endDate)
      }
    }
  },
  methods: {
    updateShowPicker (v) {
      if (typeof v === 'boolean' && !this.readonly) {
        this.showDatePicker = v
      } else {
        this.showDatePicker = false
      }
    },
    updateTextField (str) {
      const strDate = this.parseDate(str)

      if (
        (
          new RegExp(this.RegExpDateFormat, 'g').test(str) &&
          trimLR(strDate.toString()).toLowerCase() === 'invalid date'
        ) ||
        (typeof str === 'string' && !str.length) || !str
      ) {
        this.$emit('updateStartDate', '')
        return
      }

      if (new RegExp(this.RegExpDateFormat, 'g').test(str)) {
        this.internalDateRange.startDate =
          this.internalDateRange.endDate = strDate
        this.$emit(
            'updateStartDate',
            this.$moment(strDate).toISOString(),
        )
      }
    },
    apply (v) {
      this.$refs.picker.$refs['date-range-picker'].clickedApply()
      this.$emit('clickApply')
    },
    show () {
      this.updateShowPicker(true)
      this.$nextTick(() => {
        this.applyCalculation.dateRangePicker = false
        if (this.$refs?.picker?.$children) {
          this.propPositioning(this.$refs.picker.$children[0].$el, this.$refs.textField, 'dateRangePicker')
          this.pickerPlacement = this.positioned.dateRangePicker.placement
        }
      })
    },
    hide () {
      this.updateShowPicker(false)
    },
    getFormatedDate (date) {
      const regex = new RegExp(this.getRegExpByMask(date), 'g')

      if (!regex.test(date)) {
        if (!(date instanceof Date)) date = this.parseDate(date)
        return this.$moment(
            date,
        ).format(this.dateFormat)
      } else {
        return date
      }
    },
    getRegExpByMask (date) {
      let regex = this.maskDateFormat.substr(0, date.length)
      regex = regex.replace(/#/g, '\\d')
      return regex
    },
    parseDate (date) {
      let newDate = date
      if (typeof date === 'string' && date.length) {
        if (this.isJsonString(date)) {
          newDate = this.$moment(JSON.parse(newDate)).toDate()
        } else if (
          new RegExp(this.RegExpDateFormat, 'g').test(date)
        ) {
          newDate = this.$moment(date, this.dateFormat).toDate()
        } else {
          newDate = this.$moment(new Date(date)).toDate()
        }
      } else {
        newDate = ''
      }

      return newDate
  },
  optionsPropsByKeys (keys, isInKeys = true) {
      const optProps = {}
      Object.keys(this.$options.propsData).forEach(pName => {
        const checkKeys =
          isInKeys
            ? keys.includes(pName)
            : !keys.includes(pName)

        if (checkKeys) {
          if (
            typeof this.$options.propsData[pName] === 'string' &&
            !this.$options.propsData[pName].length
          ) {
            optProps[pName] = true
          } else {
            optProps[pName] = this.$options.propsData[pName]
          }
        }
      })
      return optProps
    },
    isJsonString (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
  },
}
