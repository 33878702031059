export default class User {
    constructor (profile) {
        Object.assign(this, profile)
    }

    roles (programId = null) {
        if (this.access_programs && programId) {
            const programIndex = this.access_programs.findIndex(
                (x) => x.id === programId,
            )
            if (programIndex >= 0) {
                return this.access_programs[programIndex].roles
            }
        } else if (this.access_merchant) {
            return this.access_merchant.auth_user_roles
        }
        return []
    }

    rolesSet (programId = null) {
        return new Set(this.roles(programId).map((x) => x.name))
    }

    permissions (programId = null) {
        return this.roles(programId)
            .reduce((result, role) => result.concat(role.permissions || []), [])
            .filter((v, i, a) => a.findIndex((x) => x.id === v.id) === i)
    }

    permissionsSet (programId = null) {
        return new Set(this.permissions(programId).map((x) => x.name))
    }

    hasProgramPermission (permission, programId = null) {
        return this.is_super_admin || this.permissionsSet(programId).has(permission)

        // if (this.access_programs) {
        //   const programIndex = this.access_programs.findIndex(x => x.id === programId)
        //   if (programIndex >= 0) {
        //     const program = this.access_programs[programIndex]
        //     for (let i = 0; i < program.roles.length; i++) {
        //       const permIndex = program.roles[i].permissions.findIndex(x => x.name === permission)
        //       if (permIndex >= 0) return true
        //     }
        //   }
        // }
        // return false
    }

    hasProgramRole (role, programId = null) {
        return  this.is_super_admin || this.rolesSet(programId).has(role)
        // if (this.access_programs) {
        //   const programIndex = this.access_programs.findIndex(x => x.id === programId)
        //   if (programIndex >= 0) {
        //     const program = this.access_programs[programIndex]
        //     const roleIndex = program.roles.findIndex(x => x.name === role)
        //     if (roleIndex >= 0) return true
        //   }
        // }
        // return false
    }

    doesntHaveProgramRole (role, programId = null) {
      return !this.rolesSet(programId).has(role) && !this.is_super_admin
    }

    hasProgramAnyRole (roles, programId = null) {
        for (let i = 0; i < roles.length; i++) {
            const hasRole = this.hasProgramRole(roles[i], programId)
            if (hasRole) return true
        }
        return false
    }
}
