export const CUSTOM_ICONS = {
    'trash-arrow': require('@/assets/icons/trash-arrow.svg'),
    'cert-continue': require('@/assets/icons/cert-continue.svg'),

    'status-wait': require('@/assets/icons/status-wait.svg'),
    'status-success': require('@/assets/icons/status-success.svg'),
    'status-error': require('@/assets/icons/status-error.svg'),
    'status-cancel': require('@/assets/icons/status-cancel.svg'),

    file: require('@/assets/icons/file.svg'),
    'file-invoice': require('@/assets/icons/file-invoice.svg'),

    promo: require('@/assets/icons/promo.svg'),

    animal: require('@/assets/icons/animal.svg'),
    'fast-food-outline': require('@/assets/icons/fast-food-outline.svg'),
    'speaker-on': require('@/assets/icons/speaker-on.svg'),
    'speaker-off': require('@/assets/icons/speaker-off.svg'),

    'square-chechmark-outline': require('@/assets/icons/square-chechmark-outline.svg'),
    'square-minus': require('@/assets/icons/square-minus.svg'),
    'square-outline': require('@/assets/icons/square-outline.svg'),

    // payments
    'payment-mastercard': require('@/assets/icons/payment-mastercard.svg'),
    'payment-visa': require('@/assets/icons/payment-visa.svg'),
    'payment-mir': require('@/assets/icons/payment-mir.svg'),

}
