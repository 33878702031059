import ApiService from '@/api/api-client'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    list: [],
    segment: {},
    pickList: [],
  },
  mutations: {
    SET_LIST (state, payload) {
      state.list = payload
    },
    SET_PICK_LIST (state, payload) {
      state.pickList = payload
    },
    SET_SEGMENT (state, payload) {
      state.segment = payload
    },
    ADD_IN_LIST (state, payload) {
      state.list.push(payload)
    },
    UPDATE_IN_LIST (state, payload) {
      const idx = state.list.findIndex(e => e.id === payload.id)
      Vue.set(state.list, idx, payload)
    },
    DELETE_FROM_LIST (state, id) {
      Vue.set(state, 'list', state.list.filter(e => e.id !== id))
    },
  },
  actions: {
    async getSegment ({ commit }, id) {
      const result = await ApiService.get(`/api-cabinet/program/nomenclature/segment?id=${id}`)
      commit('SET_SEGMENT', result)
    },
    async getList ({ commit }, { programId }) {
      const result = await ApiService.get(`/api-cabinet/program/nomenclature/segment/list?program_id=${programId}`)
      commit('SET_LIST', result)
    },
    async getPickList ({ commit }, { programId }) {
      const result = await ApiService.get(`/api-cabinet/program/nomenclature/segment/picklist?program_id=${programId}`)
      commit('SET_PICK_LIST', result)
    },
    async create ({ state, commit }, payload) {
      try {
        const result = await ApiService.post('/api-cabinet/program/nomenclature/segment', payload)

        commit('ADD_IN_LIST', result)

        this._vm.$notify({
            type: 'success',
            title: 'Сегменты',
            text: 'Сегмент создан',
        })
      } catch (err) {
        console.log(err)
        throw err
      }
    },
    async update ({ commit }, payload) {
      try {
        const result = await ApiService.put('/api-cabinet/program/nomenclature/segment', payload)

        commit('UPDATE_IN_LIST', result)

        this._vm.$notify({
          type: 'success',
          title: 'Сегменты',
          text: 'Сегмент обновлен',
        })

        return result
      } catch (err) {
        console.log(err)
        throw err
      }
    },
    async delete ({ commit }, id) {
      try {
        await ApiService.delete(`/api-cabinet/program/nomenclature/segment?id=${id}`)

        commit('DELETE_FROM_LIST', id)

        this._vm.$notify({
          type: 'success',
          title: 'Сегменты',
          text: 'Сегмент удален',
        })
      } catch (err) {
        console.log(err)
        throw err
      }
    },
    async recalc ({ state, commit }, id) {
      await ApiService.post('/api-cabinet/program/nomenclature/segment/recalc', { id })
      this._vm.$notify({
        type: 'success',
        title: 'Сегменты',
        text: 'Запуск сегмента на обновление',
    })
    },
  },
  getters: {
    list: state => state.list,
    pickList: state => state.pickList,
    segment: state => state.segment,
  },
}
