import ApiService from "@/api/api-client";
import { formatNumberFloat } from "@/mixins/formatNumberFloat";

export default {
  namespaced: true,
  state: {
    loading: false,
    segments: [],
    pickSegments: [],
  },
  mutations: {
    SET_PICK_SEGMENTS(state, payload) {
      state.pickSegments = payload;
    },
    segments(state, payload) {
      state.segments = payload;
    },
    // === this mutator is for example === //
    segmentUpdateById(state, payload) {

      const editDataIndex = state.segments.findIndex(function (item) {
        return item.id === payload.id;
      })

      if (editDataIndex !== -1) {
        Object.assign(state.segments[editDataIndex], payload);
      }
    },
    segmentDeleteById(state, payload) {
      const deleteDataIndex = this._vm.$_.findIndex(state.segments, (o) => {
        return o.id === payload.id;
      });
      console.log("delete_DataIndex");
      console.log(deleteDataIndex);
      console.log("delete_DataIndex");
      state.segments.splice(deleteDataIndex, 1);
    },
    segmentCreate(state, payload) {
      console.log("mutation");
      console.log(payload);
      console.log("mutation");
      state.segments.push(payload);
    },
    loading(state, payload) {
      state.loading = payload;
    },

    REMOVE_ALL_USERS_SEGMENT(state, payload) {

      const indexSegment = state.segments.findIndex(function(item) {
        return item.id === payload.id;
      })

      if (indexSegment !== -1) {
        state.segments[indexSegment].clients_count = 0;
        state.segments[indexSegment].profit = 0;
      }
    },
  },
  actions: {
    async segments({ commit }, payload) {
      commit("loading", true);

      const result = await ApiService.get(
        "/api-cabinet/program/client/segment/list",
        {
          params: payload,
        }
      );
      console.log("segments.action.segments", result);
      commit("segments", result);
      commit("loading", false);
    },

    async getPickList({ commit }, programId) {
      const result = await ApiService.get(
        "/api-cabinet/program/client/segment/picklist",
        {
          params: {
            program_id: programId,
          },
        }
      );

      commit("SET_PICK_SEGMENTS", result);
    },
    async createSegment({ commit }, payload) {
      const result = await ApiService.post(
        "/api-cabinet/program/client/segment/create",
        payload
      );

      commit("segmentCreate", result);
    },
    async editSegment({ commit }, payload) {
      console.log("editSegm");
      console.log(payload);
      console.log("editSegm");
      const result = await ApiService.post(
        "/api-cabinet/program/client/segment/update",
        payload
      );

      commit("segmentUpdateById", result); // this mutator is for example
    },

    async deleteSegment({ commit }, payload) {
      console.log("DELETE");
      console.log(payload);
      console.log("DELETE");
      const result = await ApiService.delete(
        "/api-cabinet/program/client/segment/delete",
        { params: payload }
      );

      if (result) {
        commit("segmentDeleteById", result);
      }
    },

    async deleteUsersSegment({ commit }, payload) {
        const result = await ApiService.delete(
            "/api-cabinet/program/client/segment/users/delete",
            { params: payload }
        );
        commit("REMOVE_ALL_USERS_SEGMENT", {id: payload.segment_id});
    }

  },
  getters: {
    segments(state) {
      
      return state.segments.map(item => {

        item.profit_str = Number(
          (item.profit ? item.profit / 100 : 0).toFixed(2)
        )

        item.average_profit_str = Number(
          (item.clients_count && item.profit
            ? item.profit / 100 / item.clients_count
            : 0
          ).toFixed(2)
        )

        return item
      })
    },
    pickSegments(state) {
      return state.pickSegments;
    },
    segmentsForWidget(state) {
      return state.segments.map((segment) => {
        return [
          {
            text: segment.name,
            color: segment.color,
          },
          segment.clients_count.toLocaleString() || "0",
          segment.profit
            ? Number((segment.profit / 100).toFixed(2))
            : 0,
          segment.clients_count > 0
            ? Number(
                (segment.profit / 100 / segment.clients_count).toFixed(2)
              )
            : 0,
        ];
      });
    },
  },
};
