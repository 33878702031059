
<template>
  <v-menu
    ref="menu1"
    v-model="openPicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <base-text-field
        v-model="internalValue"
        label="Date"
        hint="MM/DD/YYYY format"
        persistent-hint
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
      />
      <!-- <v-text-field
              v-model="dateFormatted"
              label="Date"
              hint="MM/DD/YYYY format"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              @blur="internalValue = parseDate(dateFormatted)"
              v-on="on"
            ></v-text-field> -->
    </template>
    <base-date-range-picker
      v-model="dateRange"
      v-bind="$attrs"
      :single-date-picker="true"
      :time-picker="true"
    />

    <!-- <v-date-picker
            v-model="date"
            no-title
            @input="menu1 = false"
          ></v-date-picker> -->
  </v-menu>
</template>

<script>
  import DateRangePicker from 'vue2-daterange-picker'
  export default {
    name: 'DatetimePickerField',
    components: { DateRangePicker },
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      value: {
        type: [String, Date],
        default: null,
      },

    },
    data () {
      return {
        openPicker: false,
        // localedata
        // :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
        //     :minDate="minDate" :maxDate="maxDate"
        //     :singleDatePicker="singleDatePicker"
        //     :timePicker="timePicker"
        //     :timePicker24Hour="timePicker24Hour"
        //     :showWeekNumbers="showWeekNumbers"
        //     :showDropdowns="showDropdowns"
        //     :autoApply="autoApply"

        //     @update="updateValues"
        //     @toggle="checkOpen"
        //     :linkedCalendars="linkedCalendars"
        //     :dateFormat="dateFormat"
      }
    },

    computed: {
      dateRange: {
        get () {
          return { startDate: this.value }
        },
        set (val) {
          (val.startDate !== this.value) && this.$emit('input', val.startDate)
        },
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          (val !== this.value) && this.$emit('input', val)
        },
      },

    },

    watch: {

    },
    mounted () {

    },
    methods: {

    },
  }
</script>
