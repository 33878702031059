
<template>
  <base-text-field
    ref="valueField"
    v-model="valueMasked"
    v-money="value!=null?money: null"
    v-bind="$attrs"
    :rules="valueRules"
    :validation-placement="'top'"
    :maxlength="withDecimal ? 18 : 15"
    @keydown="onKeydown"
    v-on="$listeners"
  />
</template>

<script>
  import { isFilled, isNumeric, isPosNumber } from '@/utils/validate'
  const decimalChars = ['.', ',']

  export default {
    name: 'MoneyField',
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: {
        type: Number,
        default: undefined,
      },
      decimal: {
        type: Boolean,
        default: false,
      },
      suffix: {
        type: String,
        default: ' ₽'
      }
    },
    data () {
      return {
        valueMasked: this.value,
        withDecimal: false,
        valueRules: [
          (v) => isFilled(this.valueRub) || this.$t('validation.required_sum'),
          (v) => isNumeric(this.valueRub) || 'Должно быть числом',
          (v) => (!this.valueRub || isPosNumber(this.valueRub)) || 'Должно быть положительным',
          (v) =>
            this.valueRub <= this.$config.MAX_TRAN_VALUE ||
            `Не более ${this.$config.MAX_TRAN_VALUE}`,
        ],
      }
    },

    computed: {
      money () {
        return {
          decimal: ',',
          thousands: ' ',
          prefix: '',
          precision: this.withDecimal && this.decimal ? 2 : 0,
          suffix: this.suffix
        }
      },
      valueUnmasked () {
        return this.valueMasked?.toString().replace(this.money.prefix, '').replace(this.money.suffix, '')
        .replaceAll(this.money.thousands, '').replace(this.money.decimal, '.')
      },
      valueRub () {
        return this.valueUnmasked ? Number(this.valueUnmasked) : null
      },
      valueCoin () {
        return Math.round(this.valueRub * 100)
      },

    },

    watch: {
      value (v) {
        if (v !== this.valueRub) this.valueMasked = v
      },
      valueRub (v) {
        this.$emit('update', v)
      },
    },
    mounted () {

    },
    methods: {
      focus () {
        this.$refs.valueField.focus()
      },
      onKeydown (e) {
        if (decimalChars.includes(e.key)) {
          this.withDecimal = !this.withDecimal
        }
      },
    },
  }
</script>
