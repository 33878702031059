<template>
  <base-drawer-dialog
    v-model="stateComputed"
    :width="580"
    title="Смена владельца карты"
  >
    <v-form ref="change-card-owner" v-model="form">
      <v-row>
        <v-col cols="12">
          <div>
            <p class="body-l-semibold">Текущий номер телефона</p>
            <base-text-field
              prefix="+"
              v-model="phone"
              type="text"
              outlined
              error-style="tooltip"
              validation-placement="top"
              disabled
            />
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            <p class="body-l-semibold">Новый номер телефона</p>
            <vue-tel-input-vuetify
              ref="form_phone"
              placeholder="Номер телефона"
              required
              v-model="newPhone"
              :rules="phoneRules"
            />
          </div>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn :loading="loadingBtn" @click="sendForm" color="primary">Сменить</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </base-drawer-dialog>
</template>


<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: false,
      newPhone: null,
      loadingBtn: false,
      phoneRules: [
          v => v.replace(/[^0-9]/g, "") != this.phone || 'Номер должен отличатся от текущего',
        ],
    };
  },
  methods: {
    ...mapActions({
      sendNewPhone: "account/account/sendNewPhone",
      updatePhone: "crm/client/changePhoneAccount"
    }),
    async sendForm() {
      if (!this.$refs["change-card-owner"].validate()) return false;
      this.loadingBtn = true;
      try {
        await this.sendNewPhone({
          accountId: this.accountId,
          phone: this.newPhone,
        });
        this.$notify({
          type: "success",
          title: "Телефон",
          text: "Телефон успешно изменен",
        });
        this.$emit("updatePhone", "")
        this.updatePhone({id: this.accountId, phone: this.newPhone})
        this.stateComputed = false;
      } catch (e) {
        console.log(e);
      } finally {
          this.loadingBtn = false;
      }
    },
  },
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: String,
      default: "799999999",
    },
    accountId: {
      type: Number,
    },
  },
  computed: {
    stateComputed: {
      get: function () {
        return this.state;
      },
      set: function (newValue) {
        this.$emit("update:state", newValue);
      },
    },
  },
};
</script>