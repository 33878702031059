<template>
  <v-row class="master-field-block">
    <v-col>
      <v-row>
        <v-col class="b-title title-m-bold neutral-900--text">
          <slot>{{ title }}</slot>
        </v-col>
      </v-row>
      <v-row v-if="horisontal">
        <v-col class="b-description body-m-regular neutral-700--text">
          <slot name="description">
            {{ description }}
          </slot>
        </v-col>
        <v-col>
          <slot name="input" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-row>
            <v-col class="b-description body-m-regular neutral-700--text">
              <slot name="description">
                {{ description }}
              </slot>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <slot name="input" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'MasterFieldBlock',
    props: {
      horisontal: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
    },

    computed: {},
  }
</script>
<style lang="scss" scoped>
.master-field-block {
  margin-top: 34px;

  .b-title {
    padding-top: 0!important;
    padding-bottom: 2px!important;
  }
  .b-description {
    padding-bottom: 2px!important;
  }
}
</style>
