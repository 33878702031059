<template>
  <div
    class="base-textarea"
  >
    <v-textarea
      class="vuetify-textarea"
      outlined
      :auto-grow="$attrs.autoGrow != undefined ? $attrs.autoGrow : true"
      v-bind="propsData"
      v-on="$listeners"
    >
      <template v-slot:prepend>
        <slot name="prepend" />
      </template>
      <template v-slot:append>
        <slot name="append" />
      </template>
      <template v-slot:append-outer>
        <slot name="append-outer" />
      </template>
      <template v-slot:progress>
        <slot name="progress" />
      </template>
      <template v-slot:counter="{ props }">
        <slot
          name="counter"
          :props="props"
        />
      </template>
      <template v-slot:prepend-inner>
        <div class="textarea---angle" />
        <slot name="prepend-inner" />
      </template>
    </v-textarea>
  </div>
</template>

<script>
  export default {
    props: {},
    computed: {
      propsData () {
        return { ...this.$attrs, ...this.$options.propsData }
      },
    },
    mounted () {},
  }
</script>

<style lang="scss">
@import '@/styles/vuetify-preset-plus/light_theme/_variables.sass';
@import '@/styles/_typography.sass';

.base-textarea {
  position: relative;
  width: 100%;
  .vuetify-textarea {
    &.v-input--is-focused {
      .v-input__control {
        .v-input__slot {
          background: $neutral-200;
          fieldset {
            border: 1px solid currentColor;
            color: $primary-base;
          }
        }
      }
    }
  }
  .v-input__slot {
    position: relative !important;
    fieldset {
      border-color: $neutral-400;
    }
    .v-input__prepend-inner {
      position: absolute !important;
      bottom: 10px !important;
      right: 0px !important;
    }
    .v-text-field__slot {
      textarea {
        &::placeholder {
          color: $neutral-600;
          @include body-m-medium;
        }
        color: $neutral-900;
        @include body-m-medium;
      }
    }
  }
  .textarea---angle {
      width: 13px;
      height: 1.4px;
      background: $neutral-500;
      transform: rotate(139deg);
      border-radius: 50px;
      z-index: 22;

      &:before {
          content: "";
          position: absolute;
          width: 7px;
          height: 1.4px;
          margin-top: -4px;
          margin-left: 2.5px;
          border-radius: 10px;
          background: $neutral-500;
      }
  }
}

</style>
