
function formatNumberFloat (num) {
    return num.replace(/.+?(?=\D|$)/, function (f) {
        return f.replace(/(\d)(?=(?:\d\d\d)+$)/g, '$1 ')
    })
}

export { formatNumberFloat }

export default {
    methods: {
        formatNumberFloat,
    },
}
