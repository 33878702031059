import { defaultProps } from './components/category/props'
import ListItems from './components/category/List.js'
import { VRow, VCol } from 'vuetify/lib/components'
import { deepClone } from '@/utils'

export default {
  model: {
    prop: 'selectedItems',
    event: 'update',
  },
  props: defaultProps,
  data () {
    return {
      itemStates: {
        open: [],
        selected: [],
        clickItem: '',
      },
      itemsWithParentVal: [],
      itemsLinear: [],
    }
  },
  watch: {
    'itemStates.selected': {
      deep: true,
      handler (v) {
        if (Array.isArray(v)) {
          this.$emit('update', v)
        }
      },
    },
    items: {
      deep: true,
      handler (v) {
        if (v && v.length) {
          this.setModifiedsItems(v)
        }
      },
    },
    addInSelected: {
      deep: true,
      handler (v) {
        if (v && v.length) {
          const ref = this.$refs.list
          v.forEach(item => {
            if (!ref.isSelected(item)) {
              ref.selectItem(item)
            }
          })
        }
      },
    },
    removeInSelected: {
      deep: true,
      handler (v) {
        if (v && v.length) {
          const ref = this.$refs.list
          v.forEach(item => {
            if (ref.isSelected(item)) {
              ref.takeOffItem(item)
            }
          })
        }
      },
    },
  },
  mounted () {
    this.setModifiedsItems(this.items)
  },
  render (createElement) {
    return createElement(VRow, {
      staticClass: 'extended-category',
      props: {
        noGutters: true,
      },
    }, [
      createElement(VCol, {
        staticClass: 'pa-0',
      }, [
        createElement(ListItems, {
          ref: 'list',
          props: {
            itemStates: this.itemStates,
            itemsWithParentVal: this.itemsWithParentVal,
            itemsLinear: this.itemsLinear,
            items: this.items,
            itemValue: this.itemValue,
            itemText: this.itemText,
            childKey: this.childKey,
            searchText: this.searchText,
          },
          on: {
            clickItem: (item, ev) => {
              this.$emit('clickItem', item, ev)
            },
            clickOpen: (item, ev) => {
              this.$emit('clickOpen', item, ev)
            },
            clickCheckbox: (item, ev) => {
              this.$emit('clickCheckbox', item, ev)
            },
            editOpen: (item, ev) =>{
              this.$emit('editOpen', item , ev)
            }
          },
        }),
      ]),
    ])
  },
  computed: {
  },
  methods: {
    clearAll () {
      this.itemStates.open = []
      this.itemStates.selected = []
    },
    setModifiedsItems (items) {
      this.$set(this, 'itemsWithParentVal', this.getArrWithParentVal(items))
      this.$set(this, 'itemsLinear', this.getLinearArr(this.itemsWithParentVal))
    },
    getArrWithParentVal (arr) {
      const cloneArr = deepClone(arr)

      cloneArr.map(el => {
        if (el[this.childKey].length) {
          const childs = this.getArrWithParentVal(el[this.childKey])

          childs.map(p => {
            if (el[this.itemValue]) {
              p.parent_val = el[this.itemValue]
            }
            return p
          })

          el[this.childKey] = childs

          return el
        }
      })

      return cloneArr
    },
    getLinearArr (arr) {
      const cloneArr = deepClone(arr)
      let linear = []

      cloneArr.forEach(el => {
        linear.push(el)
        if (el[this.childKey].length) {
          linear = linear.concat(this.getLinearArr(el[this.childKey]))
        }
      })

      return linear
    },
  },
}
