export const defaultProps = {
  checkbox: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array,
    default: function () {
      return []
    },
    required: true,
  },
  searchText: {
    type: String,
    default: '',
  },
  selectedItems: {
    type: Array,
    default: function () {
      return []
    },
  },
  itemValue: {
    type: String,
    default: 'id',
  },
  itemText: {
    type: String,
    default: 'name',
  },
  childKey: {
    type: String,
    default: 'childrens',
  },
  addInSelected: {
    type: Array,
    default: function () {
      return []
    },
  },
  removeInSelected: {
    type: Array,
    default: function () {
      return []
    },
  },
  hoverIcon :{
    type: Boolean,
    default: true,
  }
}
