module.exports = Object.freeze({
  messages: {
    isCountGroup: false, // mode grouping messages by number
    isTimeGroup: true, // mode grouping messages by time of sending
    maxCountGroup: 1, // number of messages to group (if the mode is on isCountGroup = true)
    timeGroup: 600, // time (seconds) to group messages (if the mode is on isTimeGroup = true)
  },
  checkActivityTime: 100000, // seconds
  files: {
    typesAttached: [], // example: ['image/png'], empty array = all
  },
})
