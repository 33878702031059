
<template>
  <v-text-field
    ref="valueField"
    v-model="internalValue"
    class="money-number-field"
    v-bind="$attrs"
    :rules="mergedRules"
    :validation-placement="'top'"
    :maxlength="precision > 0 ? 12 + 1 + precision : 12"
    error-style="vuetify"
    type="number"
    outlined
    :suffix="suffix||currencySymbol"
    :prefix="prefix"
    v-on="$listeners"
  />
</template>

<script>
  import { isFilled, isNumeric, isPosNumber } from '@/utils/validate'
  import currencyMixin from '@/mixins/currency'

  // const decimalChars = ['.', ',']

  export default {
    name: 'MoneyNumberField',
    mixins: [currencyMixin],
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: {
        type: Number,
        default: undefined,
      },
      precision: {
        type: Number,
        default: 2,
      },
      currency: {
        type: String,
        default: undefined,
      },
      prefix: {
        type: String,
        default: undefined,
      },
      suffix: {
        type: String,
        default: undefined,
      },
      rules: {
        type: Array,
        default: undefined,
      },

    },
    data () {
      return {
        internalValue: this.value,
        valueRules: [
          (v) => isFilled(this.valueNumber) || this.$t('validation.required_sum'),
          (v) => (this.valueNumber == null || isNumeric(this.valueNumber)) || 'Должно быть числом',
          (v) => (!this.valueNumber || !this.isPositive || isPosNumber(this.valueNumber)) || 'Должно быть положительным',
        ],
      }
    },

    computed: {
      valueMutation: {
        get: function () {
          return this.value
        },
        set: function (v) {
          this.$emit('update', Number(v))
        },
      },

      valueNumber () {
        return Number(this.internalValue)
      },
      precisionFactor () {
        return this.precision > 0 ? 10 ** this.precision : 1
      },
      valueCoin () {
        return Math.trunc(this.valueNumber * this.precisionFactor)
      },
      mergedRules () {
        return this.valueRules.concat(this.rules || [])
      },
    },

    watch: {
      internalValue (newValue, oldValue) {
        // console.log('valueMutation', newValue, oldValue)
        if (newValue !== oldValue && newValue !== null) {
          this.$nextTick(() => {
            if (this.valueNumber > this.$config.MAX_TRAN_VALUE) {
              this.internalValue = this.$config.MAX_TRAN_VALUE
            } else {
              this.internalValue = this.valueCoin / (this.precisionFactor)
            }
          })
        }
      },
      valueNumber (v) {
        this.$emit('update', Number(v))
      },
      value (v) {
        if (v !== this.valueNumber) this.internalValue = v
      },
    },
    mounted () {

    },
    methods: {
      focus () {
        this.$refs.valueField.focus()
      },
      blur () {
        this.$refs.valueField.blur()
      },
      // onKeydown (e) {
      //   if (decimalChars.includes(e.key)) {

      //   }
      //   // console.log(this.money)
      // },
    },
  }
</script>

<style scoped>
    .money-number-field >>> input {
      /* text-align: right */
    }
</style>
