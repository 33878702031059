/**
 * Базовый компонент dateRangePicker (автор: LsMacox)
 * @url https://innologica.github.io/vue2-daterange-picker
 */

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import DateRangePicker from 'vue2-daterange-picker'
import { defaultProps } from './components/date-range-picker/props'
import MixinRender from './components/date-range-picker/render'
import { getSlot } from 'vuetify/lib/util/helpers'

export default {
  name: 'base-date-range-picker',
  mixins: [MixinRender],
  props: defaultProps,
  model: {
    prop: 'dateRange',
    event: 'update',
  },
  data () {
    return {
      defaultLocaleData: {
        firstDay: 1,
        applyLabel: 'Применить',
        cancelLabel: 'Отменить',
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      },
      firstInitStartDate: true,
    }
  },
  render (createElement) {
    return createElement(DateRangePicker, {
      ref: 'date-range-picker',
      staticClass: 'base-date-range-picker',
      props: {
        dateRange: this.dateRange,
        localeData: Object.assign({}, this.defaultLocaleData),
        showDropdowns: false,
        autoApply: this.autoApplyValue,
        opens: 'inline',
        dateUtil: 'native',
        closeOnEsc: false,
        timePickerIncrement: 1,
        ranges: false,
        ...this.$options.propsData,
      },
      on: {
        'change-month': (monthDate, any) => this.$emit('change-month', monthDate, any),
        hoverDate: date => this.$emit('hoverDate', date),
        toggle: (open, togglePicker) => this.$emit('toggle', open, togglePicker),
        update: v => this.$emit('update', v),
        select: v => this.$emit('select', v),
      },
      scopedSlots: {
        ...this.$slots,
        ...this.$scopedSlots,
        footer: data => {
          if (this.hideDefaultFooter) {
            return getSlot(this, 'footer', data)
          }
          return this.genFooter(data)
        },
      },
    })
  },
  computed: {
    autoApplyValue () {
      if (this.singleDatePicker) {
        return true
      }
      return false
    },
  },
  watch: {
    'dateRange.startDate' (v) {
      if (this.firstInitStartDate) {
        if (this.$moment(v)
          .diff(this.$moment(), 'months') !== 0
        ) {
          this.$refs['date-range-picker'].$children[0].changeMonthDate(v)
        }
      }
      this.firstInitStartDate = false
    },
  },
}
