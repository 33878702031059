import ApiService from '@/api/api-client'

export default {
    namespaced: true,
    state: {
        name: 'widget_bonuses',
        segment_id : 0,
        loading: false,
        widgetData: [],
        emptyData : {
            total: 0,
            dateLabels: [0, 0, 0, 0, 0, 0, 0],
            chart: [[0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0]],
            bonusRatio: [0,0,0],
        }
    },
    mutations: {
        clearState (state) {
            state.loading = false
            state.widgetData = state.emptyData
        },
        loading (state, payload) {
            state.loading = payload
        },
        widgetData (state, payload) {
            state.widgetData = payload
        },
        segment(state, payload){
            state.segment_id = payload.segment_id;
            console.log('save widget segment ' + state.name + '_' + payload.program_id, state.segment_id)
            localStorage.setItem(state.name +'_' + payload.program_id , state.segment_id)
        }
    },
    actions: {
        async widget ({ commit }, widget) {
            commit('loading', true)

            const result = await ApiService.post('/api-cabinet/widget/dashboard/bonuses/movement', widget)
            commit('widgetData', result)
            commit('loading', false)
        },
        setDefault({commit}){
            commit('clearState')
        },
        saveSegment({commit}, payload){
            commit('segment', payload)
        },
        clearSegment({commit}, program_id){
            commit('segment', {segment_id : 0, program_id})
        }
    },
    getters: {
        loading (state) {
            return state.loading
        },
        widgetData (state) {
            const chartData = [[0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0]]
            const bonusRatio = [0, 0, 0]

            if (state.widgetData.credit) {
                chartData[0] = state.widgetData.credit
            }

            if (state.widgetData.debit) {
                chartData[1] = state.widgetData.debit
            }

            if (state.widgetData.expired) {
                chartData[2] = state.widgetData.expired
            }

            if (state.widgetData.bonus_sum) {
                if (state.widgetData.bonus_sum > 0) {
                    bonusRatio[0] = Math.round(state.widgetData.credit[state.widgetData.credit.length - 1] / state.widgetData.bonus_sum * 100)
                    bonusRatio[1] = Math.round(state.widgetData.debit[state.widgetData.debit.length - 1] / state.widgetData.bonus_sum * 100)
                    bonusRatio[2] = Math.round(state.widgetData.expired[state.widgetData.expired.length - 1] / state.widgetData.bonus_sum * 100)
                }
                console.log('BONUSES')
                console.log(bonusRatio)
                console.log('BONUSES')
            }

            console.log({
                total: state.widgetData.total,
                dateLabels: state.widgetData.date_labels,
                chart: chartData,
                bonusRatio: bonusRatio,
            })

            return {
                total: state.widgetData.total,
                dateLabels: state.widgetData.date_labels,
                chart: chartData,
                bonusRatio: bonusRatio,
            }
        },
        segment: state => program_id => {
            console.log('get widget segment ' + state.name + '_' + program_id, localStorage.getItem(state.name + '_'+ program_id))
            if (localStorage.getItem(state.name + '_'+ program_id) !== null && localStorage.getItem(state.name + '_'+ program_id) !== undefined)
                return JSON.parse(localStorage.getItem(state.name + '_'+ program_id))
            return state.segment_id
        }
    },
}
