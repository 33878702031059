import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

/* Requires ctx */
const BaseRequireCtx = require.context(
  '@/components/base', true, /\.(vue|js)$/,
)
const SidePanelRequireCtx = require.context(
  '@/components/side-panel', true, /\.(vue|js)$/,
)

const ExtendedRequireCtx = require.context(
  '@/components/extended', true, /\.(vue|js)$/,
)

// folders
installComponents(BaseRequireCtx, 'base')
installComponents(SidePanelRequireCtx, 'side-panel')
installComponents(ExtendedRequireCtx, 'extended')
// manually
installComponent(import('@/components/message-box/MessageBox.vue'), 'BaseMessageBox')

/* Helper functions */
function installComponents (requireCtx, prefix = '') {
  requireCtx.keys().forEach(fileName => {
    if (!(/^\.?\/?(components)/).test(fileName)) {
      const componentConfig = requireCtx(fileName)
      let componentName = _getOnlyComponentName(fileName, prefix)
      componentName = upperFirst(camelCase(prefix.toLowerCase() + '-' + componentName))
      Vue.component(componentName, componentConfig.default || componentConfig)
    }
  })
}

function installComponent (requireCtx, componentName) {
  Vue.component(componentName, requireCtx)
}

function _getOnlyComponentName (fileName, prefix) {
  let componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+/, '$1')),
  )

  if (prefix && prefix.length) {
    componentName = componentName.replace(prefix, '')
  }

  return componentName
}
