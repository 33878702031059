import { render, staticRenderFns } from "./TabClient.vue?vue&type=template&id=07dd3f69&scoped=true&"
import script from "./TabClient.vue?vue&type=script&lang=js&"
export * from "./TabClient.vue?vue&type=script&lang=js&"
import style0 from "./TabClient.vue?vue&type=style&index=0&id=07dd3f69&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07dd3f69",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VRadio,VRadioGroup,VRow,VTextField,VTextarea})
