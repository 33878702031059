<template>
  <div>
    <div>
      <edit-phone :phone="accountClient.user.phone
                    ? accountClient.user.phone
                    : '-'" 
                    :state.sync="showEditPhoneDialog"
                    :accountId="accountClient.id"
                    @updatePhone="$emit('updateData', '')"
                    />                    
    </div>
    <div class="mode-usual__contacts-info">
      <ul class="contacts-info__list">
        <v-row align="center" class="mb-1" no-gutters>
          <v-col cols="auto">
            <li class="contacts-info__item">
              <iconify-icon
                class="info__item-icon"
                icon="ion-phone-portrait-outline"
                width="21"
              />
              <a class="body-m-medium neutral-700--text mb-0" v-if="accountClient.user && accountClient.user.phone" :href="'tel:+' + accountClient.user.phone ">+{{ accountClient.user.phone }}</a>
              <p v-else class="body-m-medium neutral-700--text mb-0">
                -
              </p>
            </li>
          </v-col>
          <v-col>
            <v-btn
              small
              color="secondary"
              :text="true"
              :ripple="false"
              @click="showEditPhoneDialog = !showEditPhoneDialog"
            >
              Сменить номер
            </v-btn>
          </v-col>
        </v-row>
        <li class="contacts-info__item">
          <iconify-icon
            class="info__item-icon"
            icon="ion-mail-outline"
            width="21"
          />
          <p class="body-m-medium neutral-700--text">
            {{
              accountClient.user && accountClient.user.email
                ? accountClient.user.email
                : "-"
            }}
          </p>
        </li>
        <li class="contacts-info__item">
          <iconify-icon
            class="info__item-icon"
            icon="ion-barcode-outline"
            width="21"
          />
          <p class="body-m-medium neutral-700--text">
            {{ accountClient.barcode || "-" }}
          </p>
        </li>
        <li class="contacts-info__item">
          <iconify-icon
            class="info__item-icon"
            icon="feather-credit-card"
            width="21"
          />
          <p class="body-m-medium neutral-700--text">
            {{ accountClient.id || "-" }}
          </p>
        </li>
      </ul>
    </div>
    <v-row>
      <v-col>
        <bonus-account :client-data="clientData" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
          color="secondary"
          :disabled="clientCardLoading"
          :loading="sendAccountCardAction"
          @click="sendAccountCard"
        >
          <v-icon left> $iconify_feather-credit-card </v-icon>
          Отправить карту
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
          color="secondary"
          :disabled="clientCardLoading"
          @click="createAccountSaleClick"
        >
          <v-icon left> $iconify_feather-shopping-bag </v-icon>
          Создать продажу
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
          color="secondary"
          :disabled="clientCardLoading"
          @click="clickHereToPrint"
        >
          <v-icon left> $iconify_ion-document-outline </v-icon>
          Создать выписку
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="
        userPermissionsSet.has('program-account-delete') ||
        authUser.is_super_admin
      "
      justify="center"
    >
      <v-col cols="auto">
        <v-btn text color="error" :loading="deleteAction" @click="deleteClick">
          <v-icon left> $iconify_feather-trash </v-icon>
          <span>Удалить в корзину</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <a
          class="body-m-semibold primary--text"
          href="#"
          @click="activityHelp = true"
        >
          <v-icon left color="primary"
            >$iconify_question-circle-outlined</v-icon
          >
          <span class="body-m-semibold primary--text"
            >Неактивные кнопки списания и начисления?</span
          >
        </a>
      </v-col>
    </v-row>

    <!-- ВЫПИСКА -->
    <div v-if="showDetail" id="content">
      <p>#########################</p>
      <p>Выписка по бонусным операциям</p>
      <p>#########################</p>
      <p>Состояние счета</p>
      <p
        v-for="(bonus, index) in accountBalances"
        :key="'accountBalances_' + index"
      >
        {{ bonus.name }}: {{ bonus.balance }}
        {{
          getUnitNameEnding(
            bonus.balance,
            bonus.ending["unit_name_ending"],
            bonus.name
          )
        }}
      </p>

      <div v-for="(item, i) in transactions" :key="'transactions_' + i">
        <div v-if="item.created_at.split('T')[0] >= dataCheck">
          <p v-if="item.created_at.split('T')[0] >= dataCheck">
            #########################
          </p>
          <p>
            {{ item.created_at.slice(0, 19).replace("T", " ").split(" ")[0] }}
            в
            {{ item.created_at.slice(0, 19).replace("T", " ").split(" ")[1] }}
          </p>
          <p>
            {{ item.direction == "FROM" ? "Списано" : "Начислено" }}:
            <br />
            {{ item.bonus_unit }}: {{ item.value }}
            {{
              getUnitNameEnding(
                item.value,
                accountBalances.filter(
                  (element) => element.name == item.bonus_unit
                ).length
                  ? accountBalances.filter(
                      (element) => element.name == item.bonus_unit
                    )[0].ending["unit_name_ending"]
                  : "[]",
                accountBalances.filter(
                  (element) => element.name == item.bonus_unit
                ).length
                  ? accountBalances.filter(
                      (element) => element.name == item.bonus_unit
                    )[0].name
                  : ""
              )
            }}
          </p>
          <p v-if="item.direction == 'TO'">
            Срок истечения:
            {{
              item.expire_at
                ? item.expire_at.slice(0, 19).replace("T", " ")
                : "нет"
            }}
          </p>
          <p v-if="item.comment">Комментарий: {{ item.comment }}</p>
        </div>
      </div>
    </div>

    <!-- ПОМОЩЬ -->
    <v-dialog
      v-model="activityHelp"
      max-width="550"
      @click:outside="activityHelp = false"
    >
      <v-card>
        <div class="activity-help-content">
          <div class="body-l-semibold">
            Почему у меня неактивные кнопки “Начислить” или “Списать”?
          </div>

          <div style="margin: 20px 0 0 0">
            Чтобы появилась возможность ручного начисления или списания бонусов
            необходимо создать бонусные операции с типом “Начисление” и
            “Списание”.
          </div>

          <div>
            Сделать это можно в разделе настройки
            <router-link to="/loyalty"> программы лояльности </router-link>.
          </div>

          <div class="body-l-semibold" style="margin: 20px 0">
            Добавление возможности Начисления бонусов.
          </div>

          <ol>
            <li>Создайте бонусную операцию с типом “Начиление”.</li>
            <li>Заполните название бонусной операции.</li>
            <li>Заполните описание бонусной операции для клиента.</li>
            <li>Выберите валюту счета.</li>
            <li>
              Выберите “Правило срабатывания” с типом “Ручное использование”.
            </li>
            <li>Поставьте галочку “Включить для менеджеров”.</li>
          </ol>

          <div class="body-l-semibold" style="margin: 20px 0">
            Добавление возможности Списания бонусов.
          </div>

          <ol>
            <li>Создайте бонусную операцию с типом “Списание”.</li>
            <li>Заполните название бонусной операции.</li>
            <li>Заполните описание бонусной операции для клиента.</li>
            <li>Выберите валюту счета.</li>
            <li>
              Выберите “Правило срабатывания” с типом “Ручное использование”.
            </li>
            <li>Поставьте галочку “Включить для менеджеров”.</li>
          </ol>

          <v-card-actions style="margin-top: 20px">
            <v-spacer />
            <v-btn text @click="activityHelp = false"> Закрыть </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <!-- {{clientData}} -->
  </div>
</template>

<script>
import BonusAccount from "./BonusAccount";
import { mask } from "vue-the-mask";
import Permission from "@/mixins/permission";
import { mapActions } from "vuex";
import EditPhone from "./EditPhone.vue";

export default {
  directives: { mask },
  components: {
    BonusAccount,
    EditPhone
  },
  mixins: [Permission],
  props: {
    clientData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showEditPhoneDialog: false,
      loading: false,
      activityHelp: false,
      showDetail: false,
      deleteAction: false,
      sendAccountCardAction: false,
    };
  },
  computed: {
    clientCardLoading() {
      return this.$store.getters["crm/clientCard/loading"];
    },
    accountClient() {
      return this.$store.getters["crm/clientCard/client"];
    },
    accountBalances() {
      const result = this.$store.getters["crm/clientCard/accountBalances"];
      result.forEach((item) => {
        if (!item.max_value) {
          item.icon = null;
        }
      });
      return result;
    },
    transactions() {
      return this.$store.getters["crm/clientCard/transactions"];
    },
    dataCheck() {
      const date = new Date();
      date.setDate(date.getDate() - 30);
      return date.toISOString().split("T")[0];
    },
  },
  async mounted() {
    await this.getDetail();
  },
  methods: {
    ...mapActions({
      fetchSendAccountCard: "account/account/sendAccountCard",
    }),
    async deleteClick() {
      try {
        this.deleteAction = true;
        await this.$confirm(
          `Вы уверены, что хотите удалить клиента #${this.clientData.id} в корзину?`,
          "Удаление в корзину",
          {
            confirmButtonText: "Удалить",
            cancelButtonText: "Отмена",
            type: "warning",
          }
        );
        await this.$store.dispatch("crm/client/delete", this.clientData.id);
      } finally {
        this.deleteAction = false;
      }
    },
    getLastActivity(date) {
      if (date)
        return (
          "Был(а) в сети " +
          this.$moment
            .utc(date)
            .local()
            .format(this.$config.date.DATETIME_FORMAT_MIN2)
        );
      return "Был(а) в сети - ";
    },
    getUnitNameEnding(iNumber, aEndings, name) {
      aEndings =
        !!aEndings && aEndings.length ? JSON.parse(aEndings) : aEndings;
      if (
        aEndings === "" ||
        aEndings == null ||
        aEndings.first === "" ||
        aEndings.first == null
      ) {
        return name;
      }

      var sEnding, i;
      iNumber = iNumber % 100;
      if (iNumber >= 11 && iNumber <= 19) {
        sEnding = aEndings.third;
      } else {
        i = iNumber % 10;
        switch (i) {
          case 1:
            sEnding = aEndings.first;
            break;
          case 2:
          case 3:
          case 4:
            sEnding = aEndings.second;
            break;
          default:
            sEnding = aEndings.third;
        }
      }

      return sEnding;
    },
    clickHereToPrint() {
      let dispSetting = "toolbar=yes,location=no,directories=yes,menubar=yes,";
      dispSetting += "scrollbars=yes,width=900, height=700, left=100, top=25";
      const contentValue = document.getElementById("content").innerHTML;

      const docprint = window.open("", "", dispSetting);
      docprint.document.open();
      docprint.document.write("<html><head><title>Testing</title>");
      docprint.document.write(
        '</head><body onLoad="self.print()" style="width: 800px; font-size: 13px; font-family: arial;">'
      );
      docprint.document.write(contentValue);
      docprint.document.close();
      docprint.focus();
    },
    async getDetail() {
      console.log("Detail")
      try {
        this.loading = true;
        await this.$store.dispatch(
          "crm/clientCard/transactionsList",
          this.clientData.id
        );
        this.showDetail = true;
      } finally {
        this.loading = false;
      }
    },
    createAccountSaleClick() {
      this.$router.push({ name: 'SaleForm', query: { accountId: this.clientData.id }})
    },
    async sendAccountCard() {
      try {
        this.sendAccountCardAction = true;
        await this.fetchSendAccountCard(this.accountClient.id);
      } catch (error) {
        console.error(error);
      } finally {
        this.sendAccountCardAction = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
  #content {
    display: none;
    size: A4;
    margin: 0;
    width: 210mm;
    height: 297mm;
  }
  .activity-help-content {
    padding: 25px;
  }
  .mode-usual__contacts-info {
    margin-top: 2px;
    .contacts-info__list {
      padding-left: 0;
      list-style: none;
      .contacts-info__item {
        display: flex;
        .info__item-icon {
          color: $neutral-600;
          margin-right: 13px;
        }
      }
    }
  }
  .mode-usual__panel-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
    p {
      margin-bottom: 0 !important;
    }
    .panel-btn {
      display: inline-block;
      background-color: $primary-100 !important;
      border-radius: 10px;
      height: 45px;
      width: 192px;
      margin-bottom: 22px;
      margin-top: 48px;
      p {
        margin-left: 12px;
      }
      .icon-document-outline {
        color: $primary-base;
      }
    }
    a {
      display: inherit;
      text-decoration: none;
      .icon-question-circle-outlined {
        margin-right: 6px;
      }
    }
  }
</style>
