export const vuetifyProps = {
  escapeClearable: Boolean,
  keyFilterRegexp: RegExp,
  minlength: [String, Number],
  maxlength: [String, Number],
  appendIcon: String,
  appendOuterIcon: String,
  autofocus: Boolean,
  backgroundColor: String,
  clearIcon: String,
  clearable: Boolean,
  color: String,
  counter: [Boolean, String, Number],
  counterValue: Function,
  dark: Boolean,
  dense: Boolean,
  disabled: Boolean,
  error: Boolean,
  filled: Boolean,
  flat: Boolean,
  fullWidth: Boolean,
  height: [Number, String],
  hideDetails: [Boolean, String],
  hint: String,
  id: String,
  label: String,
  light: Boolean,
  loaderHeight: [Number, String],
  loading: [Boolean, String],
  outlined: Boolean,
  persistentHint: Boolean,
  placeholder: String,
  prefix: String,
  prependIcon: String,
  readonly: Boolean,
  shaped: Boolean,
  // rules: Array,
  singleLine: Boolean,
  suffix: String,
  type: String,
  // messages: [String, Array],
  success: Boolean,
  // successMessages: [String, Array],
  // validateOnBlur: Boolean,
}

export const customProps = {
  errorStyle: {
    type: String,
    default: 'tooltip', // custom and vuetify
  },
  validationPlacement: {
    type: String,
    default: 'right',
  },
  prependInnerIcon: {
    type: String,
    default: undefined,
  },
  prependInnerIconColor: {
    type: String,
    default: 'neutral-500',
  },
}

export const defaultProps = {
  ...vuetifyProps,
  ...customProps,
}
