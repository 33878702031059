/* eslint-disable no-return-assign */
import ApiService from '@/api/api-client'
import Vue from 'vue'
import axios from 'axios'
import { Sema } from "async-sema";

const getDefaultState = () => {
    return {
        clients: [], // клиенты компании
        filteredClients: [], // отфильтрованные клиенты
        search: null, 
        filter: {
            rules: [],
            segment_id: null,
            type: null,
        },
        accountsForFilter: [],
        list: {
            page: 1,
            itemsPerPage: 25,
        },
        total: 0,
        importResult: [],
        importStatusLoadingResult: false,
        countRecordsImport: 0,
        importStatusCancelled: false,
        cancelTokenImport: createCancelTokenImport()
    }
}

const createCancelTokenImport = () => axios.CancelToken.source()
const chunkSize = 10
const maxCountThread = 10
const state = getDefaultState()

const mutations = {
    SET_STATUS_LOADING_RESULT_IMPORT: (state, status) => state.importStatusLoadingResult = status,
    SET_COUNT_RECORD_IMPORT: (state, count) => state.countRecordsImport = count,
    SET_IMPORT_STATUS_CANCELLED: (state, status) => {
        if (status) state.cancelTokenImport.cancel()
        state.importStatusCancelled = status
    },
    RESET_STATE: (state) => Object.assign(state, getDefaultState()),
    RESET_IMPORT_RESULT: (state) => state.importResult = [],
    SET_CLIENTS: (state, payload) => state.clients = payload,
    SET_SEARCH: (state, payload) => state.search = payload,
    SET_FILTERED_CLIENTS: (state, payload) => state.filteredClients = payload,
    SET_FILTER: (state, payload) => state.filter = payload,
    APPEND_FILTER: (state, payload) => {
        console.log('APPEND_FILTER', payload)
        state.filter = Object.assign({}, state.filter, payload)
    },
    SET_ACCOUNTS_FOR_FILTER: (state, payload) => state.accountsForFilter = payload,
    SET_LIST: (state, payload) => state.list = payload,
    SET_TOTAL: (state, payload) => state.total = payload,
    CONCAT_IMPORT_RESULT: (state, payload) => {
        const countElements = state.importResult.length
        payload.forEach(element => element.index += countElements);
        const result = state.importResult.concat(payload)
        state.importResult = result
    },
    ADD (state, payload) {
        const items = state.clients
        items.push(payload)
    },
    UPDATE (state, payload) {
        const index = state.clients.findIndex(item => item.id === payload.id)
        if (index >= 0) Vue.set(state.clients, index, Object.assign(state.clients[index], payload))
    },
    REMOVE_CLIENT (state, id) {
        const index = state.clients.findIndex(item => item.id === id)
        if (index >= 0) state.clients.splice(index, 1)
    },
    CHANGE_PHONE_ACCOUNT (state, payload) {
        let index = state.clients.findIndex(function (object) {
            return object.id === payload.id
        })
        if (index >= 0) state.clients[index].user.phone = payload.phone.replace(/[^0-9]/g, "");
    }
    // SET_QUERY_VALUE: (state, payload) => {
    //     if (payload.children && payload.children.length === 0) {
    //         state.queryValue = null
    //     } else {
    //         state.queryValue = payload
    //     }
    // },
}

const actions = {

    resetState ({ commit }) {
        commit('RESET_STATE')
    },

    resetImportResult ({ commit }) {
        commit('RESET_IMPORT_RESULT')
    },

    setSearch ({commit}, {search}) {
        commit('SET_SEARCH', search)
    },

    setFilter ({ commit }, {filter}) {
        commit('SET_FILTER', filter)
        console.log(filter)
    },

    async uploadImportChunks ({ commit, dispatch }, {items, programId, eventEnable}) {

        commit('RESET_STATE')
        commit('SET_STATUS_LOADING_RESULT_IMPORT', true)
        commit('SET_COUNT_RECORD_IMPORT', items.length)

        const chunksArray = [];
        for (let i = 0; i <Math.ceil(items.length/chunkSize); i++){
            chunksArray[i] = items.slice((i*chunkSize), (i*chunkSize) + chunkSize);
        }

        const semaphore = new Sema(maxCountThread);
        for (let i = 0; i < chunksArray.length; i++) {

            if (state.importStatusCancelled) break

            await semaphore.acquire()
            const postData = {
                program_id: programId,
                excel: chunksArray[i],
                event_enable: eventEnable,
            }

            ;(async function () {
                try {
                    await dispatch('createList', postData)
                } catch (e) {
                    console.log(e)
                } finally {
                    semaphore.release()
                }
            })();
        }

        if (state.importStatusCancelled) {
            this._vm.$notify({
                type: 'error',
                title: 'Клиенты',
                text: 'Импорт клиентов был прерван',
            })
        } else {
            this._vm.$notify({
                type: 'success',
                title: 'Клиенты',
                text: 'Клиент успешно импортированы',
            })
        }

        commit('SET_STATUS_LOADING_RESULT_IMPORT', false)
    },

    async create ({ commit }, item) {
            const result = await ApiService.post('/api-cabinet/crm/account', item)

            this._vm.$notify({
                type: 'success',
                title: 'Клиенты',
                text: 'Клиент успешно добавлен',
            })

            return result
    },

    async delete ({ commit }, id) {
         await ApiService.delete('/api-cabinet/crm/account', {
            params: { id },
        })
        commit('REMOVE_CLIENT', id)
        this._vm.$notify({
            type: 'success',
            title: 'Клиенты',
            text: 'Клиент удален в корзину',
        })
    },

    async createList ({ commit }, data) {
        try {
            const result = await ApiService.post('/api-cabinet/crm/account/import', data, { 
                cancelToken: state.cancelTokenImport.token
            })
            commit('CONCAT_IMPORT_RESULT', result)
        } catch (error) {
            throw error
        }
    },

    async list ({ commit }, { postData, cancelToken }) {
        // eslint-disable-next-line no-useless-catch
        try {
            const result = await ApiService.post('/api-cabinet/crm/account/list', postData, {
                cancelToken,
            })
            console.log('/api-cabinet/crm/account/list')
            console.log(result)
            commit('SET_CLIENTS', result.items)
            commit('SET_TOTAL', result.total)
        } catch (error) {
            throw error
        }
    },

    changePhoneAccount ({ commit}, {id, phone} ) {
        commit('CHANGE_PHONE_ACCOUNT', {id: id, phone: phone})
    },

    async deleteAll ({ commit }, program_id ) {
        try {
            await ApiService.delete('/api-cabinet/program/account/delete/all', { 
                params: { 
                    program_id: program_id,                    
                 },
            })
            commit('SET_CLIENTS', [])
        } catch (error) {
            throw error
        }
    },

    async consentAllSms ({commit}, {program_id}) {
        try {
            await ApiService.put('/api-cabinet/crm/account/update-all', {
                program_id,
                consent_sms: true,
            })
        } catch (error) {
            throw error
        }
    },

    async consentAllAdvertising ({commit}, {program_id}) {
        try {
            await ApiService.put('/api-cabinet/crm/account/update-all', {
                program_id,
                consent_advertising: true
            })
        } catch (error) {
            throw error
        }
    },

    async querySearch ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {
            const result = await ApiService.post('/api-cabinet/crm/account/search', item)
            console.log('/api-cabinet/crm/account/search')
            console.log(result)
            commit('SET_ACCOUNTS_FOR_FILTER', result)
        } catch (error) {
            throw error
        }
    },

    async updateAccount ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {
            const result = await ApiService.put('/api-cabinet/crm/account', item)
            console.log('/api-cabinet/crm/account')
            console.log(result)
            commit('UPDATE', result)

            this._vm.$notify({
                type: 'success',
                title: 'Клиенты',
                text: 'Клиент успешно обновлен',
            })
        } catch (error) {
            throw error
        }
    },

}

const getters = {
    importStatusLoadingResult: state => state.importStatusLoadingResult,
    countRecordsImport: state => state.countRecordsImport,
    importStatusCancelled: state => state.importStatusCancelled,
    clients: state => state.clients,
    filteredClients: state => state.filteredClients,
    filter: state => state.filter,
    filterDefault: () => {
        const defaultFilter = getDefaultState()
        return defaultFilter.filter
    },
    accountsForFilter: state => state.accountsForFilter,
    list: state => state.list,
    total: state => state.total,
    importResult: state => state.importResult,
    search: state => state.search
    // queryValue: state => state.queryValue,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
