<template>
  <div
    ref="baseTextField"
    v-click-outside="hide"
    class="date-text-field"
  >
    <div class="field__input">
      <base-text-field
        ref="text-field"
        v-model="dateText"
        v-mask="mask"
        v-bind="$attrs"
        autocomplete="off"
        :autofocus="false"
        :rules="rules"
        @focus="show()"
      >
        <template v-slot:append>
          <v-icon
            color="neutral-500"
          >
            $iconify_feather-calendar
          </v-icon>
        </template>
      </base-text-field>
      <!-- <iconify-icon
        v-if="false"
        class="icon-feather-calendar"
        icon="feather-calendar"
        width="21"
      /> -->
    </div>
    <base-date-range-picker
      ref="picker"
      v-model="dateRange"
      :class="{show: showDatePicker, 'date-range-picker': true, 'date-range-picker--left': left, }"
      :time-picker="timePicker"
      :single-date-picker="singleDatePicker"
      :max-date="maxDate"
      :min-date="minDate"
      @update="updateDatePicker"
      @clickCancel="hide"
      @clickApply="apply"
    />
  </div>
</template>

<script>
  import MixinPositionable from '@/mixins/positionable.js'
  import { mask } from 'vue-the-mask'
  // import DateRangePicker from 'vue2-daterange-picker'
  // import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import { config } from '@/config'
  import vClickOutside from 'v-click-outside'

  export default {
    directives: { mask, clickOutside: vClickOutside.directive },
    mixins: [MixinPositionable],
    inheritAttrs: false,
    props: {
      date: {
        type: String,
        default: '',
      },
      dateFormat: {
        type: String,
        default: config.date.DATE_FORMAT,
      },
      maxDate: {
        type: [String, Date],
        default: null,
      },
      minDate: {
        type: [String, Date],
        default: null,
      },
      singleDatePicker: {
        type: Boolean,
        default: false,
      },
      left: {
        type: Boolean,
        default: false,
      },
      timePicker: {
        type: Boolean,
        default: false,
      },
      rules: {
        type: Array,
        default: undefined,
      },

    },
    data () {
      return {
        dateText: '',
        showDatePicker: false,
        dateRange: {
          startDate: new Date(),
        },
      }
    },
    computed: {
      mask () {
        return this.dateFormat.replace(/[DMYHhms]/g, '#')
      },
    },
    watch: {
      dateText (v) {
        const date = this.$moment(v, this.dateFormat).toISOString()
        this.$emit('update:date', date)
      },
    },
    created () {
      if (this.date) {
        this.dateRange.startDate = this.$moment(this.date)
        this.dateText = this.$moment(this.date).format(this.dateFormat)
      }
    },
    methods: {
      updateDatePicker (v) {
        this.showDatePicker = false
        if (this.dateFormat.length) {
          this.dateText = this.$moment(v.startDate).format(this.dateFormat)
        } else {
          this.dateText = v.startDate.toISOString().split('T')[0]
        }
      },
      show () {
        this.$nextTick(() => {
          this.positioningRelativelyElement(this.$refs.picker.$children[0].$el, this.$refs.baseTextField)
          this.showDatePicker = true
        })
      },
      hide () {
        this.showDatePicker = false
      },
      apply () {
        this.showDatePicker = false
        this.$emit('clickApply')
      },
    },
  }
</script>

<style lang="scss">
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.date-text-field {
  position: relative;
  .date-range-picker {
      position: absolute;
      right: 0;
      z-index: 99;
      margin-bottom: 4px;
      margin-top: 4px;
      display: none !important;
      .footer-content {
        .actions {
          padding: 15px 20px 15px 23px !important;
          button {
            height: 33px;
          }
        }
      }
      .date-range_picker__btn {
        height: 34px;
      }
      &.show {
        display: block !important;
      }

      &.date-range-picker--left{
        left: 0px;
        right: auto;
      }
  }
  .daterangepicker .calendars .calendars-container .drp-calendar:first-child {
    border-right: none !important;
  }
}

.date-text-field__input {
  // position: relative;

  .icon-feather-calendar {
    position: absolute;
    top: 25%;
    right: 12px;
    color: $neutral-500;
  }
}
</style>
