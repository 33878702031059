<template>
  <v-form
    ref="formFields"
    v-model="internalValidForm"
    class="client-fields"
  >
    <!-- {{internalFieldsData}} -->
    <ul class="fields__list">
      <li
        v-for="field in fields"
        :key="field.id"
        class="fields__item"
      >
        <div class="item-header">
          <div class="item-title">
            <span
              v-if="field.required"
              class="span-req error--text"
            >*</span>
            <p
              :style="field.required ? 'margin-left: 4px;' : ''"
              class="body-l-semibold mb-0"
            >
              {{ field.name }}
            </p>
          </div>
          <p class="body-m-regular neutral-700--text mb-0">
            {{ field.description }}
          </p>
        </div>
        <v-skeleton-loader
          :loading="internalLoadingFields"
          type="text@2"
        >
          <div class="item-content">
            <!-- {{ internalFieldsData[field.id] }} -->
            <base-text-field
              v-if="field.type === 'string'"
              v-model="internalFieldsData[field.id]"
              v-bind="textFieldBinds(field)"
            />
            <base-text-area
              v-else-if="field.type === 'text'"
              v-model="internalFieldsData[field.id]"
              v-bind="textFieldBinds(field)"
            />
            <base-text-field
              v-else-if="field.type === 'link'"
              v-model="internalFieldsData[field.id]"
              v-bind="{...textFieldBinds(field), ...{rules: getLinkRules(field)}}"
            />
            <base-text-field
              v-else-if="field.type === 'float'"
              :value="getPreparedFloat(internalFieldsData[field.id])"
              v-bind="{...textFieldBinds(field), ...{rules: getFloatRules(field)}}"
              @input="prepareFloat(field.id, $event)"
              @blur="blurFloatField(field.id)"
            />
            <base-text-field
              v-else-if="field.type === 'integer'"
              v-model="internalFieldsData[field.id]"
              v-bind="{...textFieldBinds(field), ...{rules: getIntegerRules(field)}}"
              @blur="blurIntegerField(field.id)"
            />
            <v-switch
              v-else-if="field.type === 'boolean'"
              v-model="internalFieldsData[field.id]"
              hide-details
              v-bind="{ ...textFieldBinds(field)}"
            />
            <base-date-picker-field 
              v-else-if="field.type === 'date'"
              v-model="internalFieldsData[field.id]"
              :rules="rulesWithRequired(field.required)"
              :readonly="field.readonly"
            />
            <base-datetime-picker-field
              v-else-if="field.type === 'datetime'"
              v-model="internalFieldsData[field.id]"
              :readonly="field.readonly"
              :rules="rulesWithRequired(field.required)"
              placeholder="дд.мм.гггг чч:мм:cc"
              clearable
            />

            <v-select
              v-else-if="field.type === 'select'"
              id="clientFieldSelect"
              v-model="internalFieldsData[field.id]"
              :rules="rulesWithRequired(field.required)"
              :items="field.items"
              placeholder="Выберите значение из списка"
              outlined
              clearable
              :readonly="field.readonly"
            />
          </div>
        </v-skeleton-loader>
      </li>
    </ul>
  </v-form>
</template>

<script>
  // other
  import { mapGetters, mapActions } from 'vuex'
  import { validURL, isInteger } from '@/utils/validate'
  import { deepClone, trimLR } from '@/utils'

  export default {
    name: 'TabFields',
    components: {},
    props: {
      notFilled: {
        type: Boolean,
        default: false,
      },
      fieldsData: {
        type: [Object, null],
        default: () => {},
      },
      validForm: Boolean,
      loadingFields: Boolean,
      loadingAccFields: Boolean,
    },
    data () {
      return {
        internalValidForm: false,
        internalLoadingFields: false,
        internalloadingAccFields: false,
        internalFieldsData: {},
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        settFields: 'settings/account-fields/fields',
        accountFields: 'crm/account-fields/accountFields',
        accountClient: 'crm/clientCard/client',
      }),
      fields: {
        get () {
          return this.settFields[this.programId]
        },
        set (v) {
          this.$store.commit('settings/account-fields/SET_FIELDS', { programId: this.programId, payload: v })
        },
      },
      linkRules () {
        return [
          v => (!this.isRequired(v) || validURL(v)) || 'Неверная ссылка',
        ]
      },
      floatRules () {
        return [
          v => this.isFloat(v) || 'Должно быть дробным числом',
        ]
      },
      integerRules () {
        return [
          v => isInteger(v) || 'Должно быть числом',
        ]
      },
    },
    watch: {
      programId () {
        this.init(false)
      },
      internalFieldsData: {
        deep: true,
        handler (v) {
          const prepareFields = {}

          this.accountFields.forEach(sf => {
            prepareFields[sf.id] = sf.value
          })

          this.$emit('update:hasChanges', JSON.stringify(this.internalFieldsData) !== JSON.stringify(prepareFields))
          this.$emit('update:fieldsData', v)
        },
      },
      internalValidForm (v) {
        this.$emit('update:validForm', v)
      },
      internalLoadingFields (v) {
        this.$emit('update:loadingFields', v)
      },
      internalloadingAccFields (v) {
        this.$emit('update:loadingAccFields', v)
      },
      accountFields: {
        deep: true,
        handler (v) {
          const prepareFields = {}
          v.forEach(ac => {
            prepareFields[ac.id] = ac.value
          })
          this.$set(this, 'internalFieldsData', prepareFields)
          this.$set(this, 'fields', deepClone(this.fields))
        },
      },
    },
    async mounted () {
      await this.init()
    },
    methods: {
      ...mapActions({
        GetFields: 'settings/account-fields/GetFields',
        GetAccountFields: 'crm/account-fields/GetAccountFields',
      }),
      async init (withStub = true) {
        this.internalLoadingFields = true
        await this.GetFields({ id: this.programId, withStub })
        this.internalLoadingFields = false

        if (!this.notFilled) {
          this.internalloadingAccFields = true
          await this.GetAccountFields(this.accountClient.id)
          this.internalloadingAccFields = false
        }

        Object.keys(this.internalFieldsData).forEach((key) => {
          if (this.fields.find(x => x.id == key).type === 'date' && this.$moment(this.internalFieldsData[key], "YYYY-MM-DD[T]HH:mm:ss.SSSS[Z]", true).isValid()) {
            this.internalFieldsData[key] = this.$moment(this.internalFieldsData[key], "YYYY-MM-DD[T]HH:mm:ss.SSSS[Z]").format("YYYY-MM-DD")
          }
        });

      },
      textFieldBinds (field) {
        const binds = {
          counter: false,
          errorStyle: 'vuetify',
          readonly: field.readonly,
          clearIcon: '$iconify_ion-close-circle-outline',
          prependInnerIconColor: this.$vuetify.theme.themes.light['neutral-500'],
          clearable: true,
          maxlength: 255,
        }

        const rules = this.rulesWithRequired(field.required)
        if (rules.length && !field.readonly) {
          binds.rules = rules
        }

        return binds
      },
      getLinkRules (field) {
        const rules = this.rulesWithRequired(field.required)

        rules.push(
          v => (!this.isRequired(v) || validURL(v)) || 'Неверная ссылка',
        )

        return rules
      },
      getFloatRules (field) {
        const rules = this.rulesWithRequired(field.required)

        rules.push(
          v => (!this.isRequired(v) || this.isFloat(v)) || 'Должно быть дробным числом',
        )

        return rules
      },
      getIntegerRules (field) {
        const rules = []
        const max64 = Number.MAX_SAFE_INTEGER
        const min64 = -Number.MAX_SAFE_INTEGER
        const pr = (v) => trimLR(String(v))
        const vNaN = (v) => isNaN(Number(v))

        if (field.required) {
          rules.push(
            v => !!v || 'Обязательно для заполнения',
          )
        }

        rules.push(
          v => (!v || isInteger(pr(v))) || 'Должно быть целым числом',
          v => (vNaN(v) || v < max64) || `Не должно превышать ${max64}`,
          v => (vNaN(v) || v > min64) || `Не должно превышать ${min64}`,
        )

        return rules
      },
      rulesWithRequired (required) {
        if (required) {
          return [
            v => this.isRequired(v) || 'Обязательно для заполнения',
          ]
        }
        return []
      },
      isRequired (v) {
        return Boolean(!!v && trimLR(v).length)
      },
      isFloat (v) {
        v = trimLR(String(v)).replace(/,/g, '.')
        // return /^-?(0(?![0-9])|[1-9]+),[0-9]+$/.test(v)
        return !isNaN(parseFloat(v)) && /^\d*\.*?\d*$/.test(v)
      },
      getPreparedFloat (v) {
        return (v != null) ? String(v).replace(/\./g, ',') : ''
      },
      prepareFloat (id, v) {
        if (!!v && typeof v === 'string') {
          v = v.trim().replace(/,/g, '.')
        }

        console.log('prepareFloat', id, v)
        this.internalFieldsData[id] = v

        this.updateRenderFields(id)
      },
      blurFloatField (id) {
        if (!isNaN(parseFloat(this.internalFieldsData[id]))) {
          this.internalFieldsData[id] = parseFloat(this.internalFieldsData[id])
        }
      },
      blurIntegerField (id) {
        if (!isNaN(parseInt(this.internalFieldsData[id]))) {
          this.internalFieldsData[id] = parseInt(this.internalFieldsData[id])
        }
      },
      updateRenderFields (id) {
        this.$set(this, 'internalFieldsData', Object.assign({}, this.internalFieldsData))
        const idx = this.fields.findIndex(f => f.id === id)
        this.$set(
          this.fields,
          idx,
          Object.assign({}, this.fields[idx]),
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '@/styles/vuetify-preset-plus/light_theme/_variables.sass';

.client-fields {
  & ::v-deep {
    .v-text-field {
      .v-input__append-inner {
        margin-top: 10px!important;
        .v-icon {
          svg {
            color: $neutral-500;
          }
        }
        .v-input__icon--clear {
          padding-left: 23px;
        }
      }
    }
    .v-select {
      .v-input__append-inner {
        .v-input__icon--clear {
          padding-left: 10px;
          margin-top: 2px;
        }
      }
    }
    .base-date-text-field {
      .v-input__append-inner {
        .v-input__icon--clear {
          margin-top: -2px;
          margin-left: -42px;
        }
        .v-icon {
          svg {
            color: $neutral-500;
          }
        }
      }
    }
    .v-input--switch {
      display: inline-block!important;
    }
    .v-btn.v-btn--disabled {
      p {
        color: $neutral-100!important;
      }
    }
  }
  .fields__list {
    list-style: none;
    padding-left: 0;
    .fields__item {
      margin-bottom: 34px;
      text-overflow: ellipsis;
      .item-header {
        margin-bottom: 20px;
        .item-title {
          display: flex;
          position: relative;
          .span-req {
            font-size: 20px;
            position: absolute;
            margin-top: -5px;
            left: -5px;
          }
        }
        p:first-child {
          margin-bottom: 6px;
          word-break: break-word;
        }
      }
    }
  }
  .btn {
    .icon {
      margin-right: 8px;
    }
  }
  .empty-block {
    margin-top: 34px;
    .btn-setting {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>
