import ApiService from '@/api/api-client'
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { config } from '@/config'
import { encodeQueryData } from '@/utils'

const defaultCategory = Object.freeze({
  uuid: uuidv4(),
  name: 'Без категории',
  childs: [],
  isNullCategory: true, // for lib vue-treeselect
  showChildren: false, // for lib vue-treeselect
})

export default {
  namespaced: true,
  state: {
    categoryTree: [],
    categoryList: [],
    nomenclatures: [],
    nomenclature: {},
    nomenTotalCount: 0,
    currCategoryUuid: '',
    defaultCategory,
  },
  mutations: {
    categoryTree (state, payload) {
      state.categoryTree = payload
    },
    categoryList (state, payload) {
      state.categoryList = payload
    },
    nomenclatures (state, payload) {
      state.nomenclatures = payload
    },
    nomenclature (state, payload) {
      state.nomenclature = payload
    },
    currCategoryUuid (state, payload) {
      state.currCategoryUuid = payload
    },
    nomenTotalCount (state, payload) {
      state.nomenTotalCount = payload
    },
    deleteInNomen (state, uuid) {
      Vue.set(state, 'nomenclatures', state.nomenclatures.filter(nomen => nomen.uuid !== uuid))
    },
    addInNomen (state, payload) {
      state.nomenclatures.push(payload)
      Vue.set(state, 'nomenclatures', [...state.nomenclatures])
    },
    updateInNomen (state, payload) {
      const idx = state.nomenclatures.findIndex(nomen => nomen.uuid === payload.uuid)
      state.nomenclatures[idx] = payload
      Vue.set(state, 'nomenclatures', [...state.nomenclatures])
    },
    deleteAllNomenclatureAndCategory(state) {
      state.categoryTree = []
      state.categoryList = []
      state.nomenclatures = []
      state.nomenclature = {}
      state.nomenTotalCount = 0
      state.currCategoryUuid = ''
      state.defaultCategory = defaultCategory
    }
  },
  actions: {
    async getCategoryList ({ commit, rootGetters }) {
      const programId = rootGetters.programId
      if (!programId || isNaN(programId)) return

      const result = await ApiService.get(`/api-cabinet/program/nomenclature/category/list?program_id=${programId}`)
      commit('categoryList', [defaultCategory, ...result || []])
    },
    async getCategoryTree ({ commit, rootGetters }) {
      const programId = rootGetters.programId
      if (!programId || isNaN(programId)) return

      const result = await ApiService.get(`/api-cabinet/program/nomenclature/category/tree?program_id=${programId}`)
      commit('categoryTree', [defaultCategory, ...result || []])
    },
    async getNomenclatureList ({ commit, rootGetters }, { offset, limit, search, categoryUuid }) {
      const programId = rootGetters.programId
      const query = {}
      query.program_id = programId
      if (search && search.length) query.search = search
      if (typeof offset !== 'undefined' && limit) {
        query.offset = offset
        query.limit = limit
      }
      if (categoryUuid) {
        query.category_uuid = categoryUuid
      } else if (categoryUuid === null) {
        query.category_uuid = ''
      }

      // const queryStr = encodeQueryData(query)
      console.log('queryStr: ', query)

      const result = await ApiService.get('/api-cabinet/program/nomenclature/filter/list', { params: query })

      if (result.total) {
        commit('nomenclatures', result.items)
        commit('nomenTotalCount', result.total)
      } else {
        commit('nomenclatures', [])
        commit('nomenTotalCount', 0)
      }

      return result.items
    },
    async getNomenclature ({ commit }, { uuid }) {
      const result = await ApiService.get(`/api-cabinet/program/nomenclature?nomenclature_uuid=${uuid}`)
      commit('nomenclature', result)
    },
    async create ({ state, commit, rootGetters }, payload) {
      payload.program_id = rootGetters.programId
      try {
        const result = await ApiService.post('/api-cabinet/program/nomenclature', payload)
        if (result.category_uuid === state.currCategoryUuid) {
          commit('addInNomen', result)
        }

        this._vm.$notify({
            type: 'success',
            title: 'Номенклатура',
            text: 'Номенклатура создана',
        })

        return result
      } catch (err) {
        console.log(err)
      }
    },
    async update ({ getters, commit }, payload) {
      try {
        const result = await ApiService.put('/api-cabinet/program/nomenclature', payload)

        if (result.category_uuid === payload.category_uuid) {
          commit('updateInNomen', result)
        }

        this._vm.$notify({
          type: 'success',
          title: 'Номенклатура',
          text: 'Номенклатура обновлена',
        })

        return result
      } catch (err) {
        console.log(err)
      }
    },
    async delete ({ getters, commit }, { uuid }) {
      try {
        await ApiService.delete(`/api-cabinet/program/nomenclature?nomenclature_uuid=${uuid}`)

        commit('deleteInNomen', uuid)
        commit('nomenTotalCount', getters.nomenTotalCount - 1)

        this._vm.$notify({
          type: 'success',
          title: 'Номенклатура',
          text: 'Номенклатура удалена',
        })
      } catch (err) {
        console.log(err)
      }
    },
    async deleteAllNomenclatureAndCategory ({ commit }, { programId }) {
      await ApiService.delete(`/api-cabinet/program/nomenclature/delete/all?program_id=${programId}`)
      commit('deleteAllNomenclatureAndCategory')
    },
    async createCategory({commit}, payload){
      try{
        console.log('create category payload', payload);
        const result = await ApiService.post(`/api-cabinet/program/nomenclature/category/create`, payload)
        console.log('create category result', result);
        this.dispatch('company/nomen/getCategoryList')
        this.dispatch('company/nomen/getCategoryTree')
      }
      catch(err){
        console.log(err);
      }
      
    },
    async updateCategory({commit}, payload){
      try{
        const result = await ApiService.put(`/api-cabinet/program/nomenclature/category/update`, payload)
        console.log('update category', result);
        this.dispatch('company/nomen/getCategoryList')
        this.dispatch('company/nomen/getCategoryTree')
      }
      catch(err){
        console.log(err);
      }
    },
    async deleteCategory({commit}, uuid){
      try{
        const result = await ApiService.post(`/api-cabinet/program/nomenclature/category/delete`, {'uuid' : uuid})
        console.log('delete category', result);
        this.dispatch('company/nomen/getCategoryList')
        this.dispatch('company/nomen/getCategoryTree')
      }
      catch(err){
        console.log(err);
      }
    },
  },
  getters: {
    categoryTree: state => state.categoryTree,
    categoryList: state => state.categoryList,
    nomenclature: (state) => state.nomenclature,
    nomenclatures: (state) => state.nomenclatures && state.nomenclatures.map(item => {
        Vue.set(item, 'createdAtFormat', moment.utc(item.created_at).local().format(config.date.DATETIME_FORMAT))
        Vue.set(item, 'updatedAtFormat', moment.utc(item.updated_at).local().format(config.date.DATETIME_FORMAT))
        return item
    }),
    nomenTotalCount: state => state.nomenTotalCount,
    defaultCategory: state => state.defaultCategory,
    nullCategory: state => state.defaultCategory,
  },
}
