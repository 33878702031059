import ApiService from '@/api/api-client'
import Vue from 'vue'

const getDefaultState = () => {
  return {
    sendings: [],
    sending: {},
    sendingsTotal: 0,
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => Object.assign(state, getDefaultState()),
  SET_SENDINGS (state, payload) {
    state.sendings = payload
  },
  SET_SENDING (state, payload) {
    state.sending = payload
  },
  SET_SENDINGS_TOTAL (state, payload) {
    state.sendingsTotal = payload
  },
  ADD_IN_SENDINGS (state, payload) {
    const items = state.sendings
    items.unshift(payload)
  },
  REMOVE_IN_SENDINGS (state, id) {
    state.sendings = state.sendings.filter(s => s.id !== id)
    Vue.set(state.sendings, '', [...state.sendings])
  },
}

const actions = {

  resetState ({ commit }) {
    commit('RESET_STATE')
  },

  async create ({ commit }, payload) {
    try {
      const result = await ApiService.post('/api-cabinet/program/notification/job', payload)

      commit('ADD_IN_SENDINGS', result)

      this._vm.$notify({
        type: 'success',
        title: 'Отправки',
        text: 'Отправка создана',
      })
    } catch (err) {
      console.log(err)
    }
  },

  async delete ({ commit, getters }, id) {
    try {
      await ApiService.delete(`/api-cabinet/program/notification/job?id=${id}`)

      commit('REMOVE_IN_SENDINGS', id)
      commit('SET_SENDINGS_TOTAL', getters.sendingsTotal - 1)

      this._vm.$notify({
        type: 'success',
        title: 'Отправки',
        text: 'Отправка удалена',
      })
    } catch (err) {
      console.log(err)
    }
  },

  async run ({ commit }, id) {
    try {
      await ApiService.post('/api-cabinet/program/notification/job/run', { id })

      this._vm.$notify({
        type: 'success',
        title: 'Отправки',
        text: `Отправка c id: "${id}" запущена`,
      })
    } catch (err) {
      console.log(err)
    }
  },

  async list ({ commit }, { notificationId, offset, limit }) {
    let queryStr = `?notification_id=${notificationId}`
    if (typeof offset === 'number' && limit) {
      queryStr += `&offset=${offset}&limit=${limit}`
    }

    try {
      const result = await ApiService.get(`/api-cabinet/program/notification/job/list${queryStr}`)

      if (result && result.items.length) {
        commit('SET_SENDINGS', result.items)
        commit('SET_SENDINGS_TOTAL', result.total)
      } else {
        commit('SET_SENDINGS', [])
        commit('SET_SENDINGS_TOTAL', 0)
      }
    } catch (err) {
      console.log(err)
    }
  },

  async getSending ({ commit }, id) {
    try {
      const result = await ApiService.get(`/api-cabinet/program/notification/job?id=${id}`)
      commit('SET_SENDING', result)
    } catch (err) {
      console.log(err)
    }
  },

}

const getters = {
  sendings: state => state.sendings,
  sending: state => state.sending,
  sendingsTotal: state => state.sendingsTotal,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
