import { isArray } from '@/utils/validate'

export default {
    methods: {
        hexToRgbA (hex, opacity) {
            let c
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split('')
                if (c.length === 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]]
                }
                c = '0x' + c.join('')
                return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + Number(opacity) + ')'
            }
            throw new Error('Bad Hex')
        },
        declOfNum (number, titles) {
            number = Number(number)
            number = Math.abs(number)
            const cases = [2, 0, 1, 1, 1, 2]
            let endings = []
            // convert
            if (typeof titles === 'string') {
                return titles
            } else if (isArray(titles) && titles.length > 0) {
                endings = titles
            } else if (typeof titles === 'object') {
                endings = [titles.first, titles.second, titles.third]
            } else {
                return null
            }
            // console.log('declOfNum', number, endings)
            // calc
            if (endings.length === 3) {
                if (number % 1 !== 0) {
                    return endings[2]
                } else {
                    const caseIndex = (number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]
                    // const caseIndex = (number % 100 >= 11 && number % 100 <= 19) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]
                    // console.log('declOfNum', caseIndex, (number % 100 >= 11 && number % 100 <= 19), number % 10)
                    return endings[caseIndex]
                }
            } else if (endings.length === 1) {
                return endings[0]
            } else {
                return null
            }
        },
    },
}
