<template>
  <v-skeleton-loader
    v-if="loading || !account"
    type="list-item-avatar-two-line@3"
  />
  <v-container v-else>
    <!-- {{ account }} -->
    <base-dialog-field-block
      title="Реферальный код и ссылка"
      description="Этот код и/или ссылка передается клиенту для приглашения новых участников (рефералов). Клиент распространяет код и/или ссылку, сообщая друзьям и знакомым. Когда новый участник воспользуется этим кодом, он автоматически будет привязан к этому клиенту."
    >
      <v-row>
        <v-col>
          <v-text-field
            outlined
            v-on:blur="resetReferCode()"
            v-model="refferalCodeInput"
            v-mask="'########'"
            :append-icon="
              showAppendIconRefferalCode ? 'mdi-content-save' : null
            "
            @click:append="sendNewRefferalCode(true)"
            :loading="updateCodeRefAction"
            :success="refferalCodeSuccessStatus"
            :error-messages="errorMesageRefferalCode"
          />
        </v-col>
        <v-col cols="auto">
          <v-btn color="secondary" @click="copyClipboard(refferalCodeInput)">
            <v-icon>$iconify_feather-copy</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="secondary"
            :loading="sendReferCodeSmsAction"
            :disabled="blockSecLeft > 0"
            @click="sendReferCodeClick"
          >
            {{ blockSecLeft > 0 ? blockTimeFormat : "Отправить в SMS" }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="body-s-semibold neutral-900--text"
          >Реферальная ссылка:</v-col
        >
        <v-col cols="7" class="link-col"
          ><a :href="this.account.refer_link" target="_blank">{{
            this.account.refer_link
          }}</a></v-col
        >
        <v-col cols="2">
          <v-btn
            color="primary"
            @click="copyClipboard(account.refer_link)"
            small
            icon
          >
            <v-icon>$iconify_feather-copy</v-icon>
          </v-btn></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="3" class="body-s-semibold neutral-900--text"
          >Короткая ссылка:</v-col
        >
        <v-col cols="7" class="link-col"
          ><a :href="this.account.refer_link_short" target="_blank">{{
            this.account.refer_link_short
          }}</a></v-col
        >
        <v-col cols="2">
          <v-btn
             color="primary"
            @click="copyClipboard(account.refer_link_short)"
             small
            icon
          >
            <v-icon>$iconify_feather-copy</v-icon>
          </v-btn></v-col
        >
      </v-row>
    </base-dialog-field-block>

    <base-dialog-field-block
      title="Реферер"
      description="Участник, пригласивший этого клиента."
      style="margin-top: 34px"
    >
      <account-list-item
        v-if="account.referrer_account"
        :account="account.referrer_account"
      />
      <v-row v-else>
        <v-col>
          <!-- <base-text-field
            v-model="refer_code"
            v-mask="'########'"
            placeholder="Введите код"
          /> -->
          <v-form ref="masterAccountForm" v-model="validMasterAccountId">
            <account-autocomplete
              v-model="masterAccountId"
              placeholder="Выберите клиента"
              :rules="[
                (v) => !!v || 'Выберите клиента',
                (v) =>
                  v != account.id || 'Клиент не может быть сам себе реферером',
              ]"
            />
          </v-form>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="secondary"
            :loading="setAccountReferrerAction"
            :disabled="!validMasterAccountId"
            @click="setAccountReferrerClick"
          >
            Применить
          </v-btn>
        </v-col>
      </v-row>
    </base-dialog-field-block>
    <base-dialog-field-block
      title="Рефералы"
      description="Участники программы, которые зарегистрированы по рекомендации этого клиента."
      style="margin-top: 34px"
    >
      <v-list v-if="referral_accounts.length > 0">
        <account-list-item
          v-for="referral in referral_accounts"
          :key="referral.id"
          :account="referral"
        >
          <template v-slot:action>
            <v-btn
              color="error"
              :loading="referral.removeReferralAction"
              icon
              @click="removeReferralClick(referral)"
            >
              <v-icon>$iconify_feather-trash</v-icon>
            </v-btn>
          </template>
        </account-list-item>
      </v-list>
      <div v-else>Рефералы отсутствуют</div>
    </base-dialog-field-block>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import copyClipboard from "@/mixins/copyClipboard.js";
import timer from "@/mixins/timer.js";
import Vue from "vue";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  name: "TabReferral",
  components: {
    AccountListItem: () => import("@/components/account/AccountListItem.vue"),
    AccountAutocomplete: () =>
      import("@/components/ModelSelect/AccountAutocomplete"),
  },
  // directives: {  },
  mixins: [copyClipboard, timer],
  props: {
    clientData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      errorMesageRefferalCode: "",
      refferalCodeSuccessStatus: false,
      showAppendIconRefferalCode: false,
      refferalCodeInput: null,
      loading: false,
      account: null,
      sendReferCodeDisabled: false,
      sendReferCodeSmsAction: false,
      setReferrerByCodeAction: false,
      setAccountReferrerAction: false,
      refer_code: null,
      masterAccountId: null,
      validMasterAccountId: false,
      updateCodeRefAction: false,
    };
  },
  computed: {
    accountClient() {
      return this.$store.getters["crm/clientCard/client"];
    },
    accountId() {
      return this.clientData.id;
    },
    referral_accounts() {
      return (this.account?.referral_accounts || []).map((item) => {
        Vue.set(item, "removeReferralAction", false);
        return item;
        // return Object.assign(item, {
        //   removeReferralAction: false,
        // })
      });
    },

    blockSecLeft() {
      return this.account && this.account.refer_code_send_block_left
        ? this.account.refer_code_send_block_left
        : 0;
      // return this.sendReferCodeBlockStart
      //   ? this.sendReferCodeBlockSec - this.timerMoment.diff(this.sendReferCodeBlockStart, 'seconds')
      //   : 0
    },
    blockTimeFormat() {
      if (this.blockSecLeft > 0) {
        const min = Math.trunc(this.blockSecLeft / 60);
        const sec = this.blockSecLeft - min * 60;
        return (
          min.toString().padStart(2, "0") +
          ":" +
          sec.toString().padStart(2, "0")
        );
      } else return "";
    },
    referCodeValid() {
      return this.refer_code && this.refer_code.length === 8;
    },
  },
  watch: {
    accountId(v) {
      this.init();
    },
    timerMoment() {
      if (this.account && this.account.refer_code_send_block_left)
        this.account.refer_code_send_block_left--;
    },
    refferalCodeInput() {
      this.sendNewRefferalCode();
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    ...mapActions({
      checkReferCode: "crm/account-referral/checkReferCode",
      accountReferInfo: "crm/account-referral/accountReferInfo",
      setReferrerByCode: "crm/account-referral/setReferrerByCode",
      setAccountReferrer: "crm/account-referral/setAccountReferrer",
      removeReferral: "crm/account-referral/removeReferral",
      sendReferCodeSMS: "crm/account-referral/sendReferCodeSMS",
      updateCodeRef: "crm/account-referral/updateCodeRef",
    }),
    resetReferCode() {
      this.refferalCodeInput = this.account.refer_code;
    },

    async sendNewRefferalCode(click = false) {
      if (this.refferalCodeInput.length === 8) {
        if (this.refferalCodeInput === this.account.refer_code) {
          this.showAppendIconRefferalCode = false;
          this.errorMesageRefferalCode = "";
          return;
        }

        this.showAppendIconRefferalCode = true;
        this.errorMesageRefferalCode = "";

        if (click) {
          try {
            this.updateCodeRefAction = true;
            const account = await this.updateCodeRef({
              account_id: this.clientData.id,
              refer_code: this.refferalCodeInput,
            });
            this.refferalCodeSuccessStatus = true;
            this.account.refer_code = this.refferalCodeInput;
            this.account.refer_link = account.refer_link;
            this.account.refer_link_short = account.refer_link_short;
            this.showAppendIconRefferalCode = false;
          } catch (e) {
            this.errorMesageRefferalCode = "Ошибка сохранения кода";
            this.showAppendIconRefferalCode = false;
          } finally {
            this.updateCodeRefAction = false;
          }
        }
      } else {
        this.refferalCodeSuccessStatus = false;
        this.errorMesageRefferalCode = "Код должен состоять из 8 цифр";
        this.showAppendIconRefferalCode = false;
      }
    },

    async init() {
      try {
        this.loading = true;
        this.account = await this.accountReferInfo(this.clientData.id);
        this.refferalCodeInput = this.account.refer_code;
      } finally {
        this.loading = false;
      }
    },
    async sendReferCodeClick() {
      if (!this.accountClient.consent_sms) {
        return this.$notify({
          type: "error",
          title: "Отправить в SMS",
          text: "Клиент не дал согласие на СМС",
        });
      }

      try {
        this.sendReferCodeSmsAction = true;
        const result = await this.sendReferCodeSMS(this.clientData.id);
        this.account = Object.assign(this.account, result);
      } finally {
        this.sendReferCodeSmsAction = false;
      }
    },
    async setReferrerByCodeClick() {
      try {
        this.setReferrerByCodeAction = true;
        const result = await this.setReferrerByCode({
          account_id: this.clientData.id,
          refer_code: this.refer_code,
        });
        this.account = Object.assign(this.account, result);
      } finally {
        this.setReferrerByCodeAction = false;
      }
    },
    async setAccountReferrerClick() {
      try {
        this.setAccountReferrerAction = true;
        const result = await this.setAccountReferrer({
          account_id: this.clientData.id,
          master_account_id: this.masterAccountId,
        });
        this.account = Object.assign(this.account, result);
      } finally {
        this.setAccountReferrerAction = false;
      }
    },
    async removeReferralClick(referral) {
      try {
        referral.removeReferralAction = true;
        await this.removeReferral({
          account_id: this.clientData.id,
          referral_account_id: referral.id,
        });
        const index = this.account?.referral_accounts.findIndex(
          (x) => x.id === referral.id
        );
        if (index >= 0) this.account.referral_accounts.splice(index, 1);
      } finally {
        referral.removeReferralAction = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.link-col {
  word-break: break-all;
}
</style>
