<template>
  <base-side-panel
    ref="side-panel__edit-client"
    v-model="internalActive"
    :width="580"
    class="side-panel__edit-client"
  >
    <div
      ref="side-panel__edit-client__header"
      class="side-panel__edit-client__header"
      :class="{usual: !internalMode, extended: internalMode}"
    >
      <div class="side-panel__edit-client__tabs">
        <v-btn
          class="side-panel__edit-client__tabs-btn"
          :class="{active: !internalMode}"
          color="primary-100"
          @click="internalMode = false"
        >
          Обычный
        </v-btn>
        <v-btn
          class="side-panel__edit-client__tabs-btn"
          :class="{active: internalMode}"
          color="primary-100"
          @click="internalMode = true"
        >
          Расширенный
        </v-btn>
      </div>
      <div class="side-panel__edit-client__contacts">
        <div class="contacts__block-right">
          <div class="contacts-full_name">
            <p class="title-m-bold">
              {{ getFIO(accountClient.user) }}
            </p>
          </div>
          <div class="contacts-online">
            <p class="body-s-semibold neutral-600--text">
              {{ (accountClient.user && accountClient.user.last_activity) ? getLastActivity(accountClient.user.last_activity) : '-' }}
            </p>
          </div>
          <div
            v-if="accountClient.deleted_at"
            class="contacts-online"
          >
            <p class="body-s-semibold error--text">
              {{ 'Удален ' + this.$moment.utc(accountClient.deleted_at).local().format(this.$config.date.DATETIME_FORMAT_MIN2) }}
            </p>
          </div>
        </div>
        <div class="contacts__block-left">
          <v-skeleton-loader
            v-if="clientCardLoading"
            v-bind="attrs"
            type="avatar"
          />
          <img
            v-else
            :src="[(accountClient.user && accountClient.user.avatar) ? accountClient.user.avatar : '']"
            @error="e => e.target.src = img404"
          >
        </div>
      </div>
      <div
        v-if="internalMode"
        ref="side-panel__edit-client__sub-tabs"
        class="side-panel__edit-client__sub-tabs"
      >
        <ul
          class="tab-list"
        >
          <li
            v-for="(tab, index) in extendedTabs"
            :key="'tab-' + tab.id"
            class="tab-item"
            :class="[tab.icon ? 'd-flex' : '', {active: tab.active}]"
          >
            <p
              class="body-m-medium neutral-500--text"
              @click="changeExtendedTab($event, index)"
            >
              {{ tab.title }}
            </p>
            <iconify-icon
              v-if="tab.icon"
              :icon="tab.icon"
              :class="tab.iconClass"
              width="18"
            />
          </li>
        </ul>
      </div>
    </div>
    <div
      :class="{usual: !internalMode, extended: internalMode}"
      class="side-panel__edit-client__body"
    >
      <mode-usual
        v-if="!internalMode && existsData"
        :client-data="data"
      />
      <mode-extended
        v-if="internalMode && existsData"
        :client-data="data"
        :tabs="extendedTabs"
      />
    </div>
  </base-side-panel>
</template>

<script>
  // components
  import ModeExtended from './components/edit-client/ModeExtended'
  import ModeUsual from './components/edit-client/ModeUsual'
  // mixins
  import Convertor from '@/mixins/convertor.js'
  import User from '@/mixins/user.js'

  export default {
    components: {
      ModeExtended,
      ModeUsual,
    },
    mixins: [Convertor, User],
    model: {
      prop: 'active',
      event: 'changeState',
    },
    props: {
      extendedMode: {
        type: Boolean,
        default: false,
      },
      active: {
        type: Boolean,
      },
      data: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        internalMode: this.extendedMode,
        internalActive: this.active,
        img404: this.$config.app.fileStorage + 'users/avatars/default.png',
        attrs: {
          class: 'mb-0',
          boilerplate: true,
          elevation: 0,
        },
        extendedTabs: [
          {
            id: 1,
            title: 'Клиент',
            active: true,
            componentName: 'TabClient',
          },
          {
            id: 2,
            title: 'Карта',
            active: false,
            componentName: 'TabMap',
          },
          {
            id: 3,
            title: 'Документы',
            active: false,
            componentName: 'TabDocument',
          },
          {
            id: 4,
            title: 'Операции',
            active: false,
            icon: 'tabler-external-link',
            iconClass: 'icon-external-link neutral-500--text',
            componentName: 'TabOperation',
          },
        ],
      }
    },
    computed: {
      clientCardLoading () {
        return this.$store.getters['crm/clientCard/loading']
      },
      program () {
        return this.$store.getters['company/program/program']
      },
      accountClient () {
        return this.$store.getters['crm/clientCard/client']
      },
      existsData () {
        return (this.data && Object.keys(this.data).length)
      },
    },
    watch: {
      active (v) {
        this.internalActive = this.active
        if (v) {
          this.getData()
        }
      },
      internalActive () {
        this.$emit('changeState', this.internalActive)
      },
      internalMode (v) {
        this.$emit('update:mode', v)
      },
    },
    async mounted () {
      this.shadowHeaderAtScroll()
    },
    methods: {
      getLastActivity (date) {
        if (date) return 'Был(а) в сети ' + this.$moment.utc(date).local().format(this.$config.date.DATETIME_FORMAT_MIN2)
        return 'Был(а) в сети - '
      },
      changeExtendedTab (event, tabIndex) {
        this.extendedTabs.map((tab) => {
          tab.active = false
          return tab
        })

        this.extendedTabs[tabIndex].active = true
      },
      shadowHeaderAtScroll () {
        this.$refs['side-panel__edit-client'].$el.querySelector('.v-navigation-drawer__content').addEventListener('scroll', (e) => {
          if (e.srcElement.scrollTop > 5) {
            this.$refs['side-panel__edit-client__header'].style.boxShadow = '0px 7px 20px rgba(88, 93, 106, 0.1)'
          } else {
            this.$refs['side-panel__edit-client__header'].style.boxShadow = 'none'
          }
        })
      },
      async getData () {
        console.log('getData', this.data)
        if (this.data && Object.keys(this.data).length > 0) {
          await this.fetchData()
        }
      },
      async fetchData () {
        console.log('fetchData')
        try {
          this.$store.commit('crm/clientCard/SET_LOADING', true)
          // обнуляем пользователя карты
          this.$store.commit('crm/clientCard/SET_CLIENT', {})
          //
          await this.$store.dispatch('company/bonus_resources/GetList', this.program.id) // бонусные операции
          await this.$store.dispatch('crm/segment/segments', { program_id: this.program.id }) // сегменты программы
          await this.$store.dispatch('crm/clientCard/getAccountBalances', this.data) // бонусные балансы
          await this.$store.dispatch('crm/clientCard/getAccountClient', this.data) // пользователь карты
        } finally {
          this.$store.commit('crm/clientCard/SET_LOADING', false)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
  @import "@/styles/_typography.sass";

  .side-panel__edit-client {
    .side-panel__back {
      z-index: 124;
      position: fixed;
      color: #4776E6;
      cursor: pointer;
    }
    .v-navigation-drawer__content {
      &::-webkit-scrollbar {
        width: 0 !important
      }
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
    }
    .side-panel__edit-client__header {
      width: 100%;
      position: fixed;
      padding: 0 34px;
      margin-top: 0 !important;
      margin-left: 0 !important;
      background-color: #fff;
      z-index: 123;
      padding-top: 29px;
      .side-panel__edit-client__tabs {
        text-align: right;
        .side-panel__edit-client__tabs-btn {
          width: 112px;
          height: 33px !important;
          &.active {
            background: linear-gradient(90deg, $primary-base 0%, $secondary-base 100%) !important;
            span.v-btn__content {
                color: $neutral-100;
            }
          }
          span.v-btn__content {
            @include body-s-semibold;
            color: $primary-base;
          }
          &:nth-child(1) {
            border-radius: 10px 0px 0px 10px;
          }
          &:nth-child(2) {
            border-radius: 0px 10px 10px 0px;
          }
        }
      }
      .side-panel__edit-client__sub-tabs {
        margin-top: 21px;
        .tab-list {
          display: flex;
          list-style: none;
          padding: 0;
          .tab-item {
            position: relative;
            cursor: pointer;
            margin-right: 20px;
            p {
              transition: 0.2s ease-in-out;
              margin-bottom: 0;
            }
            .icon-external-link {
              transition: 0.2s ease-in-out;
              margin-left: 5px;
            }
            &:hover {
              p {
                  color: $primary-base !important;
              }
              .icon-external-link {
                  color: $primary-base !important;
              }
            }
            &.active {
              p {
                color: $primary-base !important;
              }
              .icon-external-link {
                color: $primary-base !important;
              }
              &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background: $primary-base;
                border-radius: 100px;
                bottom: -6px;
              }
            }
          }
        }
      }
      .side-panel__edit-client__contacts {
        display: flex;
        justify-content: space-between;
        margin-top: 48px;
        .contacts__block-right {
          width: calc(100% - 82px);
          margin-right: 15px;
          .contacts-full_name {
              p {
                margin-bottom: 3px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
          }

          .contacts-online {
            margin-top: 8px;
          }
        }

        .contacts__block-left {
          margin-top: 5px;
          img {
            width: 67px;
            height: 67px;
            border-radius: 67px;
          }
        }
      }
      &.extended {
        padding-bottom: 26px;
      }
    }
    .info-list {
      display: flex;
      flex-direction: column;
      padding: 0;
      list-style: none;
      .info-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        p {
          margin-bottom: 0!important;
          width: 35%;
        }
        .right-text {
          display: block;
          width: 65%;
        }
      }
    }
    .v-input.theme--light {
      .v-input__control {
        .v-input__slot {
          fieldset {
            border-radius: 10px;
          }
          input {
            color: $neutral-900 !important;
            @include body-m-medium;
            &::placeholder {
              color: $neutral-600 !important;
              @include body-m-medium;
            }
          }
        }
      }
    }
    .side-panel__edit-client__body {
      padding: 0 34px 0 34px;
      &.usual {
        margin-top: 210px !important;
      }
      &.extended {
        margin-top: 270px !important;
      }
    }
  }
</style>
