<template>
  <v-container class="dialog-field-block pa-0">
    <v-row>
      <v-col class="body-l-semibold neutral-900--text">
        {{ title }}
      </v-col>
    </v-row>
    <v-row v-if="description || $slots.description" class="description" no-gutters>
      <v-col class="body-m-regular neutral-700--text">
        <slot name="description">
          {{ description }}
        </slot>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="dialog-field-block__input">
        <v-skeleton-loader
          v-if="loading"
          :type="loadingType"
        />
        <slot
          v-else
          name="default"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'DialogFieldBlock',
    components: { },
    props: {
      title: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      loading: {
        type: Boolean,
        default: false,
      },
      loadingType: {
        type: String,
        default: 'paragraph',
      },
    },

    computed: {},
  }
</script>
<style lang="scss" scoped>
.description{
  margin-top: 6px;
}
</style>
