<template>
  <v-autocomplete
    v-model="valueMutation"
    v-bind="$attrs"
    :disabled="disabled"
    :items="items"
    item-text="name"
    item-value="id"
    :deletable-chips="deletableChips"
    :placeholder="multiple? 'Выберите сегмент(ы)': 'Выберите сегмент'"
    outlined
    :label="label"
    :readonly="readonly"
    :clearable="clearable"
    :rules="rules"
    :multiple="multiple"
    :return-object="returnObject"
    hide-details
    hide-selected
    chips
  >
    <template v-slot:selection="{item}">
      <segment-chip
        :name="item.name"
        :color="item.color"
        :chipAuto="item.type == 'auto'"
        :is_public="item.is_public"
        chip
        :close="deletableChips && readonly != true"
        @click:close="remove(item)"
      />
    </template>
    <template v-slot:item="{item}">
      <segment-chip
        :chipAuto="item.type == 'auto'"
        :name="item.name"
        :color="item.color"
        :is_public="item.is_public"
        max-width="380px"
      />
    </template>
  </v-autocomplete>
</template>

<script>
  import Convertor from '@/mixins/convertor'
  export default {
    name: 'SegmentAutocomplete',
    components: {
        SegmentChip: () => import('@/components/segment/SegmentChip'),
    },
    mixins: [Convertor],
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      value: { type: [Number, Object, Array], default: undefined },
      multiple: {
        type: Boolean,
        default: false,
      },
      returnObject: {
        type: Boolean,
        default: true,
      },
      rules: {
        type: Array,
        default: undefined
      },
      label: {
        type: [String],
        default: ""
      },
      clearable: {
        type: [Boolean],
        default: true
      },
      readonly: {
        type: [Boolean],
        default: false
      },
      deletableChips: {
        type: [Boolean],
        default: true
      },
      disabled: {
        type: [Boolean],
        default: false
      },
    },
    computed: {
      valueMutation: {
        get: function () {
          return this.value
        },
        set: function (v) {
          this.$emit('update', v)
        },
      },
    },
    methods: {
    remove (item) {
        const index = this.valueMutation.findIndex(x => x.id === item.id)
        if (index >= 0) this.valueMutation.splice(index, 1)
      },
    },
    mounted () {},

  }
</script>

<style lang="scss" scoped>
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
.segment-chip {
  // max-width:20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
}
</style>
