import ApiService from '@/api/api-client'

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async createAccountSale ({ commit }, payload) {
            /*
             'account_id' => 'required|integer|exists:accounts,id',
            'value' => "required|integer|gt:0|max:" . ProgramSale::MAX_VALUE,
            'comment' => 'nullable|string|max:255'
            */
            const result = await ApiService.post('/api-cabinet/program/account/sales/create', payload)
            this._vm.$notify({
                type: 'success',
                title: 'Продажа',
                text: 'Продажа зафиксирована',
            })
            return result
        },
        async accountSaleCalc ({ commit }, { request, cancelToken }) {
            /*
             'account_id' => ['required', 'integer', Rule::exists(Account::getTableName(), 'id')],
            'sum' => ['required', 'integer', 'gt|0', 'max:' . ProgramSale::MAX_VALUE],
            'bonus_res_id' => ['required', 'integer', Rule::exists(ProgramBonusResource::getTableName(), 'id')]
            */
            const result = await ApiService.post('/api-cabinet/program/account/sales/calc', request, {
                cancelToken,
            })
            return result
        },

    },
    getters: {

    },
}
