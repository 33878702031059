import { defaultProps as defaultPickerProps } from '@/components/base/components/date-range-picker/props'
import { defaultProps as defaultTextFieldProps } from '@/components/base/components/text-field/props'
import { config } from '@/config'

export const defaultProps = {
  startDate: {
    type: [String, Date, Object],
    default: '',
  },
  endDate: {
    type: [String, Date, Object],
    default: '',
  },
  dateFormat: {
    type: String,
    default: config.date.DATE_FORMAT,
  },
  rules: {
    type: Array,
    default: undefined,
  },
}

export const mergedPickerProps = {
  ...defaultPickerProps,
  ...defaultTextFieldProps,
  ...defaultProps,
}
