export default {
    name: 'permission',

    props: {

    },
    data () {
        return {

        }
    },
    computed: {
        authUser () {
            return this.$store.getters.user
        },
        programId () {
            return this.$store.getters.programId
        },
        userPermissions () {
            return this.userRoles.reduce((result, role) => result.concat(role.permissions || []), []).filter((v, i, a) => a.findIndex(x => x.id === v.id) === i)
        },
        userPermissionsSet () {
            return new Set(this.userPermissions.map(x => x.name))
        },
        userRoles () {
            if (this.authUser.access_programs && this.programId) {
                const programIndex = this.authUser.access_programs.findIndex(x => x.id === this.programId)
                if (programIndex >= 0) {
                   return this.authUser.access_programs[programIndex].roles
                }
            } else if (this.authUser.access_merchant) {
                return this.authUser.access_merchant.auth_user_roles
            }
            return []
        },
        userRolesSet () {
            return new Set(this.userRoles.map(x => x.name))
        },

    },

    methods: {
        hasProgramPermission (permission, programId = null) {
            return this.authUser && this.authUser.hasProgramPermission(permission, programId)
        },
        hasPermission (permission, programId = null) {
            return this.hasProgramPermission(permission, programId)
        },
        hasAnyPermission (permissionList) {
            return permissionList.some(permission => this.userPermissionsSet.has(permission))
        },
    },
}
