import ApiService from '@/api/api-client'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    links: [],
  },
  mutations: {
    links (state, payload) {
      state.links = payload
    },
    update (state, { linkId, payload }) {
      const idx = state.links.findIndex(offer => offer.id === linkId)
      if (idx !== -1) {
        Vue.set(state.links, idx, Object.assign(state.links[idx], payload))
      }
    },
    deleteById (state, linkId) {
      state.links = state.links.filter(link => link.id !== linkId)
      Vue.set(state.links, '', [...state.links])
    },
    add (state, payload) {
      const links = [...state.links, payload]
      state.links = links
    },
  },
  actions: {
    async list ({ commit }, programId) {
      const result = await ApiService.get(`/api-cabinet/program/invite-link/list?program_id=${programId}`)

      commit('links', result)
    },
    async create ({ commit }, payload) {
      const result = await ApiService.post('/api-cabinet/program/invite-link', payload)
      commit('add', result)
    },
    async update ({ commit, rootGetters }, { linkId, payload }) {
      payload.link_id = linkId

      const result = await ApiService.put('/api-cabinet/program/invite-link', payload)

      commit('update', { linkId: result.id, payload: result })
    },
    async changeActive ({ state, commit }, { linkId, active }) {
      const currIdx = state.links.findIndex(offer => offer.id === linkId)
      if (currIdx !== -1) {
        const link = state.links[currIdx]

        link.loadingChangeActive = true

        commit('update', { linkId: link.id, payload: link })

        const result = await ApiService.put('/api-cabinet/program/invite-link/active', {
          link_id: linkId,
          active: active,
        })

        link.loadingChangeActive = false
        link.active = result.active

        commit('update', { linkId: result.id, payload: link })
      }
    },
    async delete ({ state, commit }, linkId) {
      const isExists = state.links.some(link => link.id === linkId)
      if (isExists) {
        try {
          await ApiService.delete(`/api-cabinet/program/invite-link?link_id=${linkId}`)
          commit('deleteById', linkId)
        } catch (err) {
          console.log('inviteLink/inviteLink/delete', err)
        }
      }
    },
  },
  getters: {
    links (state) {
      return state.links
    },
  },
}
