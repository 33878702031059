/**
 * @url https://innologica.github.io/vue2-daterange-picker/#props
 */
export const defaultHideProps = {
  hideDefaultFooter: Boolean,
}

export const defaultProps = {
  minDate: [String, Date],
  maxDate: [String, Date],
  showWeekNumbers: [String, Date],
  linkedCalendars: {
    type: Boolean,
    default: false,
  },
  singleDatePicker: [Boolean, String],
  showDropdowns: Boolean,
  timePicker: Boolean,
  timePickerIncrement: Number,
  timePicker24Hour: Boolean,
  timePickerSeconds: Boolean,
  autoApply: Boolean,
  localeData: Object,
  dateRange: Object,
  ranges: [Object, Boolean],
  opens: String,
  dateFormat: Function,
  alwaysShowCalendars: Boolean,
  disabled: Boolean,
  controlContainerClass: [Object, String],
  appendToBody: Boolean,
  calculatePosition: Function,
  closeOnEsc: Boolean,
  readOnly: Boolean,
  // hide props
  ...defaultHideProps,
}
