<template>
  <div>
    <bonus-account :client-data="clientData" />
    <card-account :client-data="accountClient" />
    <owner-account :client-data="accountClient" />
    <sale-account :client-data="accountClient" />
    <div class="tab-client tab-client__segment">
      <div class="tab-client__head segment__head">
        <p class="body-l-semibold neutral-900--text">
          Сегменты
        </p>
        <p class="body-m-regular neutral-700--text">
          Выберите к каким сегментам относится клиент.
        </p>
      </div>
      <v-row
        class="tab-client__content segment__content"
        justify="space-between"
      >
        <v-col cols="12">         
          <SegmentAutocomplete
            v-model="accountClient.segments"
            :items="segments"
            multiple
          />

          <!-- <base-combobox
            v-model="clientSegments"
            not-found-placeholder="Сегменты не найдены!"
            :items="segments"
            item-value="id"
            item-text="name"
          /> -->
        </v-col>
      </v-row>
    </div>
    <div class="tab-client tab-client__user">
      <div class="tab-client__head user__head">
        <p class="body-l-semibold neutral-900--text">
          Имя клиента
        </p>
        <p class="body-m-regular neutral-700--text">
          Если вам нужно реальное имя клиента, указанное в паспорте, введите его в поле ниже.
        </p>
      </div>
      <v-row class="tab-client__content user__content">
        <v-col>
          <base-text-field
          v-model="accountClient.client_name"
          placeholder="Введите реальное имя клиента"
          validation-placement="left"
        />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group
            v-model="accountClient.gender"
            class="mt-0"
            hide-details
            row
          >
            <v-radio
              v-for="item in genderList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </v-radio-group>
        </v-col>
      </v-row>
    </div>
    <div class="tab-client tab-client__birthday">
      <div class="tab-client__head birthday__head">
        <p class="body-l-semibold neutral-900--text">
          Дата рождения
        </p>
        <p class="body-m-regular neutral-700--text">
          Если вам нужна реальная дата рождения клиента, указанная в паспорте, введите её в поле ниже.
        </p>
      </div>
      <div class="tab-client__content birthday__content">
        <base-datetime-picker-field
          v-model="accountClient.birthday"
          placeholder="Дата рождения клиента (дд.мм.гггг)"
          :with-time="false"
          :max-date="$moment().toDate()"
          clearable
        />
      </div>
    </div>
    <div class="tab-client tab-client__birthday">
      <div class="tab-client__head birthday__head">
        <p class="body-l-semibold neutral-900--text">
          Почта
        </p>
        <p class="body-m-regular neutral-700--text">
          Заполните поле для отправки уведомлений
        </p>
      </div>
      <div class="tab-client__content birthday__content">
        <v-text-field
          v-model="accountClient.email"
          placeholder="Введите еmail"
          class="auth-text-field"
          outlined
          :rules="emailRules"
          :validate-on-blur="false"
          hide-details="auto"
        >
        </v-text-field>
      </div>
    </div>
    <div class="tab-client tab-client__birthday">
      <div class="tab-client__head birthday__head">
        <p class="body-l-semibold neutral-900--text">
          Код карты
        </p>
        <p class="body-m-regular neutral-700--text">
          Физический код карты
        </p>
      </div>
      <div class="tab-client__content birthday__content">
        <v-text-field
          v-model="accountClient.card_code"
          placeholder="Код карты"
          class="auth-text-field"
          outlined
          maxlength="255"
          :validate-on-blur="false"
          hide-details="auto"
        >
        </v-text-field>
      </div>
    </div>
    
    <BaseDialogFieldBlock
      title="Согласия клиента"
      style="margin-top: 34px"
    >
      <v-row>
        <v-col>
          <base-ext-switch
            v-model="accountClient.consent_sms"
            label="Согласие на СМС уведомления"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <base-ext-switch
            v-model="accountClient.consent_advertising"
            label="Согласие на рекламу"
          />
        </v-col>
      </v-row>
    </BaseDialogFieldBlock>
    <div class="tab-client tab-client__user">
      <div class="tab-client__head user__head">
        <p class="body-l-semibold neutral-900--text">
          Комментарий
        </p>
        <p class="body-m-regular neutral-700--text">
          Добавьте комментарии к данному клиенту.
        </p>
      </div>
      <div class="tab-client__content user__content">
        <v-textarea
          v-model="accountClient.comment"
          :rows="3"
          auto-grow
          placeholder="Введите комментарий (необязательно)"
          outlined
          maxlength="4096"
          hide-details
        />
      </div>
    </div>
   

    <v-btn
      class="btn-success"
      color="primary"
      :loading="loading"
      :disabled="false"
      @click="accountUpdate()"
    >
      <iconify-icon
        class="icon-success"
        icon="ion-checkmark-circle-outline"
        width="21"
      />
      <p class="body-m-semibold mb-0">
        Сохранить
      </p>
    </v-btn>
  </div>
</template>

<script>
  // components
  import BonusAccount from '@/components/side-panel/components/edit-client/BonusAccount'
  import CardAccount from "@/components/side-panel/components/edit-client/CardAccount";
  import OwnerAccount from "@/components/side-panel/components/edit-client/OwnerAccount"
  import SaleAccount from "@/components/side-panel/components/edit-client/SaleAccount";
  import SegmentAutocomplete from '@/components/segment/SegmentAutocomplete.vue'
  import SegmentChip from '@/components/segment/SegmentChip'
  import { validEmail } from '@/utils/validate.js'
  import moment from 'moment'

  export default {
    name: 'TabClient',
    components: {
      SaleAccount,
      BonusAccount,
      SegmentChip,
      OwnerAccount,
      CardAccount,
      SegmentAutocomplete,
      SaleAccount
    },
    props: {
      clientData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        loading: false,
        clientSegments: [],
        segmentToAdd: null,
        emailRules: [
          v => ( !v || validEmail(v)) || 'E-mail неверного формата',
        ],
      }
    },
    computed: {
      accountClient () {
        return this.$store.getters['crm/clientCard/client']
      },
      user () {
        const client = this.$store.getters['crm/clientCard/client']
        if (client && client.user) return client.user
        return {}
      },
      segments () {
        return this.$store.getters['crm/segment/segments'] || []
      },
       genderList () {
        return [
          { value: 'm', label: 'Мужчина' },
          { value: 'f', label: 'Женщина' },
        ]
      },
    },
    mounted () {
      if (this.accountClient) {
        /*
        if (this.accountClient.birthday) {
          this.accountClient.birthday = this.$moment(this.accountClient.birthday).format(this.$config.date.DATE_FORMAT)
        }
         */
        // объявление сегментов
        if (this.accountClient.segments) {
          this.clientSegments = Object.copy(this.accountClient.segments)

          this.segmentToAdd = []
          this.clientSegments.forEach(el => {
            const index = this.segments.findIndex(x => x.id === el.id)
            this.segmentToAdd.push(this.segments[index])
          })

        } else {
          this.clientSegments = []
        }
      }
    },
    methods: {
      addSegment () {
        if (!this.segmentToAdd) return
        this.clientSegments.push(this.segmentToAdd)
        this.segmentToAdd = null
      },
      removeSegment (index) {
        this.clientSegments.splice(index, 1)
      },
      getGender (gender) {
        if (gender === 'm') return 'мужской'
        if (gender === 'f') return 'женский'
        return '-'
      },
      async accountUpdate () {
        try {
          this.loading = true
          const payload = {
            id: this.accountClient.id,
            client_name: this.accountClient.client_name,
            gender: this.accountClient.gender,
            comment: this.accountClient.comment,
            birthday: this.accountClient.birthday ? moment(this.accountClient.birthday).format(this.$config.date.DATE_FORMAT) : this.accountClient.birthday,
            consent_sms: this.accountClient.consent_sms,
            consent_advertising: this.accountClient.consent_advertising,
            email: this.accountClient.email,
            card_code: this.accountClient.card_code,
            segments:  this.accountClient.segments.map(item => item.id)  // передаем только id сегментов
          }
          await this.$store.dispatch('crm/client/updateAccount', payload)
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.tab-client {
  .tab-client__head {
    margin-top: 34px;
    p:nth-child(1) {
      margin-bottom: 6px;
    }
    p:nth-child(2) {
      margin-bottom: 20px;
    }
  }
}
.tab-client__info {
  margin-top: 36px;
}
.info-item {
  p {
    position: relative;
    top: 3px;
  }
  // &:nth-child(2) {
  //   margin-bottom: 24px !important;
  // }
}
.btn-success {
  margin-top: 34px;
  margin-bottom: 34px;
  vertical-align: middle;
  .icon-success {
    margin-right: 10px;
  }
}
</style>
