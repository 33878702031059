<template>
  <keep-alive>
    <component
      :is="activeTab.componentName"
      :client-data="clientData"
    />
  </keep-alive>
</template>

<script>
  import TabClient from './tabs/TabClient'
  import TabFields from './tabs/TabFields'
  import TabDocument from './tabs/TabDocument'
  import TabMap from './tabs/TabMap'
  import TabOperation from './tabs/TabOperation'
  import TabReferral from './tabs/TabReferral'

  export default {
    components: {
      TabClient,
      TabFields,
      TabDocument,
      TabMap,
      TabOperation,
      TabReferral,
    },
    props: {
      clientData: {
        type: Object,
        default: () => {
          return {}
        },
      },
      tabs: {
        type: Array,
        default () {
          return []
        },
      },
    },
    data () {
      return {}
    },
    computed: {
      activeTab () {
        return this.tabs.filter(tab => tab.active)[0]
      },
    },
    methods: {
    },
  }
</script>
