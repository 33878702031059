import store from '@/store'

export function guardHandler (to, from) {
  const programs = store.getters['company/program/programs']
    console.log('guard-empty-program', { programs })
    if (programs && programs.length > 0) {
      return undefined
    } else {
      return {
        name: 'empty-company',
      }
    }
}

export default function (to, from, next) {
  console.log('guard-empty-program', to, from)
  const redirect = guardHandler(to, from)
  if (next) {
    next(redirect)
  } else {
    return redirect
  }
}
