<template>
  <div class="tab-map">
    <div class="tab-map__action">
      <div class="action__header">
        <p class="body-l-semibold">Действия с картой</p>
      </div>
      <div class="action__route">
        <div v-if="loading">
          <v-skeleton-loader v-bind="attrs" type="heading" />
          <v-skeleton-loader v-bind="attrs" type="heading" />
          <v-skeleton-loader v-bind="attrs" type="heading" />
        </div>
        <div v-else>
          <div v-if="goals && goals.length">
            <div>
              <v-timeline dense align-top>
                <v-timeline-item
                  v-for="(item, i) in goals"
                  :key="i"
                  small
                  color="white"
                  icon="$iconify_feather-clock"
                  icon-color="primary"
                  fill-dot
                >
                  <v-row class="body-s-semibold" style="padding-top: 4px">
                    <v-col cols="auto">
                      {{
                        item.created_at
                          ? $moment(item.created_at)
                              .local()
                              .format($config.date.DATETIME_FORMAT)
                          : "-"
                      }}
                    </v-col>
                    <v-col>
                      <div>{{ item.goal_type }}</div>
                      <div v-if="$IsDebugMode()" class="text-caption">
                        {{ item.payload }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
                <v-timeline-item v-if="showBtnStill" hide-dot>
                  <v-btn
                    depressed
                    color="secondary"
                    :loading="loadingBtn"
                    @click="loadingData"
                  >
                    Показать еще
                  </v-btn>
                </v-timeline-item>
              </v-timeline>
            </div>
          </div>
          <div v-else>Действий пока нет</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TabMap",
  props: {
    clientData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loadingBtn: false,
      loading: false,
      limit: 20,
      offset_id: null,
      attrs: {
        class: "mb-1",
        boilerplate: true,
        elevation: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      accountEventPickList: "company/event_broadcasters/accountEventPickList",
      countGoals: "crm/clientCard/countGoals",
    }),
    accountClient() {
      return this.$store.getters["crm/clientCard/client"];
    },
    showBtnStill() {
      if (this.countGoals > this.limit) {
        return true;
      } else {
        return false;
      }
    },
    goals() {
      return this.$store.getters["crm/clientCard/goals"].map((item) => {
        const action = this.$_.findWhere(this.accountEventPickList, {
          id: item.goal_type_enum,
        });
        item.goal_type = action ? action.name : item.goal_type_enum;
        return item;
      });
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    ...mapActions({
      GetEventList: "company/event_broadcasters/GetEventList",
    }),
    async fetchData() {
      try {
        this.loading = true;
        const item = {
          account_id: this.accountClient.id,
          page: 1,
          limit: this.limit,
        };
        await this.GetEventList();
        await this.$store.dispatch("crm/clientCard/getGoals", item);
        if (this.goals.length > 0) {
          this.offset_id = this.goals[0].id;
        }
      } finally {
        this.loading = false;
      }
    },
    async loadingData() {
      this.loadingBtn = true;
      this.limit = this.limit + 20;
      const item = {
        account_id: this.accountClient.id,
        page: 1,
        limit: this.limit,
        offset_id: this.offset_id,
      };
      try {
        await this.$store.dispatch("crm/clientCard/getGoals", item);
      } finally {
        this.loadingBtn = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.tab-map {
  .tab-map__info {
    .info-item {
      margin-bottom: 12px;
      .right-text {
        width: 56%;
      }
    }
  }
  .tab-map__action {
    margin-top: 22px;
    p {
      margin-bottom: 0;
    }
    .action__route {
      margin-top: 20px;
      .route__block {
        margin-bottom: 20px;
        display: flex;
        .route__icon {
          position: relative;
          margin-right: 9px;
          .icon-clock {
            color: $primary-base;
          }
        }
        &:not(:last-child) {
          .route__icon {
            &:before {
              content: "";
              position: absolute;
              width: 1px;
              height: 30px;
              background: $neutral-250;
              border-radius: 20px;
              top: calc(50% + 2px);
              left: 7px;
            }
          }
        }
        .route__info {
          p {
            &:nth-child(2) {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
}
</style>
