export function existsField (obj, field) {
  return Object.keys(obj).includes(field)
}

export function declOfNum (number, titles) {
  number = Number(number)
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
}

export function filter (name, ...args) {
  return this.$options.filters[name](...args)
}
