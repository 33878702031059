function existsKeys (obj) {
  return Boolean(Object.keys(obj).length)
}

export default {
  data () {
    return {
      parentDrawerDialog: {},
      pddContent: {},
      isParentNav: false,
      navScrollTop: 0,
      navScrollBottom: 0,
      navScrollLeft: 0,
      navScrollRight: 0,
      navClientWidth: 0,
      navClientHeight: 0,
      elTopFromNav: {},
    }
  },
  mounted () {
    setTimeout(() => {
      if (!existsKeys(this.parentDrawerDialog)) this.setDrawerDialogOfParents()
      if (existsKeys(this.parentDrawerDialog)) {
        if (!existsKeys(this.pddContent)) {
          this.pddContent = this.parentDrawerDialog?.$el?.querySelector('.v-navigation-drawer__content')
        }
        this.isParentNav = true
        this.navClientWidth = this.parentDrawerDialog?.$el?.clientWidth || 0
        this.navClientHeight = this.parentDrawerDialog?.$el?.clientHeight || 0
        this.navScrollTop = this.parentDrawerDialog?.$el?.scrollTop || 0
        this.navScrollBottom = this.parentDrawerDialog?.$el?.scrollBottom || 0
        this.navScrollLeft = this.parentDrawerDialog?.$el?.scrollLeft || 0
        this.navScrollRight = this.parentDrawerDialog?.$el?.scrollRight || 0
      }
    }, 0)
  },
  methods: {
    setDrawerDialogOfParents () {
      const parents = this.getParentRecursiveNodes(this)
      this.parentDrawerDialog = parents.find(p => {
        return p.$el && p.$el.classList && p.$el.classList.contains('v-navigation-drawer')
      }) || {}
    },
    getParentRecursiveNodes (node, nodes = []) {
      if (node.$parent) {
        nodes.push(node.$parent)
        this.getParentRecursiveNodes(node.$parent, nodes)
      }
      return nodes
    },
    getElTopFromNav (el, alias) {
      this.elTopFromNav[alias] = el.getBoundingClientRect().top
      return this.elTopFromNav[alias]
    },
    addInNavEventScroll (func) {
      if (existsKeys(this.parentDrawerDialog)) {
        if (!existsKeys(this.pddContent)) {
          this.pddContent = this.parentDrawerDialog?.$el?.querySelector('.v-navigation-drawer__content')
        }

        this.pddContent.addEventListener('scroll', (e) => func(e))
        return true
      }
      return false
    },
    removeInNavEventScroll (func) {
      if (existsKeys(this.pddContent)) this.pddContent.removeEventListener('scroll', func)
    },
    getFromHideNodeAttribute (node, attrName) {
      const clone = node.cloneNode(true)
      clone.setAttribute('style', 'display:block !important')
      clone.style.visibility = 'hidden'
      node.parentNode.append(clone)

      const attr = clone[attrName]

      clone.remove()

      return attr
    },
  },
}
