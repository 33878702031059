<template>
  <div class="bonus-accounts">
    <div class="bonus-accounts__header">
      <p class="body-l-semibold">Продажи</p>
    </div>

    <div class="tab-client tab-client__info">
      <ul class="info-list">
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">Общая сумма</p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{
              Number(clientData.sales_value_sum/100).toLocaleString(undefined, {style: this.program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.program.currency.alpha3})
            }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">Сумма оплат</p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{
              Number(clientData.sales_cash_value_sum/100).toLocaleString(undefined, {style: this.program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.program.currency.alpha3})
            }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">Количество</p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{
              clientData.sales_count
            }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">Средний чек</p>
          <div class="right-text">            
            <span class="body-m-medium neutral-700--text">{{
              Number( clientData.sales_count > 0 ? clientData.sales_value_sum / (100 *clientData.sales_count) : 0).toLocaleString(undefined, {style: this.program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.program.currency.alpha3})
            }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">Первая продажа</p>
          <div class="right-text">
            <span v-if="clientData.sales_min_date" class="body-m-medium neutral-700--text">{{
              this.$moment
                .utc(clientData.sales_min_date)
                .local()
                .format(this.$config.date.DATETIME_FORMAT_MIN2)
            }}</span>
            <span v-else class="body-m-medium neutral-700--text">-</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">Последняя продажа</p>
          <div class="right-text">
            <span v-if="clientData.sales_max_date" class="body-m-medium neutral-700--text"
              >{{
                this.$moment
                  .utc(clientData.sales_max_date)
                  .local()
                  .format(this.$config.date.DATETIME_FORMAT_MIN2)
              }}
              ({{ lastSale }})</span
            >
            <span v-else class="body-m-medium neutral-700--text">-</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    lastSale() {
      var maxDate = this.$moment(this.clientData.sales_max_date);
      var nowDate = this.$moment();

      var days = nowDate.diff(maxDate, "days");

      if (days == 0) {
        return "сегодня";
      }

      return nowDate.diff(maxDate, "days") + " дней назад";
    },
    program() {
      return this.$store.getters["company/program/program"];
    },
  },
  props: {
    clientData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
@import "@/styles/_typography.sass";
.bonus-accounts {
  margin-top: 16px;
  .bonus-accounts__header {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 0;
  }
}
</style>
