import defaultProps from './components/table/props.js'
import { genDefaultHeader, genDefaultFooter } from './components/table/slots.js'
import { VDataTable } from 'vuetify/lib'
import { getSlot } from 'vuetify/lib/util/helpers'

export default {
  props: defaultProps,
  data () {
    return {
      internalOptions: Object.assign({}, this.options),
    }
  },
  mounted () {
    if (this.storageKey) {
      const storageOptions = this.getDataTableOptions(this.storageKey)
    if (
      Object.keys(storageOptions).length
    ) {
      this.internalOptions = {}
      this.internalOptions = Object.assign(storageOptions, this.internalOptions)
    }
    }
  },
  render (createElement) {
    return createElement(VDataTable, {
      staticClass: 'plus-table',
      props: {
        ...this.$options.propsData,
        options: this.internalOptions,
        hideDefaultHeader: true,
        hideDefaultFooter: true,
      },
      on: {
        'click:row': (v, data) => this.$emit('click:row', v, data),
        'dbclick:row': (ev, data) => this.$emit('dbclick:row', ev, data),
        'contextmenu:row': (ev, data) => this.$emit('contextmenu:row', ev, data),
        'item-expanded': v => this.$emit('item-expanded', v),
        'item-selected': v => this.$emit('item-selected', v),
        'current-items': v => this.$emit('current-items', v),
        'update:options': v => { this.internalOptions = v },
        'toggle-select-all': v => this.$emit('toggle-select-all', v),
        'update:page': v => this.$emit('update:page', v),
        'update:multi-sort': v => this.$emit('update:multi-sort', v),
        'update:must-sort': v => this.$emit('update:must-sort', v),
        'update:items-per-page': v => this.$emit('update:items-per-page', v),
        'update:sort-by': v => this.$emit('update:sort-by', v),
        'update:sort-desc': v => this.$emit('update:sort-desc', v),
        input: v => this.$emit('input', v),
        'update:group-by': v => this.$emit('update:group-by', v),
        'update:group-desc': v => this.$emit('update:group-desc', v),
        'update:expanded': v => this.$emit('update:expanded', v),
        pagination: v => this.$emit('pagination', v),
        'page-count': v => this.$emit('page-count', v),
      },
      scopedSlots: {
        ...this.$slots,
        ...this.$scopedSlots,
        header: (data) => {
          if (
            this.existsPropAndFalse('hideDefaultHeader')
          ) {
            return genDefaultHeader(this, { props: data.props, on: data.on })
          } else {
            return getSlot(this, 'header', data)
          }
        },
        footer: (data) => {
          if (
            this.existsPropAndFalse('hideDefaultFooter')
          ) {
            return genDefaultFooter(this, data)
          } else {
            return getSlot(this, 'footer', data)
          }
        },
      },
    })
  },
  watch: {
    options: {
      deep: true,
      handler (v) {
        this.internalOptions = v
        this.setDataTableOptions(this.storageKey, v)
      },
    },
    internalOptions: {
      deep: true,
      handler (v) {
        this.$emit('update:options', v)
        this.setDataTableOptions(this.storageKey, v)
      },
    },
  },
  methods: {
    updateOptions (v) {
      console.log('updateOptions', v)
      this.internalOptions = Object.assign(this.internalOptions, v)
    },
    existsPropAndFalse (propName) {
      return !Object.keys(this.$options.propsData).includes(propName) ||
      (
        Object.keys(this.$options.propsData).includes(propName) &&
        this.$options.propsData[propName] === false
      )
    },
    getDataTableOptions (key) {
      const tables = JSON.parse(localStorage.getItem('tables'))
      if (!tables || (tables && !tables[key])) {
        return {}
      }
      return tables[key]
    },
    setDataTableOptions (key, value) {
      if (!key || (key && !key.length)) return
      let tables = JSON.parse(localStorage.getItem('tables'))
      if (!tables) tables = {}
      tables[key] = value
      localStorage.setItem('tables', JSON.stringify(tables))
    },
  },
}
