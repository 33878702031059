import store from '@/store'

export function canAccessRoute (route) {
  try {
    const authUser = store.getters.user

      const programId = store.getters.programId
      if (!authUser) return false

    let canAccess = true
    if (route.meta && route.meta.roles) {
      canAccess = canAccess && authUser.hasProgramAnyRole(route.meta.roles, programId)
    }
    if (route.meta && route.meta.permission) {
      if (route.meta.permission instanceof Array) {
        canAccess = canAccess && route.meta.permission.some(permission => authUser.hasProgramPermission(permission, programId))
      } else {
        canAccess = canAccess && authUser.hasProgramPermission(route.meta.permission, programId)
      }
    }
    return canAccess
  } catch (error) {
    return false
  }
}

export function guardHandler (to, from) {
  const canAccess = canAccessRoute(to)
  console.log('guard-access-handler', canAccess, to)
  return !canAccess ? { name: 'access-denied', query: { path: to.path } } : undefined
}

export default function (to, from, next) {
  const redirect = guardHandler(to, from)
  if (next) {
    next(redirect)
  } else {
    return redirect
  }
}
