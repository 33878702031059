import { VIcon } from 'vuetify/lib/components'
import BaseTextField from '@/components/base/TextField'
import BaseDateRangePicker from '@/components/base/DateRangePicker'

export default {
  render (createElement) {
    return createElement('div', {
      staticClass: 'base-date-text-field',
      directives: [
        {
          name: 'click-outside',
          value: this.hide,
        },
      ],
    }, [
      createElement('div', {
        ref: 'textField',
        staticClass: 'field__input',
         on: {
        click: () => {
          this.show()
        },
      },
      }, [
        createElement(BaseTextField, {
          ref: 'textField',
          directives: [
            {
              name: 'mask',
              value: this.maskDateFormat,
            },
          ],
          attrs: {
            maxlength: this.maskDateFormat.length,
          },
          props: {
            ...this.propsTextField,
            value: this.formatedStartDate,
            autocomplete: 'off',
            autofocus: false,
            rules: this.rules,
            placeholder: this.placeholder || this.$attrs.placeholder,
          },
          on: {
            input: v => {
              this.hide()
              this.updateTextField(v)
              this.$forceUpdate()
            },
            keydown: ev => {
              if (ev.keyCode === 27) {
                if (this.$refs?.textField) {
                  this.$refs.textField
                    .querySelector('input')
                    .blur()
                }
                this.hide()
              }
            },
          },
          scopedSlots: {
            append: () => {
              return createElement(VIcon, {
                props: {
                  color: 'neutral-500',
                },
              }, '$iconify_feather-calendar')
            },
          },
        }),
      ]),
    ].concat(this.genDatePicker()))
  },
  methods: {
    genDatePicker () {
      if (!this.showDatePicker) return

      return this.$createElement(BaseDateRangePicker, {
        ref: 'picker',
        props: {
          ...this.propsDateRange,
          dateRange: this.internalDateRange,
        },
        class: {
          show: true,
          [this.pickerPlacement]: true,
        },
        style: [this.pickerStyles],
        on: {
          update: v => {
            this.internalDateRange = v
            this.$emit(
              'updateStartDate',
              this.$moment(v.startDate).toISOString(),
            )
            this.updateShowPicker(false)
          },
          'change-month': (monthDate, any) => this.$emit('change-month', monthDate, any),
          hoverDate: date => this.$emit('hoverDate', date),
          toggle: (open, togglePicker) => this.$emit('toggle', open, togglePicker),
          select: v => this.$emit('select', v),
          clickCancel: ev => this.hide(ev),
          clickApply: v => this.apply(v),
        },
      })
    },
  },
}
