<template>
    <div class="bonus-accounts">
        <div class="bonus-accounts__header">
            <p class="body-l-semibold">
            Владелец
            </p>
        </div>

        <div class="tab-client tab-client__info">
      <ul class="info-list">
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            ID
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ clientData.user.id }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Телефон
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text"><a :href="'tel:+' + clientData.user.phone ">+{{ clientData.user.phone }}</a></span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            ФИО
          </p>
          <div
            v-if="clientData.invite_source"
            class="right-text"
          >
            <v-row>
              <v-col
                cols="auto"
              >
                <v-avatar size="25">
                  <img
                    :src="clientData.user.avatar"
                    @error="$event.target.src = $config.app.fileStorage + 'users/avatars/default.png'"
                  >
                </v-avatar>
              </v-col>
              <v-col cols="auto">
                <span
                  class="body-m-medium neutral-700--text"
                >{{ clientData.user.FIO }}</span>
              </v-col>
            </v-row>
          </div>
          <div
            v-else
            class="right-text"
          >
            <span class="body-m-medium neutral-700--text">-</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Дата регистрация
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ this.$moment.utc(clientData.user.created_at).local().format(this.$config.date.DATETIME_FORMAT_MIN2)  }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Город
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ clientData.user.city ? clientData.user.city : "-" }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Пол
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ getGender(clientData.user.gender) }}</span>
          </div>
        </li>
       <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Email
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ clientData.user.email ? clientData.user.email : '-' }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Дата рождения
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ clientData.user.birthday ? this.$moment.utc(clientData.user.birthday).local().format(this.$config.date.DATE_FORMAT) : '-' }}</span>
          </div>
        </li>
      </ul>
    </div>

    </div>
</template>

<script>
export default {
    methods: {
        getGender (gender) {
        if (gender === 'm') return 'мужской'
        if (gender === 'f') return 'женский'
        return '-'
      },
    },
    props: {
    clientData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
}
</script>

<style lang="scss">
  @import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
  @import "@/styles/_typography.sass";
  .bonus-accounts {
    margin-top: 16px;
    .bonus-accounts__header {
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 0;
    }
  }
</style>
