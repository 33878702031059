import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from 'vuetify/lib/locale/en'
import ru from 'vuetify/lib/locale/ru'
import tr from 'vuetify/lib/locale/tr'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en.json'),
    label: { ...require('@/locales/en/label.json') },
    validation: { ...require('@/locales/en/validation.json') },
    $vuetify: en,
  },
  ru: {
    ...require('@/locales/ru.json'),
    validation: { ...require('@/locales/ru/validation.json') },
    attributes: { ...require('@/locales/ru/attributes.json') },
    label: { ...require('@/locales/ru/label.json') },
    $vuetify: ru,
  },
  tr: {
    ...require('@/locales/tr.json'),
    validation: { ...require('@/locales/tr/validation.json') },
    // attributes: { ...require('@/locales/tr/attributes.json') },
    label: { ...require('@/locales/tr/label.json') },
    $vuetify: ru,
  },
}

console.log('navigator.language', navigator.language)

let userLanguage = navigator.language.split('-')[0]

if (process.env.NODE_ENV === 'development') {
  userLanguage = 'en'
}

// VueI18n.prototype.localeMapped = function () {
//   return {
//     en: 'en-US',
//   }[this.locale] || this.locale
// }

Object.defineProperty(VueI18n.prototype, 'localeMapped', {
  get: function () {
    return {
      en: 'en-US',
    }[this.locale] || this.locale
  },
})

const i18n = new VueI18n({
  locale: userLanguage || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  // formatFallbackMessages: true,
  // silentFallbackWarn: true,
  messages,
})

export default i18n
