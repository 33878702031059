
<template>
  <base-text-field
    ref="valueField"
    v-model="valueMasked"
    v-money="value!=null?money: null"
    v-bind="$attrs"
    :rules="mergedRules"
    :validation-placement="'top'"
    @keydown="onKeydown"
  />
</template>

<script>
  import { isNumeric, isPosNumber } from '@/utils/validate'

  const decimalChars = ['.', ',']

  export default {
    name: 'NumberField',
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: {
        type: Number,
        default: undefined,
      },
      decimal: {
        type: Boolean,
        default: false,
      },
      isPositive: {
        type: Boolean,
        default: false,
      },
      moneyPrefix: {
        type: String,
        default: '',
      },
      moneySuffix: {
        type: String,
        default: '',
      },
      rules: {
        type: Array,
        default: undefined,
      },

    },
    data () {
      return {
        decimalMode: false,
        valueMasked: this.value,
        valueRules: [
          (v) => (this.valueNumber == null || isNumeric(this.valueNumber)) || 'Должно быть числом',
          (v) => (!this.valueNumber || !this.isPositive || isPosNumber(this.valueNumber)) || 'Должно быть положительным',
        ],
      }
    },

    computed: {
      money () {
        return {
          decimal: ',',
          thousands: ' ',
          prefix: this.moneyPrefix,
          suffix: this.moneySuffix,
          precision: this.decimal && this.decimalMode ? 2 : 0,
        }
      },
      valueUnmasked () {
        return this.valueMasked?.toString().replace(this.moneyPrefix, '').replace(this.moneySuffix, '')
        .replaceAll(this.money.thousands, '').replace(this.money.decimal, '.')
      },
      valueNumber () {
        return this.valueUnmasked ? Number(this.valueUnmasked) : null
      },
      mergedRules () {
        return this.valueRules.concat(this.rules || [])
      },

    },

    watch: {
      valueNumber (v) {
        this.$emit('update', v)
      },
      valueUnmasked (v) {
        console.log('valueUnmasked', v)
      },
    },
    mounted () {

    },
    methods: {
      focus () {
        this.$refs.valueField.focus()
      },
      onKeydown (e) {
        if (decimalChars.includes(e.key)) {
          this.decimalMode = !this.decimalMode
        }
        console.log(this.money)
      },
    },
  }
</script>
