export default {
  props: {
    top: Boolean,
    bottom: Boolean,
    right: Boolean,
    left: Boolean,
  },
  data () {
    return {
      positioned: {},
      applyCalculation: {},
    }
  },
  methods: {
    getFromHideNodeAttribute (node, attrName) {
      const clone = node.cloneNode(true)
      clone.setAttribute('style', 'display:block !important')
      clone.style.visibility = 'hidden'
      node.parentNode.append(clone)

      const attr = clone[attrName]

      clone.remove()

      return attr
    },
    parentDispType (parentDisplayable) {
      if (parentDisplayable?.$refs?.['side-panel__nav']) return 'side-panel'
      if (
        parentDisplayable.constructor &&
        parentDisplayable.constructor === document.body.constructor
      ) {
        return 'body'
      }
    },
    getParentDisplayable () {
      let vNode = this

      while (true) {
        if (Object.keys(vNode.$refs).includes('side-panel')) break
        if (typeof vNode.$parent === 'undefined') {
          vNode = null
          break
        }
        vNode = vNode.$parent
      }

      return !vNode ? document.body : vNode
    },
    setOutstands (alias, outstands) {
      outstands.forEach(o => {
        if (o[1] < 0) o[1] = Math.abs(o[1])
        else o[1] = 0
        // console.log(o[0], o[1])
        this.positioned[alias][o[0]] = o[1]
      })
    },
    setTopPositionElement (node1, node2, alias) {
      if (this.applyCalculation[alias]) {
        node1.style.top = 'inherit'
        node1.style.bottom = node2.clientHeight + 'px'
      }
      this.positioned[alias].placement = 'top'
    },
    setBottomPositionElement (node1, node2, alias) {
      if (this.applyCalculation[alias]) {
        node1.style.bottom = 'inherit'
        node1.style.top = node2.clientHeight + 'px'
      }
      this.positioned[alias].placement = 'bottom'
    },
    setLeftPositionElement (node1, node2, alias) {
      if (this.applyCalculation[alias]) {
        node1.style.left = 'inherit'
        node1.style.right = node2.clientHeight + 'px'
      }
      this.positioned[alias].placement = 'left'
    },
    setRightPositionElement (node1, node2, alias) {
      if (this.applyCalculation[alias]) {
        node1.style.right = 'inherit'
        node1.style.left = node2.clientHeight + 'px'
      }
      this.positioned[alias].placement = 'right'
    },
    setPositioned (positioned, relative, alias) {
      let parentDisplayable = this.getParentDisplayable()
      if (!this.positioned[alias]) this.positioned[alias] = {}

      if (this.parentDispType(parentDisplayable) === 'side-panel') {
        parentDisplayable = parentDisplayable.$refs['side-panel__nav'].$el.querySelector('.v-navigation-drawer__content')
      }
      // console.group('[log] mixin positionable')

      const scrollTop = this.parentDispType(parentDisplayable) === 'body'
        ? window.scrollY
        : parentDisplayable.scrollTop
      const scrollLeft = this.parentDispType(parentDisplayable) === 'body'
        ? window.scrollX
        : parentDisplayable.scrollLeft

      this.positioned[alias].scrollTop = scrollTop
      this.positioned[alias].scrollLeft = scrollLeft

      this.positioned[alias].relativeEl = relative
      this.positioned[alias].positionedEl = positioned

      const relativeTop = relative.getBoundingClientRect().top + this.positioned[alias].scrollTop
      const relativeLeft = Math.abs(parentDisplayable.getBoundingClientRect().left - relative.getBoundingClientRect().left) + this.positioned[alias].scrollLeft

      this.positioned[alias].relativeTop = relativeTop
      this.positioned[alias].relativeLeft = relativeLeft

      const positionedHeight = this.getFromHideNodeAttribute(positioned, 'offsetHeight')
      const positionedWidth = this.getFromHideNodeAttribute(positioned, 'offsetWidth')

      this.positioned[alias].positionedHeight = positionedHeight
      this.positioned[alias].positionedWidth = positionedWidth

      const relativeHeight = relative.clientHeight
      const relativedWidth = relative.clientWidth

      this.positioned[alias].relativeHeight = relativeHeight
      this.positioned[alias].relativedWidth = relativedWidth

      const outstandTop = relativeTop - positionedHeight
      const outstandScrollTop = outstandTop - scrollTop
      const outstandBottom = parentDisplayable.scrollHeight - ((relativeTop + relative.clientHeight) + positionedHeight)
      const outstandScrollBottom = outstandBottom - scrollTop
      const outstandLeft = relativeLeft - positionedWidth
      const outstandScrollLeft = outstandLeft - scrollLeft
      const outstandRight = parentDisplayable.scrollWidth - ((relativeLeft + relative.clientWidth) + positionedWidth)
      const outstandScrollRight = parentDisplayable.scrollWidth - ((relativeLeft + relative.clientWidth) + positionedWidth)

      this.setOutstands(
        alias,
        [
          ['outstandTop', outstandTop],
          ['outstandScrollTop', outstandScrollTop],
          ['outstandBottom', outstandBottom],
          ['outstandScrollBottom', outstandScrollBottom],
          ['outstandLeft', outstandLeft],
          ['outstandScrollLeft', outstandScrollLeft],
          ['outstandRight', outstandRight],
          ['outstandScrollRight', outstandScrollRight],
        ],
      )

      // console.log('scrollTop', scrollTop)
      // console.log('scrollLeft', scrollLeft)
      // console.log('relativeEl', relative)
      // console.log('positionedEl', positioned)
      // console.log('relativeTop', relativeTop)
      // console.log('relativeLeft', relativeLeft)
      // console.log('positionedHeight', positionedHeight)
      // console.log('positionedWidth', positionedWidth)
      // console.log('relativeHeight', relativeHeight)
      // console.log('relativedWidth', relativedWidth)

      // console.groupEnd()
    },
    verticalPositioned (alias, only) {
      const args = this.positioned[alias]

      if ((!only.length && args.relativeTop >= args.positionedWidth) || only === 'top') {
        this.setTopPositionElement(args.positionedEl, args.relativeEl, alias)
      } else {
        this.setBottomPositionElement(args.positionedEl, args.relativeEl, alias)
      }
    },
    horizontalPositioned (alias, only) {
      const args = this.positioned[alias]

      if ((!only.length && args.relativeLeft >= args.positionedWidth) || only === 'left') {
        this.setLeftPositionElement(args.positionedEl, args.relativeEl, alias)
      } else {
        this.setRightPositionElement(args.positionedEl, args.relativeEl, alias)
      }
    },
    positioningRelativelyElementVertical (positioned, relative, alias = 'baseVertical', only = '') {
      this.setPositioned(positioned, relative, alias)
      this.verticalPositioned(alias, only)
    },
    positioningRelativelyElementHorizontal (positioned, relative, alias = 'baseHorizontal', only = '') {
      this.setPositioned(positioned, relative, alias)
      this.horizontalPositioned(alias, only)
    },
    propPositioning (positioned, relative, alias) {
      let isDetermined = false
      for (const prop in this.$options.propsData) {
        if (['top', 'bottom'].includes(prop)) {
          isDetermined = true
          this.positioningRelativelyElementVertical(positioned, relative, alias, prop)
        } else if (['left', 'right'].includes(prop)) {
          isDetermined = true
          this.positioningRelativelyElementHorizontal(positioned, relative, alias, prop)
        }
      }
      if (!isDetermined) {
        this.positioningRelativelyElementVertical(positioned, relative, alias)
      }
    },
  },
}
