import ApiService from '@/api/api-client'

export default {
    namespaced: true,
    state: {
        name: 'widget_share_purchase',
        segment_id : [],
        loading: false,
        widgetData: [],
        emptyData :{
            quantity_sales_of_program: 0,
            sales_by_segments: [
                {count_sales:0},
                {count_sales:0},
                {count_sales:0},
            ],
            total_quantity_sales: 0
        }
    },
    mutations: {
        clearState (state) {
            state.loading = false
            state.widgetData = state.emptyData
        },
        loading (state, payload) {
            state.loading = payload
        },
        widgetData (state, payload) {
            state.widgetData = payload
        },
        segment(state, payload){
            console.log('before save widget segment' + state.name + '_' + payload.program_id, payload.segment_id, state.segment_id);
            state.segment_id = payload.segment_id;
            console.log('save widget segment ' + state.name + '_' + payload.program_id, state.segment_id)

            const saveArray = JSON.stringify(state.segment_id)
            console.log("JSON STRINGIFY SAVE OBJECT",saveArray)
            localStorage.setItem(state.name +'_' + payload.program_id , saveArray)
        }
    },
    actions: {
        async widget ({ commit }, widget) {
            commit('loading', true)

            const result = await ApiService.post('/api-cabinet/widget/dashboard/purchases/share', widget)
            commit('widgetData', result)
            commit('loading', false)
        },
        setDefault({commit}){
            commit('clearState')
        },
        saveSegment({commit}, payload){
            commit('segment', payload)
        },
        clearSegment({commit}, program_id){
            commit('segment', {segment_id : [], program_id})
        }
    },
    getters: {
        loading (state) {
            return state.loading
        },
        widgetData (state) {
            console.log('sdfsdfsdfdsf')
            console.log(state.widgetData)
            console.log('sdfsdgsdfsdf')

            return state.widgetData
        },
        segment: state => program_id => {
            console.log('get widget segment ' + state.name + '_' + program_id, localStorage.getItem(state.name + '_'+ program_id))
            if (localStorage.getItem(state.name + '_'+ program_id) !== null && localStorage.getItem(state.name + '_'+ program_id) !== undefined){
                const elem = JSON.parse(localStorage.getItem(state.name + '_'+ program_id))
                return elem ? elem : []
            }
            return []
        }
    },
}
