import moment from 'moment'

export default {
    name: 'timerMixin',
    data () {
        return {
            timerMoment: moment(),
            timerTask: null,
        }
    },
    computed: {},
    methods: {
        runTimer () {
            this.timerTask = setInterval(() => {
                this.timerNextTick()
            }, 1000)
        },
        timerNextTick () {
            this.timerMoment = moment()
        },
        stopTimer () {
            if (this.timerTask) clearInterval(this.timerTask)
        },
    },
    mounted () {
        this.runTimer()
    },
    destroyed () {
        this.stopTimer()
    },
}
