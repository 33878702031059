<template>
  <v-row
    align="center"
    justify="center"
    style="height: 100%;"
    no-gutters
  >
    <v-col cols="auto">
      <v-row justify="center">
        <v-col cols="auto">
          <slot name="image">
            <v-img
              src="@/assets/png/settings-dummy.png"
              width="193.96px"
              height="174px"
            />
          </slot>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          class=""
          style="width:502px"
        >
          <v-row
            justify="center"
            class="title-row"
            no-gutters
          >
            <v-col
              class="title-s-bold mb-0"
              cols="auto"
            >
              {{ title }}
            </v-col>
          </v-row>
          <v-row
            justify="center"
            class="description-row"
            no-gutters
          >
            <v-col
              class="body-m-regular mb-0"
              style="text-align:center;"
              cols="auto"
            >
              {{ description }}
              <slot name="description" />
            </v-col>
          </v-row>
          <v-row
            v-if="$slots.actions"
            justify="center"
            class="action-row"
          >
            <v-col class="pa-0">
              <slot
                name="actions"
              />
            </v-col>
          </v-row>
          <v-row
            v-else-if="action"
            justify="center"
            class="action-row"
            no-gutters
          >
            <v-col cols="auto">
              <v-btn
                color="primary"
                @click="btnClick"
              >
                <v-icon
                  v-if="actionIcon"
                  left
                >
                  {{ actionIcon }}
                </v-icon>
                {{ actionText }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

  export default {
    name: 'EmptyBlockPage',
    components: { },
    props: {
      title: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      action: Boolean,
      actionIcon: {
        type: String,
        default: '',
      },
      actionText: {
        type: String,
        default: '',
      },

    },

    computed: {},
    methods: {
      btnClick () {
        this.$emit('action')
      },
    },
  }
</script>
<style lang="scss" scoped>
.action-row {
  margin-top: 34px;
}
.title-row {
  margin-top: 34px;
  margin-bottom: 6px;
}
.description-row{
  margin-top: 6px;
}
</style>
