import ApiService from '@/api/api-client'

const state = {
    timezones:[]
}

const getters = {
    allTimezones: (state) => state.timezones,
}

const mutations = {
    SET_TIMEZONES(state, payload){
        state.timezones = payload;
    }
}

const actions = {
    async timezonesList({commit}) {
        try {
            const result = await ApiService.get(
                `/api-cabinet/timezone/list`,
            )
            console.log(`/api-cabinet/timezone/list`)
            console.log(result)
            commit('SET_TIMEZONES', result)
        } catch (error) {
            throw error
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}