import ApiService from '@/api/api-client'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    offers: [],
    offerInfo: {},
  },
  mutations: {
    offers (state, payload) {
      state.offers = payload
    },
    updateOffer (state, { offerId, payload }) {
      const idx = state.offers.findIndex(offer => offer.id === offerId)
      if (idx !== -1) {
        Vue.set(state.offers, idx, payload)
      }
    },
    deleteOfferById (state, offerId) {
      state.offers = state.offers.filter(offer => offer.id !== offerId)
      Vue.set(state.offers, '', [...state.offers])
    },
    addInOffers (state, payload) {
      const offers = [[state.offers], ...[payload]]
      state.offers = offers
    },
    offerInfo (state, payload) {
      state.offerInfo = payload
    },
  },
  actions: {
    async list ({ commit, rootGetters }) {
      const result = await ApiService.get(`/api-cabinet/program/bonus/offer/list?program_id=${rootGetters.programId}`)

      commit('offers', result)
    },
    async create ({ commit, rootGetters }, payload) {
      if (!payload.program_id) payload.program_id = rootGetters.programId
      const result = await ApiService.post('/api-cabinet/program/bonus/offer', payload)

      commit('addInOffers', result)
    },
    async update ({ commit, rootGetters }, { offerId, payload }) {
      payload.offer_id = offerId

      console.log('update offer', payload)

      const result = await ApiService.put('/api-cabinet/program/bonus/offer', payload)

      commit('updateOffer', { offerId: result.id, payload: result })
    },
    async changeActive ({ state, commit }, { offerId, active }) {
      const currOfferIdx = state.offers.findIndex(offer => offer.id === offerId)
      if (currOfferIdx !== -1) {
        const offer = state.offers[currOfferIdx]

        offer.loadingChangeActive = true

        commit('updateOffer', { offerId: offer.id, payload: offer })

        const result = await ApiService.put('/api-cabinet/program/bonus/offer/active', {
          offer_id: offerId,
          active: active,
        })

        offer.loadingChangeActive = false
        offer.active = result.active

        commit('updateOffer', { offerId: result.id, payload: offer })
      }
    },
    async getInfo ({ state, commit }, offerId) {
      try {
        const result = await ApiService.get(`/api-cabinet/program/bonus/offer?offer_id=${offerId}`)
        commit('offerInfo', result)
      } catch (err) {
        console.log('offers/offers/getInfo', err)
      }
    },
    async CheckClientFilter ({ commit }, filter) {
      const result = await ApiService.post('/api-cabinet/program/bonus/offer/filter/validate', filter)
      return result
    },
    async delete ({ state, commit }, offerId) {
      const isExists = state.offers.some(offer => offer.id === offerId)
      if (isExists) {
        try {
          const result = await ApiService.delete(`/api-cabinet/program/bonus/offer?offer_id=${offerId}`)
          console.log('res delete offer', result)
          commit('deleteOfferById', offerId)
        } catch (err) {
          console.log('offers/offers/delete', err)
        }
      }
    },
  },
  getters: {
    offers (state) {
      return state.offers
    },
    offerInfo (state) {
      return state.offerInfo
    },
  },
}
