var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-editor',_vm._b({attrs:{"api-key":_vm.$config.apiKeys.tinyMCE,"output-format":"html","init":{
      height: 264,
      language: 'ru',
      menubar: false,
      statusbar: false,
      content_style: _vm.contentStyle,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar: 'formatselect | bold italic underline | numlist bullist'
    }},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},'vue-editor',_vm.$attrs,false)),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],attrs:{"type":"error","dense":"","text":""}},[_vm._v(" "+_vm._s(_vm.validations[0])+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }