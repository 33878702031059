<template>
  <div class="field-block">
    <p class="field-block__title body-l-semibold neutral-900--text mb-0">
      {{ title }}
    </p>
    <p class="field-block__description body-m-regular neutral-700--text mb-0">
      {{ description }}
    </p>
    <slot name="input" />
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style lang="scss" scoped>
.field-block {
  margin-bottom: 34px;
  .field-block__description {
    margin-top: 6px;
    padding-bottom: 20px;
  }
}
</style>
