import ApiService from '@/api/api-client'

const getDefaultState = () => {
    return {
        loading: false,
        accountScores: [], // счета клиента
        accountExpiresScores: [], // счета клиента
        accountBalances: [], // баланс счетов клиента
        transactions: [], // транзакции счетов
        client: {}, // пользователь карты
        goals: [], // события карты
        countGoals: 0
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => Object.assign(state, getDefaultState()),
    SET_LOADING: (state, payload) => { state.loading = payload },
    SET_ACCOUNT_SCORES: (state, payload) => { state.accountScores = payload },
    SET_ACCOUNT_EXPIRES_SCORES: (state, payload) => { state.accountExpiresScores = payload },
    SET_ACCOUNT_BALANCES: (state, payload) => { state.accountBalances = payload },
    SET_TRANSACTIONS: (state, payload) => { state.transactions = payload },
    SET_CLIENT: (state, payload) => { state.client = payload },
    SET_GOALS: (state, payload) => { state.goals = payload },
    SET_COUNT_GOALS: (state, payload) => { state.countGoals = payload}
}

const actions = {

    resetState ({ commit }) {
        commit('RESET_STATE')
    },

    //
    async getAccountScores ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {
            const result = await ApiService.get(`/api/account/bonus/score/list?account_id=${item.id}`)
            console.log(`/api/account/bonus/score/list?account_id=${item.id}`)
            console.log(result)
            commit('SET_ACCOUNT_SCORES', result)
        } catch (error) {
            throw error
        }
    },

    async getAccountBalances ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {
            const result = await ApiService.get(`/api-cabinet/crm/account/balance?id=${item.id}`)
            console.log(`/api-cabinet/crm/account/balance?id=${item.id}`)
            console.log(result)
            commit('SET_ACCOUNT_BALANCES', result)
        } catch (error) {
            throw error
        }
    },

    async transactionsList ({ commit }, accountId) {
        const result = await ApiService.get(`/api/account/bonus/transactions/view/list?account_id=${accountId}`)
        // const result = await ApiService.get(`/api/account/bonus/transactions/list?account_id=${item.id}`)

        console.log('/api/account/bonus/transactions/view/list')
        console.log(result)

        commit('SET_TRANSACTIONS', result)
    },
    async getAccountExpiresScores ({ commit }, accountId) {
        const result = await ApiService.get(`/api-cabinet/crm/account/bonus/expires?id=${accountId}`)
        commit('SET_ACCOUNT_EXPIRES_SCORES', result)
        return result
    },

    // account/bonus/transactions/add
    async transactionsAdd ({ commit }, item) {
        /*
        'account_id' => 'required|integer|exists:accounts,id',
            'direction' => "required|in:FROM,TO",
            'value' => 'required|numeric|gt:0|max:1000000',
            'comment' => 'nullable|string' 
        */
            const result = await ApiService.post('/api/account/bonus/transactions/add', item)
            console.log('/api/account/bonus/transactions/add')
            console.log(result)

            this._vm.$notify({
                type: 'success',
                title: 'Клиенты',
                text: 'Операция успешно проведена',
            })
    },

    async getAccountClient ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {
            console.log(`/api-cabinet/crm/account/user?id=${item.id}`)
            const result = await ApiService.get(`/api-cabinet/crm/account/user?id=${item.id}`)
            console.log(result)
            commit('SET_CLIENT', result)
        } catch (error) {
            throw error
        }
    },

    // GOALS
    async getGoals ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {
            const url = `/api-cabinet/crm/account/goal/list?account_id=${item.account_id}&page=${item.page}&limit=${item.limit}`
            if (item.offset_id) url + `&offset_id=${item.offset_id}`
            const result = await ApiService.get(url)
            commit('SET_GOALS', result.items)
            commit('SET_COUNT_GOALS', result.total)
        } catch (error) {
            throw error
        }
    },

}

const getters = {
    loading: state => state.loading,
    accountScores: state => state.accountScores,
    accountExpiresScores: state => state.accountExpiresScores,
    accountBalances: state => state.accountBalances,
    transactions: state => state.transactions,
    client: state => state.client,
    goals: state => state.goals,
    countGoals: state => state.countGoals
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
