import ApiService from '@/api/api-client'

export default {
  namespaced: true,
  state: {
    fields: {},
    loadedForProgram: {},
  },
  mutations: {
    SET_LOADED (state, { programId, bool }) {
      state.loadedForProgram[programId] = bool
    },
    UPDATE_FIELDS (state, programId) {
      state.fields[programId] = state.fields[programId].map(f => Object.assign({}, f))
      state.fields = Object.assign({}, state.fields)
    },
    SET_FIELDS (state, { programId, payload }) {
      state.fields[programId] = payload
      state.fields = Object.assign({}, state.fields)
    },
    ADD_IN_FIELDS (state, { programId, payload }) {
      if (!state.fields[programId]
        .find(f => f.id === payload.id)) {
        state.fields[programId].push(payload)
        state.fields = Object.assign({}, state.fields)
      }
    },
    UPDATE_IN_FIELDS (state, { programId, id, payload }) {
      state.fields[programId] = state.fields[programId].filter(f => f.id !== id)
      state.fields[programId].push(payload)
      state.fields = Object.assign({}, state.fields)
    },
    REMOVE_IN_FIELDS_BY_ID (state, { programId, id }) {
      state.fields[programId] = state.fields[programId]
        .filter(f => f.id !== id)
      state.fields = Object.assign({}, state.fields)
    },
  },
  actions: {
    async GetFields ({ state, commit }, { id, withStub = true }) {
      if (!state.loadedForProgram[id] || !withStub) {
        const result = await ApiService.get('/api-cabinet/program/account/field-setting/list?program_id=' + id)
        commit('SET_LOADED', { programId: id, bool: true })
        commit('SET_FIELDS', { programId: id, payload: result || [] })
      }
    },
    async CreateField ({ commit }, payload) {
      const result = await ApiService.post('/api-cabinet/program/account/field-setting', payload)
      commit('ADD_IN_FIELDS', { programId: result.program_id, payload: Object.assign(payload, result) })
      commit('SET_LOADED', { programId: result.program_id, bool: false })
    },
    async UpdateField ({ commit }, { id, payload }) {
      const result = await ApiService.put('/api-cabinet/program/account/field-setting', payload)
      commit('UPDATE_IN_FIELDS', { programId: result.program_id, id, payload: result })
      commit('SET_LOADED', { programId: result.program_id, bool: false })
    },
    async UpdateReOrder ({ commit }, payload) {
      await ApiService.post('/api-cabinet/program/account/field-setting/reorder', payload)
      commit('UPDATE_FIELDS', payload.program_id)
      commit('SET_LOADED', { programId: payload.program_id, bool: false })
    },
    async DeleteField ({ commit }, payload) {
      await ApiService.delete('/api-cabinet/program/account/field-setting', { params: { id: payload.id } })
      commit('REMOVE_IN_FIELDS_BY_ID', { programId: payload.program_id, id: payload.id })
      commit('SET_LOADED', { programId: payload.program_id, bool: false })
    },
  },
  getters: {
    fields (state) {
      return state.fields
    },
  },
}
