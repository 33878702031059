import ApiService from '@/api/api-client'

export default {
    namespaced: true,
    state: {
        name: 'widget_purchase',
        segment_id : 0,
        loading: false,
        widgetData: [],
        emptyData : {
            averageCheck:0,
            averageCheckPrev:0,
            byProgramCount:0,
            byProgramSum:0,
            byProgramSumPrev:0,
            chart: [
                [
                    {count : 0, date_end: "0", date_start:"0"},
                    {count : 0, date_end: "0", date_start:"0"},
                    {count : 0, date_end: "0", date_start:"0"},
                    {count : 0, date_end: "0", date_start:"0"},
                ],
                [
                    {count : 0, date_end: "0", date_start:"0"},
                    {count : 0, date_end: "0", date_start:"0"},
                    {count : 0, date_end: "0", date_start:"0"},
                    {count : 0, date_end: "0", date_start:"0"},
                ],
                [
                    {count : 0, date_end: "0", date_start:"0"},
                    {count : 0, date_end: "0", date_start:"0"},
                    {count : 0, date_end: "0", date_start:"0"},
                    {count : 0, date_end: "0", date_start:"0"},
                ]
            ],
            totalCount:0,
            totalSum:0,
            totalSumPrev:0,

        }
    },
    mutations: {
        clearState (state) {
            state.loading = false
            state.widgetData = state.emptyData
        },
        loading (state, payload) {
            state.loading = payload
        },
        widgetData (state, payload) {
            state.widgetData = payload
        },
        segment(state, payload){
            state.segment_id = payload.segment_id;
            console.log('save widget segment ' + state.name + '_' + payload.program_id, state.segment_id)
            localStorage.setItem(state.name +'_' + payload.program_id , state.segment_id)
        }
    },
    actions: {
        async widget ({ commit }, widget) {
            commit('loading', true)

            const result = await ApiService.post('/api-cabinet/widget/dashboard/purchases', widget)
            commit('widgetData', result)
            commit('loading', false)
        },
        setDefault({commit}){
            commit('clearState')
        },
        saveSegment({commit}, payload){
            commit('segment', payload)
        },
        clearSegment({commit}, program_id){
            commit('segment', {segment_id : 0, program_id})
        }
    },
    getters: {
        loading (state) {
            return state.loading
        },
        widgetData (state) {
            const newData = []
            const totalData = []
            const avgData = []
            if (state.widgetData.chart && state.widgetData.chart.by_program) {
                state.widgetData.chart.by_program.forEach(item => {
                    newData.push({
                        count: item.sum / 100,
                        date_start: item.date_start,
                        date_end: item.date_end,
                    })
                })
            }
            if (state.widgetData.chart && state.widgetData.chart.total) {
                state.widgetData.chart.total.forEach(item => {
                    totalData.push({
                        count: item.sum / 100,
                        date_start: item.date_start,
                        date_end: item.date_end,
                    })
                })
            }
            if (state.widgetData.chart && state.widgetData.chart.average_check_chart) {
                state.widgetData.chart.average_check_chart.forEach(item => {
                    avgData.push({
                        count: item.average_check / 100,
                        date_start: item.date_start,
                        date_end: item.date_end,
                    })
                })
            }

            const result = {
                chart: [newData.reverse(), totalData.reverse(), avgData.reverse()],
                totalCount: state.widgetData.total_count || 0,
                byProgramCount: state.widgetData.by_program_count || 0,
                totalSum: state.widgetData.total_sum_current / 100 || 0,
                byProgramSum: state.widgetData.by_program_sum_current / 100 || 0,
                totalSumPrev: state.widgetData.total_sum_prev / 100 || 0,
                byProgramSumPrev: state.widgetData.by_program_sum_prev / 100 || 0,
                averageCheck : state.widgetData.average_check / 100 || 0,
                averageCheckPrev : state.widgetData.average_check_prev / 100 || 0
            }
            console.log('widgetData', result)
            return result
        },
        segment: state => program_id => {
            console.log('get widget segment ' + state.name + '_' + program_id, localStorage.getItem(state.name + '_'+ program_id))
            if (localStorage.getItem(state.name + '_'+ program_id) !== null && localStorage.getItem(state.name + '_'+ program_id) !== undefined)
                return JSON.parse(localStorage.getItem(state.name + '_'+ program_id))
            return state.segment_id
        }
    },
}
