import store from '@/store'

export default function (to, from, next) {
  try {
    const authUser = store.getters.user
    if (!authUser) throw new Error('User not auth')
    const programs = store.getters['company/program/programs']
    const programId = store.getters.programId

    if (programs.length > 0 && !programId) throw new Error('programId in null')

    if (authUser.hasProgramPermission('merchant-stat', programId)) {
      return next()
    } else if (['program-account-list'].some(permission => authUser.hasProgramPermission(permission, programId))) {
      return next({ name: 'ProgramAccounts' })
    } else if (['program-sales-create'].some(permission => authUser.hasProgramPermission(permission, programId))) {
      return next({ name: 'SaleForm' })
    } else {
      throw new Error('access-denied')
    }
  } catch (error) {
    return next({ name: 'access-denied' })
  }
}
