<template>
  <v-text-field
    :id="id"
    ref="vTextField"
    v-model="internalValue"
    v-bind="{ ...$props, ...customVTextFieldProps }"
    @update:error="updateError"
    @blur="onBlur"
    @focus="onFocus"
    @input="onInput"
    @click="onClick"
    @keydown="onKeydown"
    @contextmenu="onContextmenu"
  >
    <template v-slot:prepend-inner>
      <v-icon
        v-if="prependInnerIcon"
        :color="prependInnerIconColor"
      >
        {{ prependInnerIcon }}
      </v-icon>
      <slot
        name="prepend-inner"
      />
    </template>
    <template v-slot:append>
      <v-row
        no-gutters
        class="slot-append__row"
        align="center"
      >
        <v-col
          class="slot-append__col"
        >
          <slot
            v-if="$slots.append"
            name="append"
          />
          <v-icon
            v-else-if="appendIcon"
          >
            {{ appendIcon }}
          </v-icon>
        </v-col>

        <v-col
          v-if="!!counter"
          cols="auto"
          class="slot-append__col body-xs-semibold"
        >
          <span
            v-if="!!counter"
            :class="{
              'error--text': isRequired || isGreater,
              'success--text': !(isRequired || isGreater),
              'base-counter': true,
            }"
          >
            {{ computedMaxCounter ? `${computedCounterValue} / ${computedMaxCounter}` : String(computedCounterValue) }}
          </span>
        </v-col>

        <v-col
          v-if="errorStyle === 'custom'"
          cols="auto"
          class="slot-append__col"
        >
          <div
            :style="hasDispayErrors && !disabled ? 'display: block; opacity: 1' : 'display: none; opacity: 1'"
            class="base-text-field__tooltip error--text"
          >
            <v-icon
              class="icon-warning icon-warning-custom"
              color="error"
            >
              $iconify_ion-warning
            </v-icon>
            <div
              ref="customTooltip"
              class="tooltip-content"
              :class="{show: showError}"
              :placement="validationPlacement"
            >
              <div
                v-for="(errorMsg, index) in errorMessagesToDisplay"
                :key="index"
              >
                {{ errorMsg }}
              </div>
            </div>
          </div>
        </v-col>

        <v-col
          v-else-if="errorStyle === 'tooltip' && hasDispayErrors && !disabled"
          cols="auto"
          class="slot-append__col"
        >
          <base-tooltip
            v-model="showBaseTooltip"
            :disabled="disabled || (isParentNav && elTopFromNav.textfield < 0)"
            :open-on-hover="false"
            text=""
            color="error"
            :top="validationPlacement === 'top'"
            :right="validationPlacement === 'right'"
            :left="validationPlacement === 'left'"
            :bottom="validationPlacement === 'bottom'"
            :position-y="showBaseTooltip && isParentNav ? elTopFromNav.textfield - navScrollTop : 0"
          >
            <v-icon
              v-show="hasDispayErrors"
              color="error"
              class="error-style-tooltip"
            >
              $iconify_ion-warning
            </v-icon>
            <template v-slot:content>
              <div
                v-for="(errorMessage, index) in errorMessagesToDisplay"
                :key="index"
              >
                {{ errorMessage }}
              </div>
            </template>
          </base-tooltip>
        </v-col>

        <v-col
          v-else
          cols="auto"
          class="slot-append__col"
        >
          <v-icon
            v-show="hasDispayErrors"
            class="error-style-base"
            color="error"
            @click="showError = !showError"
          >
            $iconify_ion-warning
          </v-icon>
        </v-col>
      </v-row>
    </template>
    <!-- Слот отображения ошибки -->
    <!-- <template v-slot:message="{key, message}">
      <div>!!!{{ message }}</div>
    </template> -->
  </v-text-field>
</template>

<script>
// https://vuetifyjs.com/en/components/text-fields/#text-fields
/**
 * todo  counter + clearable
 */

  import { VTextField } from 'vuetify/lib'
  import Validatable from 'vuetify/lib/mixins/validatable'
  import { defaultProps } from './components/text-field/props.js'
  import VNavDrawerHelper from '@/mixins/v-nav-drawer-helper.js'

  export default {
    name: 'Textfield',
    mixins: [
      VTextField,
      // VInput,
      Validatable,
      VNavDrawerHelper,
    ],
    model: {
      prop: 'value',
      event: 'input',
    },
    props: defaultProps,
    data () {
      return {
        vCounter: false,
        showError: false,
        showBaseTooltip: false,
        // computedCounterValue: 0,
        computedCounterValue2: 0,
        tooltipPos: {
          left: 0,
          top: 0,
          bottom: 0,
        },
      }
    },
    computed: {
      hasDispayErrors () {
        return this.errorMessagesToDisplay && this.errorMessagesToDisplay.length > 0
      },
      // computedCounterValue () {
      //   return this.$refs.vTextField ? this.$refs.vTextField.computedCounterValue : 0
      // },
      computedMaxCounter () {
        return this.counter === true ? this.maxlength : this.counter
      },
      customVTextFieldProps () {
        return {
          counter: false,
          errorCount: this.errorCount, // mixin validateable
          errorMessages: this.errorMessages, // mixin validateable
          hideDetails: this.hideDetails || ['tooltip', 'custom'].includes(this.errorStyle),
          reverse: false,
          rounded: false,
          solo: false,
          outlined: true,
          soloInverted: false,
          class: this.compClasses,
          maxlength: this.maxlength,
          rules: this.rules,
        }
      },
      errorMessagesToDisplay () {
        // console.log('this.onFocus', this.onFocus)
        // console.log('this.form', this.form)
        // console.log('this.isDisabled', this.isDisabled)
        // console.log('this.validateOnBlur', this.validateOnBlur)
        // console.log('this.hasFocused && !this.isFocused ', this.hasFocused, !this.isFocused)
        // console.log('this.shouldValidate', this.shouldValidate)
        // console.log('this.errorBucket', this.errorBucket)

        // console.log('this.validationTarget', this.validationTarget)
        // console.log('this.validations', this.validations)

        return this.validations.map(validation => {
          if (typeof validation === 'string') return validation
          const validationResult = validation(this.internalValue)
          return typeof validationResult === 'string' ? validationResult : ''
        }).filter(message => message !== '')
      },
      isRequired () {
        return !!this.minlength && this.computedCounterValue < this.minlength
      },
      isGreater () {
        return !!this.computedMaxCounter && this.computedCounterValue > this.maxlength
      },
      isFilledText () {
        return !!this.value && this.value.length >= this.minlength
      },
      isSuccess () {
        return !!this.minlength && this.isFilledText
      },
      compClasses () {
        return {
          'base-text-field': true,
          'v-input--counter': !!this.counter,
          'v-input--counter-success-text': this.isSuccessText,
          'v-input--counter-error-text': this.isRequiredText || this.isMaxText,
        }
      },
    },
    watch: {
      value (v) {
        // console.log('v.l', this.value.length, this.minlength)
      },
      hasDispayErrors (v) {
        if (v) {
          this.showError = true

          if (this.errorStyle === 'tooltip') {
            this.showBaseTooltip = true
          }

          this.$nextTick(() => {
            const tooltip = this.$refs.customTooltip
            const styles = this.getTooltipStyles()

            if (styles) {
              tooltip.style = styles
            }
          })

          setTimeout(() => {
            this.showError = false
          }, 4000)
        } else {
          this.showError = false
        }
      },
    },
    mounted () {
      // console.log('this.$refs.vTextField', this.$refs.vTextField)
      if (this.errorStyle === 'tooltip') {
        this.setDrawerDialogOfParents()
        this.addInNavEventScroll(this.navScroll)
      }
    },
    beforeDestroy () {
      if (this.errorStyle === 'tooltip') {
        this.removeInNavEventScroll(this.navScroll)
      }
    },
    methods: {
      focus () {
        this.$refs.vTextField.focus()
      },
      updateError (e) {
        // console.log(e)
      },
      getTooltipStyles () {
        const plcmt = this.validationPlacement
        const tooltip = this.$refs.customTooltip
        const pad = 8

        if (!tooltip?.clientHeight || !tooltip?.clientWidth) return

        return plcmt === 'top' ? 'left: -' + (tooltip.clientWidth / 2 - pad) + 'px;bottom: 27px;'
          : plcmt === 'bottom' ? 'left: -' + (tooltip.clientWidth / 2 - pad) + 'px;top: 27px;'
            : plcmt === 'left' ? 'left: -' + (tooltip.clientWidth + pad) + 'px;top: -' + (tooltip.clientHeight / 2 - pad - 2) + 'px;'
              : plcmt === 'right' ? 'left: 28px;top: -' + (tooltip.clientHeight / 2 - pad - 2) + 'px;' : ''
      },
      navScroll (e) {
        this.$_.debounce(() => {
          this.navScrollTop = e.target.scrollTop
          if (this.$refs.vTextField?.$el) {
            this.getElTopFromNav(this.$refs.vTextField.$el, 'textfield')
          }
        }, 200)()
      },
      onFocus (e) {
        if (!this.$refs.vTextField) return

        // if (document.activeElement !== this.$refs.input) {
        //   return this.$refs.input.focus();
        // }

        if (!this.isFocused) {
          this.isFocused = true
          e && this.$emit('focus', e)
        }
      },
      onBlur (e) {
        this.isFocused = false
        e && this.$nextTick(() => this.$emit('blur', e))
      },
      onInput (e) {
        // console.log('onInput', e)
        e && this.$nextTick(() => this.$emit('input', e))
      },

      onKeydown (e) {
        // console.log('onKeydown', e)
        if (this.escapeClearable && e.key === 'Escape') {
          this.internalValue = ''
        }

        if (this.keyFilterRegexp && !this.keyFilterRegexp.test(e.key)) e.preventDefault()
        e && this.$nextTick(() => this.$emit('keydown', e))
      },

      onClick (e) {
        // if (this.isFocused || this.isDisabled || !this.$refs.vTextField) return;
        // this.$refs.input.focus();
        e && this.$emit('click', e)
      },
      onContextmenu (e) {
        e && this.$emit('contextmenu', e)
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/_typography";
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.base-text-field.theme--light.v-text-field--outlined {
  &.v-input--is-focused {
    & ::v-deep  {
      .v-input__control {
        .v-input__slot {
          background: $neutral-200;
          fieldset {
            border: 1px solid currentColor;
            color: $primary-base;
          }
        }
      }
    }
  }
  & ::v-deep {
    .v-input__control {
      .v-input__slot {
        height: 45px;
        padding: 0 0 0 16px;
        &:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled) {
          &:hover {
            fieldset {
              color: $primary-base;
            }
          }
        }
        fieldset {
          // border-radius: 10px;
          color: $neutral-400;
        }
        .v-text-field__slot {
          padding-right: 4px;
          input {
            height: 21px;
            padding: 0;
          }
        }
      }
    }

    .v-input__prepend-inner {
      display: flex;
      align-items: center;
      margin: 0 4px 0 0!important;
      padding: 0!important;
      height: 100%;
    }

    .v-input__control {
      .v-input__append-inner {
        &:last-child {
          margin-right: 12px;
        }
        display: flex;
        align-items: center;
        padding: 0;
        flex-shrink: 0;
        margin-top: 0!important;
        height: 100%;
        &:not(:first-child):not(:last-child) {
          margin-right: 4px;
        }
        .v-input__icon--clear {
          height: 21px;
          min-height: 21px;
        }
      }
    }
  }
}

.slot-append__row {
  .base-counter {
    @include body-xs-semibold;
    padding-right: 8px;
    white-space: nowrap;
  }
}

.base-text-field__tooltip {
  position: relative;
  opacity: 0;
  width: 21px;
  height: 21px;
  .icon-warning {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    background: transparent;
    top: 0;
    left: 0;
    &:hover {
      & + .tooltip-content[placement] {
        visibility: visible;
        z-index: 3;
        opacity: 1;
      }
      & + .tooltip-content[placement='top'],
      & + .tooltip-content[placement='bottom'],
      & + .tooltip-content[placement='left'],
      & + .tooltip-content[placement='right']
      {
        transform: translate(0, 0);
      }
    }
  }
  .tooltip-content {
    position: absolute;
    background-color: $error;
    color: $neutral-100;
    border-radius: 8px;
    padding: 8px;
    width: 134px;
    min-width: 134px;
    z-index: 20;
    text-align: center;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    @include body-s-semibold;
    &.show {
      visibility: visible;
      &[placement] {
        opacity: 1 !important;
        z-index: 3 !important;
        transform: translate(0, 0) !important;
      }
    }
  }
  .tooltip-content[placement] {
    &:before {
      content: '';
      position: absolute;
      border-width: 8px;
      border-style: solid;
    }
  }
  .tooltip-content[placement='top'] {
    transform: translate(0, 10%);
    &:before {
      bottom: -14px;
      right: calc(50% - 10px);
      border-color: $error transparent transparent transparent;
    }
  }
  .tooltip-content[placement='bottom'] {
    transform: translate(0, -10%);
    &:before {
      top: -14px;
      right: calc(50% - 10px);
      border-color: transparent transparent $error transparent;
    }
  }
  .tooltip-content[placement='left'] {
    transform: translate(10%, 0);
    &:before {
      top: calc(50% - 8px);
      right: -14px;
      border-color: transparent transparent transparent $error;
    }
  }
  .tooltip-content[placement='right'] {
    transform: translate(-10%, 0);
    &:before {
      top: calc(50% - 8px);
      left: -14px;
      border-color: transparent $error transparent transparent;
    }
  }
}
</style>
