<template>
  <div>
    <vue-editor
      v-model="internalValue"
      :api-key="$config.apiKeys.tinyMCE"
      v-bind="$attrs"
      output-format="html"
      :init="{
        height: 264,
        language: 'ru',
        menubar: false,
        statusbar: false,
        content_style: contentStyle,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'formatselect | bold italic underline | numlist bullist'
      }"
    />
    <v-alert
      v-show="hasError"
      type="error"
      dense
      text
    >
      {{ validations[0] }}
    </v-alert>
  </div>
</template>

<script>
  import VueEditor from '@tinymce/tinymce-vue'
  import Validatable from 'vuetify/lib/mixins/validatable'
  // eslint-disable-next-line import/no-webpack-loader-syntax
  import contentStyle from '!!css-loader!sass-loader!./components/text-editor/content.extract.scss'

  export default {
    components: {
      VueEditor,
    },
    mixins: [Validatable],
    props: {

    },
    data () {
      return {
        contentStyle: contentStyle,
      }
    },
    computed: {

    },
    mounted () {
    },
    methods: {

    },
  }
</script>

<style lang="scss">
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
@import "@/styles/_typography.sass";

.tox-tinymce {
  border-radius: 12px!important;
  border-color: $neutral-400!important;
  .tox-editor-container {
    .tox-editor-header {
      .tox-toolbar__primary {
        background: $neutral-200!important;
      }
      .tox-icon {
        svg {
          fill: $neutral-600!important;
        }
      }
      .tox-toolbar__group {
        border-right: none!important;
        padding: 0!important;
        margin: 0 0 0 34px!important;
        &:first-child {
          margin-left: 16px!important;
        }
      }
      .tox-tbtn.tox-tbtn--select {
        .tox-tbtn__select-label {
          @include body-s-medium;
          color: $neutral-600!important;
          margin-right: 10px!important;
          width: inherit!important;
        }
      }
    }
  }
}
</style>
