// import validate from '@/utils/validate'
import i18n from '@/i18n'

export function emailV (v) {
    const rexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return rexp.test(String(v))
}

function formatMessage (errorMsg, attributeName, context = {}) {
    const attributeKey = 'attributes.' + attributeName
    const attribute = i18n.te(attributeKey)
        ? i18n.t(attributeKey)
        : attributeKey
    const validationKey = 'validation.' + errorMsg
    return i18n.te(validationKey)
        ? i18n.t(validationKey, { attribute, ...context })
        : errorMsg
}

class RulesBuilder {
    attribute;
    rules;

    constructor (attribute = null) {
        this.attribute = attribute
        this.rules = []
    }

    attribute (v) {
        this.attribute = v
        return this
    }

    label (v) {
        return this.attribute(v)
    }

    addRule (validator, errorMsg) {
        this.rules.push(
            (v) => validator(v) || formatMessage(errorMsg, this.attribute),
        )
        return this
    }

    when (boolValue, thenCalback, elseCalback) {
        if (boolValue === true && thenCalback) {
            thenCalback(this)
        } else if (boolValue === false && elseCalback) {
            elseCalback(this)
        }
        return this
    }

    all () {
        return this.rules
    }

    first () {
        return this.rules.length > 0 ? this.rules[0] : null
    }

    required () {
        this.addRule((v) => !!v, 'required')
        return this
    }

    static build (attribute) {
        return new RulesBuilder(attribute)
    }

    static attribute (attribute) {
        return RulesBuilder.build(attribute)
    }
}

const rulesMixin = {
    name: 'rulesMixin',

    computed: {
        $rules () {
            return RulesBuilder
        },
    },
}

export { rulesMixin }

export function required (attribute) {
    return new RulesBuilder(attribute).required().first()
}