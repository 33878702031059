import ApiService from '@/api/api-client'
import Vue from 'vue'
import { castingObjFields } from '@/utils'

const getDefaultState = () => {
    return {
        templates: [],
        notificationPickList: [],
        template: {},
        statistic: {},
    }
}

const state = getDefaultState()

const mutations = {
    SET_STATISTIC (state, payload) { state.statistic = payload },
    RESET_STATE: (state) => Object.assign(state, getDefaultState()),
    SET_TEMPLATES (state, payload) {
        state.templates = payload
    },
    SET_NOTIFICATION_PICK_LIST (state, payload) {
        state.notificationPickList = payload
    },
    SET_TEMPLATE (state, payload) {
        state.template = payload
    },
    ADD (state, payload) {
        const items = state.templates
        items.push(payload)
    },
    UPDATE (state, payload) {
        const idx = state.templates.findIndex(t => t.id === payload.id)
        const updated = Object.assign({},state.templates[idx], payload)
        console.log('updated', updated)
        Vue.set(state.templates, idx, updated) 
        
    },
    REMOVE (state, payload) {
        const items = state.templates
        items.forEach((item, index) => {
            if (item.id === payload.id) items.splice(index, 1)
        })
    },
    // ADD_ATTACHMENT (state, payload) {
    //     state.template.attachments.push(payload)
    // },
    // UPDATE_ATTACHMENT (state, payload) {
    //     const items = state.template.attachments
    //     items.forEach((item, index) => {
    //         if (item.id === payload.id) Object.assign(items[index], payload)
    //     })
    // },
    // REMOVE_ATTACHMENT (state, payload) {
    //     const items = state.template.attachments
    //     items.forEach((item, index) => {
    //         if (item.id === payload.id) items.splice(index, 1)
    //     })
    // },
}

const actions = {

    resetState ({ commit }) {
        commit('RESET_STATE')
    },

    async create ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {
            const data = castingObjFields(
                { program_id: 0, name: '', fallback_notification_id: null, channel_type: '', content: { title: '', description: '', body: '', design: '__schema-all__' } },
                item,
            )
            const result = await ApiService.post('/api-cabinet/program/notification', data)
            commit('ADD', result)
            commit('SET_TEMPLATE', result) // после создания шаблона переход на форму редактирования
            this._vm.$notify({
                type: 'success',
                title: 'Рассылка',
                text: 'Рассылка создана',
            })
            return result
        } catch (error) {
            throw error
        }
    },

    async list ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {
            const result = await ApiService.get(`/api-cabinet/program/notification/list?program_id=${item.program_id}`)
            commit('SET_TEMPLATES', result)
        } catch (error) {
            throw error
        }
    },

    async getPickList ({ commit }, programId) {
        const result = await ApiService.get(`/api-cabinet/program/notification/shortlist?program_id=${programId}`)
        commit('SET_NOTIFICATION_PICK_LIST', result)
    },

    async read ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {
            const result = await ApiService.get(`/api-cabinet/program/notification?id=${item.id}`)
            console.log('123123')
            console.log(result)
            commit('SET_TEMPLATE', result)
        } catch (error) {
            throw error
        }
    },

    async changeActive ({ state, commit }, { id, active }) {
      const currIdx = state.templates.findIndex(temp => temp.id === id)
      if (currIdx !== -1) {
        const template = state.templates[currIdx]
        try {
            template.loadingChangeActive = true
            commit('UPDATE', template)

            const result = await ApiService.put('/api-cabinet/program/notification/active', {
                id: id,
                active: active,
            })

            if (!result.active) {
                this._vm.$notify({
                    title: 'Рассылка',
                    text: 'Рассылка выключена',
                    type: 'success',
                })
            } else {
                this._vm.$notify({
                    title: 'Рассылка',
                    text: 'Рассылка включена',
                    type: 'success',
                })
            }

            template.active = result.active
        } catch (err) {
            template.active = !active
        } finally {
            template.loadingChangeActive = false
            commit('UPDATE', template)
        }
      }
    },

    async update ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {

            const data = castingObjFields(
                { id: 0, name: '', fallback_notification_id: null, channel_type: '', content: { title: '', description: '', body: '', file: {id: '', mime_type: '', url: '', original_name: ""}, design: '__schema-all__' } },
                item,
            )

            const result = await ApiService.put('/api-cabinet/program/notification', data)
            commit('UPDATE', result)
            this._vm.$notify({
                type: 'success',
                title: 'Рассылка',
                text: 'Рассылка изменена',
            })
        } catch (error) {
            throw error
        }
    },

    async delete ({ commit }, item) {
        // eslint-disable-next-line no-useless-catch
        try {
            await ApiService.delete(`/api-cabinet/program/notification?id=${item.id}`)
            // console.log('/api-cabinet/staff')
            // console.log(result)
            commit('REMOVE', item)
            this._vm.$notify({
                type: 'success',
                title: 'Рассылка',
                text: 'Рассылка удалена',
            })
        } catch (error) {
            throw error
        }
    },

    async sendContentNotification ({ commit }, { channelType, content, programId }) {
        try {
            await ApiService.post('/api-cabinet/program/notification/content/send', {
                channel_type: channelType,
                content: content,
                program_id: programId,
            })

            this._vm.$notify({
                type: 'success',
                title: 'Рассылка',
                text: 'Контент рассылки отправлен',
            })
        } catch (error) {
            console.log(error)
        }
    },

    async sendNotification ({ commit }, sendingId) {
        try {
            await ApiService.post('/api-cabinet/program/notification/send', {
                id: sendingId,
            })

            this._vm.$notify({
                type: 'success',
                title: 'Рассылка',
                text: 'Уведомление отправлено',
            })
        } catch (error) {
            console.log(error)
        }
    },

    async sendToModeration ({ commit }, id) {
        try {
            const result =  await ApiService.post('/api-cabinet/program/notification/moderation', { id })

            this._vm.$notify({
                type: 'success',
                title: 'Рассылка',
                text: 'Отпраавлено на модерацию',
            })
            commit('UPDATE', result)
        } catch (error) {
            console.log(error)
        }
    },

    async getStatisticsNotifications ({commit }, id) {
        try {
            const result =  await ApiService.get(`/api-cabinet/program/notification/statistic?notification_id=${id}`)
            commit('SET_STATISTIC', result)
        } catch (error) {
            console.log(error)
        }
    },
    
    async uploadImageWhatsAppImage ({commit}, {id, image}) {
        try {
            console.log('img')
            console.log(image)
            let formData = new FormData();
            formData.append('file', image);
            formData.append('notification_id', id);
            const result = await ApiService.post(`/api-cabinet/program/notification/whatsapp/file`, formData)
        } catch (error) {
            console.log(error)
        }
    },

    async deleteImageWhatsApp ({commit}, {id}) {
        try {
            await ApiService.delete(`/api-cabinet/program/notification/whatsapp/file?notification_id=${id}`)
        } catch (error) {
            console.log(error)
        }
    }

    /*
     * ATTACHMENTS
     */

    // async createAttachment ({ commit }, item) {
    //     // eslint-disable-next-line no-useless-catch
    //     try {
    //         const result = await ApiService.post('/api-cabinet/program/notifications/attachments', item)
    //         commit('ADD_ATTACHMENT', result)
    //         this._vm.$notify({
    //             type: 'success',
    //             title: 'Шаблоны рассылки',
    //             text: 'Блок успешно создан',
    //         })
    //     } catch (error) {
    //         throw error
    //     }
    // },

    // async sortAttachment ({ commit }, item) {
    //     // eslint-disable-next-line no-useless-catch
    //     try {
    //         const result = await ApiService.put('/api-cabinet/program/notifications/attachments/sort', item)
    //     } catch (error) {
    //         throw error
    //     }
    // },

    // async deleteAttachment ({ commit }, item) {
    //     // eslint-disable-next-line no-useless-catch
    //     try {
    //         const result = await ApiService.delete(`/api-cabinet/program/notifications/attachments?id=${item.id}`)
    //         commit('REMOVE_ATTACHMENT', result)
    //         this._vm.$notify({
    //             type: 'success',
    //             title: 'Шаблоны рассылки',
    //             text: 'Блок удален',
    //         })
    //     } catch (error) {
    //         throw error
    //     }
    // },

    // async uploadAttachmentFile ({ commit }, item) {
    //     // eslint-disable-next-line no-useless-catch
    //     try {
    //         const result = await ApiService.post('/api-cabinet/program/notifications/attachments/file', item)
    //         // console.log(result)
    //         commit('UPDATE', result)
    //         commit('SET_TEMPLATE', result)
    //         this._vm.$notify({
    //             type: 'success',
    //             title: 'Шаблоны рассылки',
    //             text: 'Блок успешно изменен',
    //         })
    //     } catch (error) {
    //         throw error
    //     }
    // },

    // async deleteAttachmentFile ({ commit }, item) {
    //     // eslint-disable-next-line no-useless-catch
    //     try {
    //         const result = await ApiService.delete(`/api-cabinet/program/notifications/attachments/file?id=${item.id}`)
    //         commit('UPDATE', result)
    //         commit('SET_TEMPLATE', result)
    //         this._vm.$notify({
    //             type: 'success',
    //             title: 'Шаблоны рассылки',
    //             text: 'Файл удален',
    //         })
    //     } catch (error) {
    //         throw error
    //     }
    // },

}

const getters = {
    statistic: state => state.statistic,
    templates: state => state.templates,
    notificationPickList: state => state.notificationPickList.map(item => {
        Vue.set(item, 'disabled', !item.active)
        return item
    }),
    template: state => state.template,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
