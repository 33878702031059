import ApiService from '@/api/api-client'
import moment from 'moment'
import { config } from '@/config'


export default {
    namespaced: true,
    state: {
        trashList: [],
        totalCount: 0
    },
    mutations: {
        SET_TRASH_LIST(state, payload) {
            state.trashList = payload.items
            state.totalCount = payload.total
        },

        CLEAR_TRASH_LIST(state) {
            state.trashList = []
        },

        REMOVE_ELEMENTS_TRASH_LIST(state, ids) {
            ids.forEach(function(id) {
                state.trashList = state.trashList.filter(item => item['id'] !== id)
            });
        }
    },
    actions: {
        async getTrashList({ commit }, {programId, limit, offset, search, cancelToken}) {
            const list = await ApiService.get('api-cabinet/program/trash/accounts/list', {
                params: {
                    program_id: programId,
                    limit,
                    offset,
                    search
                },
                cancelToken
            })

            commit("SET_TRASH_LIST", list)
        },

        async deleteAll({ commit }, programId) {
            await ApiService.post('api-cabinet/program/trash/accounts/delete/all', { 
                program_id: programId,
                async: true,
            })

            commit("CLEAR_TRASH_LIST")
        },

        async restoreAll({ commit }, programId) {
            await ApiService.post('api-cabinet/program/trash/accounts/restore/all', {
                program_id: programId
            })

            commit("CLEAR_TRASH_LIST")
        },

        async restore ({ commit }, {programId, ids}) {
            await ApiService.post('api-cabinet/program/trash/accounts/restore', {
                    program_id: programId,
                    ids: ids 
            })

            commit("REMOVE_ELEMENTS_TRASH_LIST", ids)
        },

        async delete ({ commit }, {programId, ids}) {
            await ApiService.post('api-cabinet/program/trash/accounts/delete', {
                program_id: programId,
                ids: ids
            })

            commit("REMOVE_ELEMENTS_TRASH_LIST", ids)
        }

    },
    getters: {
        trashList(state) {

            return state.trashList.map(item => {
                item.deleted_at_display = moment.utc(item.deleted_at).local().format(config.date.DATETIME_FORMAT)
                

                if (item.client_name === null) {
                    item.client_name = "Без имени"
                }

                return item
            })
        },
        totalCount(state) {
            return state.totalCount
        }
    },
}
