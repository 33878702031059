import ApiService from '@/api/api-client'

export default {
  namespaced: true,
  state: {
    accountFields: [],
  },
  mutations: {
    SET_ACCOUNT_FIELDS (state, payload) {
      state.accountFields = payload
    },
    ADD_IN_ACCOUNT_FIELDS (state, payload) {
      state.accountFields.push(payload)
    },
  },
  actions: {
    async CreateAccountFields ({ commit }, payload) {
      const result = await ApiService.post('/api-cabinet/crm/account/fields', payload)
      commit('SET_ACCOUNT_FIELDS', result)
    },
    async GetAccountFields ({ commit }, id) {
      const result = await ApiService.get('/api-cabinet/crm/account/fields?account_id=' + id)
      commit('SET_ACCOUNT_FIELDS', result)
    },
    async GetAllAccountFields ({ commit }, id) {
      const result = await ApiService.get('/api-cabinet/crm/account/fields/all?program_id=' + id)
      commit('SET_ACCOUNT_FIELDS', result)
    },
  },
  getters: {
    accountFields (state) {
      return state.accountFields
    },
  },
}