<template>
  <div class="base-pagination">
    <v-btn
      class="btn-prev"
      text
      :ripple="false"
      @click="clickPrev"
    >
      <v-icon
        :color="enablePrevIcon ? 'primary-base' : 'neutral-400'"
        size="15"
      >
        $iconify_chevron-left
      </v-icon>
    </v-btn>
    <base-text-field
      v-model="page"
      v-mask="'#'.repeat(String(value).length)"
      :height="41"
      class="base-pagination__field"
    />
    <v-btn
      class="btn-next"
      text
      :ripple="false"
      @click="clickNext"
    >
      <v-icon
        :color="enableNextIcon ? 'primary-base' : 'neutral-400'"
        size="15"
      >
        $iconify_chevron-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
  import { mask } from 'vue-the-mask'

  export default {
    directives: { mask },
    model: {
      prop: 'value',
      event: 'onChangeValue',
    },
    props: {
      value: {
        type: [Number, String],
        default: 1,
      },
      length: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        page: 1,
      }
    },
    computed: {
      enableNextIcon () {
        if (this.page < this.length) return true
        return false
      },
      enablePrevIcon () {
        if (this.page > 1) return true
        return false
      },
    },
    watch: {
      value (v) {
        if (v <= 0 || v > this.length) this.page = 1
        if (typeof v === 'string') v = Number(v)
        this.page = v
      },
      page (v) {
        if (!v || (v && v > this.length)) return
        this.$emit('onChangeValue', v)
      },
    },
    methods: {
      clickNext () {
        if (this.enableNextIcon) this.page += 1
      },
      clickPrev () {
        if (this.enablePrevIcon) this.page -= 1
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '@/styles/vuetify-preset-plus/light_theme/_variables.sass';
@import '@/styles/_typography.sass';

.base-pagination {
  display: inline-flex;
  align-items: center;
  .btn-prev {
    margin-right: 10px;
  }
  .btn-next {
    margin-left: 10px;
  }
  .btn-prev,.btn-next {
    min-width: 0;
  }
  & ::v-deep .base-pagination__field {
    max-width: 41px;
    min-height: 41px;
    .v-input__slot {
      padding: 0!important;
      .v-input__append-inner {
        display: none;
      }
      min-height: 41px!important;
      fieldset {
        color: $neutral-400!important;
        border-radius: 10px!important;
      }
      input {
        width: 41px!important;
        @include body-s-medium;
        color: $neutral-900;
        text-align: center;
      }
    }
  }
}
</style>
