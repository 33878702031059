<template>
  <v-chip
    v-if="chip"
    class="body-s-semibold segment-chip"
    label
    link
    :close="close"
    @click="click"
    :style="
      color != undefined
        ? `color: ${color}; background: ${hexToRgbA(color, '0.15')}`
        : ''
    "
    @click:close="clickClose"
  >
    <base-tooltip
      :text="name"
      :disabled="!(chip && name && name.length > maxLen)"
      top
    >
      <div>
        <v-icon v-if="chipAuto">
          mdi-brightness-auto
        </v-icon>
        <v-icon v-if="selected">
          mdi-checkbox-marked-circle
        </v-icon>
        <template v-if="is_public != null">
          <v-icon
            v-if="is_public"
            color="success"
          >
            mdi-eye
          </v-icon>
          <v-icon v-else>
            mdi-eye-off
          </v-icon>
        </template>

        {{ trimMaxLen(name) }}
      </div>
    </base-tooltip>
  </v-chip>
  <div v-else
    class = "d-flex flex-row align-center segment-chip"
    :style="{
          background: backgroundColor || color && hexToRgbA(color, '0.15'),
          'max-width': maxWidth,
        }"
  >
    <div
      class="body-s-semibold overflow-hidden"
      :style="{
        color: textColor || color,
        //background: backgroundColor || color && hexToRgbA(color, '0.15'),
      }"
    >
      <template v-if="is_public != null">
        <v-icon
          v-if="is_public"
          color="success"
        >
          mdi-eye
        </v-icon>
        <v-icon v-else>
          mdi-eye-off
        </v-icon>
      </template>
      <template v-if="chipAuto">
        <v-icon>
          mdi-brightness-auto
        </v-icon>
      </template>
      <span> 
        {{ name }} 
        
      </span>
    </div>
    <div
      class="close-icon"
      v-if="close"
    >
      <v-icon 
          size="14px"
          
          @click="clickClose"
        >
          mdi-close
        </v-icon>
      </div>
  </div>
  
</template>

<script>
  import Convertor from '@/mixins/convertor'
  export default {
    name: 'SegmentChip',
    mixins: [Convertor],
    props: {
      name: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        default: undefined,
      },
      maxLen: { type: Number, default: 25 },
      maxWidth: { type: [Number, String], default: '100%' },
      selected: { type: Boolean, default: false },
      is_public: { type: Boolean, default: undefined },
      chip: { type: Boolean, default: false },
      close: { type: Boolean, default: false },
      textColor: { type: String, default: null },
      backgroundColor: { type: String, default: null },
      chipAuto: { type: Boolean, default: false },
    },
    mounted () {},
    methods: {
      trimMaxLen (value) {
        return value && this.maxLen && value.length > this.maxLen
          ? value.substring(0, this.maxLen) + '...'
          : value
      },
      clickClose () {
        this.$emit('click:close')
        console.log('click close');
      },
      click () {
        this.$emit('click')
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
.segment-chip {
  // max-width:20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  cursor: default;
}

.overflow-hidden{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.close-icon{
  margin-left: 10px;
}
</style>
