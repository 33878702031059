import ApiService from '@/api/api-client'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    fields: {},
  },
  mutations: {
    SET_FIELDS (state, payload) {
      state.fields = payload
    },
    UPDATE_IN_FIELDS (state, payload) {
      const idx = state.fields
        .findIndex(f => f.id === payload.id)
      Vue.set(state.fields, idx, payload)
    },
  },
  actions: {
    async GetAllFields ({ commit }, nomenclatureUuid) {
      const result = await ApiService.get('/api-cabinet/program/nomenclature/fields/all?nomenclature_uuid=' + nomenclatureUuid)
      commit('SET_FIELDS', result)
    },
    async GetFields ({ commit }, nomenclatureUuid) {
      const result = await ApiService.get('/api-cabinet/program/nomenclature/fields?nomenclature_uuid=' + nomenclatureUuid)
      commit('SET_FIELDS', result)
    },
    async UpdateFields ({ commit }, payload) {
      const result = await ApiService.post('/api-cabinet/program/nomenclature/fields', payload)
      commit('UPDATE_IN_FIELDS', result)
    },
  },
  getters: {
    fields (state) {
      return state.fields
    },
  },
}
