/* eslint-disable camelcase */
import ApiService from '@/api/api-client'

export default {
    namespaced: true,
    state: {},
    mutations: {

    },
    actions: {
        async checkReferCode ({ commit }, { program_id, refer_code }) {
            const result = await ApiService.get(
                '/api-cabinet/program/account/referral/check', {
                    params: { program_id, refer_code },
                },
            )
            return result.exists
        },
        async accountReferInfo ({ commit }, id) {
            const result = await ApiService.get(
                '/api-cabinet/program/account/referral/info?account_id=' + id,
            )
            return result
        },
        async sendReferCodeSMS ({ commit }, account_id) {
            const result = await ApiService.post(
                '/api-cabinet/program/account/referral/code/send', { account_id },
            )
            return result
        },
        async setReferrerByCode ({ commit }, { account_id, refer_code }) {
            const result = await ApiService.post(
                '/api-cabinet/program/account/referrer/code',
                { account_id, refer_code },
            )
            this._vm.$notify({
                type: 'success',
                title: 'Клиенты',
                text: 'Операция успешно проведена',
            })
            return result
        },
        async setAccountReferrer ({ commit }, { account_id, master_account_id }) {
            const result = await ApiService.post(
                '/api-cabinet/program/account/referrer',
                { account_id, master_account_id },
            )
            this._vm.$notify({
                type: 'success',
                title: 'Клиенты',
                text: 'Операция успешно проведена',
            })
            return result
        },
        async removeReferral ({ commit }, { account_id, referral_account_id }) {
            const result = await ApiService.delete(
                `/api-cabinet/program/account/referral/remove?account_id=${account_id}&&referral_account_id=${referral_account_id}`,
            )
            this._vm.$notify({
                type: 'success',
                title: 'Клиенты',
                text: 'Операция успешно проведена',
            })
            return result
        },
        async updateCodeRef ({commit}, {account_id, refer_code}) {
            const result = await ApiService.post(
                '/api-cabinet/program/account/referral/code/update',
                {account_id, refer_code}
            )
            return result
        }
    },
    getters: {},
}
