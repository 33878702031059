<template>
  <div
    class="bonus-accounts"
  >
    <div class="bonus-accounts__header">
      <p class="body-l-semibold">
        Бонусные счета
      </p>
    </div>
    <div
      v-if="loading && loadingData"
    >
      <v-skeleton-loader
        type="table-tfoot"
      />
      <v-skeleton-loader
        type="table-tfoot"
      />
    </div>
    <div
      v-else
      class="bonus-accounts__cards"
    >
      <div
        v-for="(item, i) in accountBalances"
        :key="i"
        class="bonus-accounts__card"
      >
        <v-row class="card__header">
          <v-col class="card__header-title body-l-semibold mr-auto">
            <v-icon v-if="isUnitMain(item.unit_id)">mdi-star</v-icon>
            {{ item.name }}
          </v-col>
          <v-col
            class="card__header-count title-m-bold success--text"
            cols="auto"
          >
            <span>{{ item.balance_str | spacesBetweenNumbers }}</span>
            <span
              v-if="item.max_value"
              class="neutral-400--text"
            >/{{ item.max_value }}</span>
          </v-col>
          <v-col
            class="card__header-control"
            cols="auto"
          >
            <v-row>
              <!-- списание/отмена -->
              <!-- блокируем операцию если нет ручного бонусного ресурса -->
              <v-btn
                v-if="editedItem.unit_id !== item.unit_id"
                class="control-btn"
                color="primary-100"
                :disabled="!isManual(item, 'TYPE_TARGET') || !valid"
                @click="menuOperation(item, 'FROM')"
              >
                <iconify-icon
                  icon="feather-minus"
                  width="15"
                />
              </v-btn>
              <v-btn
                v-else
                class="control-btn"
                color="primary-100"
                @click="menuClose()"
              >
                <iconify-icon
                  icon="jam-close"
                  width="15"
                />
              </v-btn>

              <!-- начисление/подтверждение -->
              <!-- блокируем операцию если нет ручного бонусного ресурса -->
              <v-btn
                v-if="editedItem.unit_id !== item.unit_id"
                class="control-btn"
                color="primary-100"
                :disabled="!isManual(item, 'TYPE_SOURCE') || !valid"
                @click="menuOperation(item, 'TO')"
              >
                <iconify-icon
                  icon="eva-plus-outline"
                  width="15"
                />
              </v-btn>
              <v-btn
                v-else
                class="control-btn active"
                color="primary-100"
                :loading="loadingOperation"
                :disabled="!validOperation"
                @click="processing(editedItem.unit_id)"
              >
                <iconify-icon
                  icon="octicon-check-16"
                  width="15"
                />
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <div
          v-if="editedItem.unit_id === item.unit_id"
          class="card__body"
        >
          <v-form
            ref="bonusForm"
            v-model="validOperation"
            class="card__body-form"
          >
            <div class="card__body-select">              
              <v-select
                v-if="availableOperations.length > 1"
                v-model="selectedBonusResourceId"
                :items="availableOperations"
                item-text="title"
                item-value="score_id"
                placeholder="Выберите операцию"
                hide-details
                :rules="[
                  v => !!v || 'Выберите операцию'
                ]"
                outlined
              />
            </div>
            <div class="card__body-group">
              <base-bonus-operation-field
                v-model="operation.value"
                class="panel-crm__form-input card__body-input"
                placeholder="сумма"
                :prefix="operationMode === 'FROM' ? 'Списать: ' : 'Начислить: '"
                :decimal="false"
                hide-details
              />
              <base-number-field
                v-if="operationMode === 'TO'"
                v-model="operation.expired_days"
                prefix="Действуют: "
                placeholder="(дней)"
                class="panel-crm__form-input card__body-input"
                :rules="daysRules"
                is-positive
                clearable
                validation-placement="right"
              />
            </div>
            <div
              class="panel-crm__form-textarea"
              style="margin-bottom: 20px"
            >
              <v-textarea
                v-model="operation.comment"
                class="panel-crm__form-input card__body-input"
                rows="4"
                placeholder="Введите комментарий (необязательно)"
                outlined
                auto-grow
                hide-details
                clearable
                :rules="[
                  v => String(v).length <= 255 || 'Комментарий должен быть не более 255 символов'
                ]"
              />
              <div class="textarea---angle" />
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // other
  import { mask } from 'vue-the-mask'
  import { isFilled } from '@/utils/validate'

  export default {
    directives: { mask },
    props: {
      clientData: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        valid: true,
        loadingData: false,
        validOperation: false,
        loadingOperation: false,
        editedItem: {},
        operationMode: null,
        selectedBonusResourceId: null,
        operation: {
          value: null,
          expired_days: null,
          comment: null,
        },
        daysRules: [
          (v) => (!isFilled(v) || v > 0) || 'Введите дни',
          (v) => v <= this.$config.MAX_DAYS || `Не более ${this.$config.MAX_DAYS.toLocaleString()}`,
        ],
      }
    },
    computed: {
      loading () {
        return this.$store.getters['crm/clientCard/loading']
      },
      bonusResources () {
        return this.$store.getters['company/bonus_resources/bonusResources']
      },
      // доступные ручные бонусные ресурсы
      availableOperations () {
        let type = null
        if (this.operationMode === 'FROM') type = 'TYPE_TARGET'
        if (this.operationMode === 'TO') type = 'TYPE_SOURCE'
        return this.bonusResources.filter(item => {
          return item.bonus_score.units_id === this.editedItem.unit_id &&
            item.resource_type_enum === type &&
            item.bonus_score && item.bonus_score.active &&
            (!item.rules || !item.rules.event)
        })
      },
      accountBalances () {
        const result = this.$store.getters['crm/clientCard/accountBalances']
        result.forEach(item => {
          if (!item.max_value) {
            item.icon = null
          }
        })
        return result
      },
      accountClient () {
        return this.$store.getters['crm/clientCard/client']
      },
      bonusUnits () {
        return this.$store.getters['company/bonus_units/bonusUnits']
      },
    },
    async mounted(){
      await this.fetchData() // Подгрузка валют
    },
    watch: {
      accountClient (value) {
        value && value.id && this.fetchData()
      },
    },
    methods: {
      getMask (mode) {
        return mode === 'FROM' ? 'Списать: ###########' : 'Начислить: ###########'
      },
      isManual (unit, type) {
        const res = this.bonusResources.find(item => {
          return item.bonus_score.units_id === unit.unit_id &&
            item.resource_type_enum === type &&
            item.bonus_score && item.bonus_score.active &&
            (!item.rules || !item.rules.event)
        })
        if (res) return true
        return false
      },
      menuOperation (item, operation) {
        this.operationMode = operation
        this.editedItem = item
        if (this.availableOperations.length === 1) {
          this.selectedBonusResourceId = this.availableOperations[0].score_id
        }
      },
      menuClose () {
        this.operationMode = null
        this.selectedBonusResourceId = null
        this.operation = {
          value: null,
          expired_days: null,
          comment: null,
        }
        this.valid = true
        this.editedItem = {}
      },
      clearValue (v) {
        v = String(v).replace('Списать: ', '')
        v = v.replace('Начислить: ', '')
        return Number(v)
      },
      clearDays (v) {
        v = String(v).replace('Действуют: ', '')
        return Number(v)
      },
      isUnitMain(id){
        const unit = this.bonusUnits.find(item => item.id === id)
        if (unit)
          return unit.is_main
      },
      async processing (unitId) {
        const bonusForm = Array.isArray(this.$refs.bonusForm) && this.$refs.bonusForm.length > 0 ? this.$refs.bonusForm[0] : this.$refs.bonusForm
        if (!bonusForm) return
        if (!bonusForm.validate() || this.loadingOperation) return
        try {
          this.loadingOperation = true
          const operation = {
            account_id: this.clientData.id,
            direction: this.operationMode,
            value: this.clearValue(this.operation.value),
            expired_days: this.clearDays(this.operation.expired_days),
            comment: this.operation.comment,
          }
          if (this.operationMode === 'FROM') operation.score_target_id = this.selectedBonusResourceId
          if (this.operationMode === 'TO') operation.score_source_id = this.selectedBonusResourceId
          await this.$store.dispatch('crm/clientCard/transactionsAdd', operation)
          await this.$store.dispatch('crm/clientCard/getAccountBalances', this.clientData) // обнвление баланса
          this.$notify({
            type: 'success',
            title: 'Клиенты',
            text: 'Бонусная операция успешно проведена',
          })
          this.menuClose()
          this.$bus.$emit('side-panel.editClient.tabOperation:fetchData')
        } finally {
          this.loadingOperation = false
        }
      },
      async fetchData () {
        if (this.loadingData || !this.accountClient) return
        try {
          this.loadingData = true
          await this.$store.dispatch('company/bonus_units/loadBonusUnits', this.accountClient.program_id)
        } finally {
          this.loadingData = false
        }
      },
    },
  }
</script>
<style lang="scss">
  @import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
  @import "@/styles/_typography.sass";
  .bonus-accounts {
    margin-top: 16px;
    .bonus-accounts__header {
      margin-bottom: 8px;
    }
    .bonus-accounts__cards {
      .bonus-accounts__card {
        margin-bottom: 8px;
        background: $neutral-100;
        box-shadow: 0px 24px 20px -16px rgba(88, 93, 106, 0.1);
        border: 1px solid $neutral-250;
        border-radius: 10px;
        .card__header {
          display: flex;
          align-items: center;
          padding: 24px 20px 24px 20px;
          .card__header-title {
             overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .card__header-count {
            margin-left: auto;
            margin-right: 20px;
            white-space: nowrap;
          }
          .card__header-control {
            .control-btn {
              width: 31px;
              height: 31px;
              min-width: inherit;
              padding: 0 !important;
              border-radius: 50px;
              margin-left: 12px;
              .v-btn__content {
                color: $primary-base;
              }

              &.active {
                background: linear-gradient(90deg, $primary-base 0%, $secondary-base 100%);
                .v-btn__content {
                  color: $neutral-100;
                }
              }
            }
          }
        }

        .card__body {
          padding: 0 20px 0 20px;
          .card__body-form {
            .card__body-input {
                margin-bottom: 12px;
                .v-input__slot {
                  margin-bottom: 0 !important;
                }
                input {
                  &::focus {
                    background-color: $neutral-200 !important;
                  }
                }
                input, textarea {
                  @include body-m-medium;
                  &::placeholder {
                    color: $neutral-600 !important;
                  }
                }
            }
            .card__body-select {
              margin-bottom: 12px;
            }
            .textarea---angle {
              bottom: 10px;
            }
            .card__body-group {
              display: flex;
              .card__body-input {
                  &:nth-child(2) {
                      margin-left: 13px;
                  }
              }
            }
          }
        }
      }
    }

    .bonus-accounts__success {
      margin-top: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-success {
        margin-right: 6px;
      }
    }

    .card__body-form {
      .card__body-select {
          margin-bottom: 12px;
      }
    }

    p {
      margin-bottom: 0;
    }
  }
</style>
