var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.chip)?_c('v-chip',{staticClass:"body-s-semibold segment-chip",style:(_vm.color != undefined
      ? ("color: " + _vm.color + "; background: " + (_vm.hexToRgbA(_vm.color, '0.15')))
      : ''),attrs:{"label":"","link":"","close":_vm.close},on:{"click":_vm.click,"click:close":_vm.clickClose}},[_c('base-tooltip',{attrs:{"text":_vm.name,"disabled":!(_vm.chip && _vm.name && _vm.name.length > _vm.maxLen),"top":""}},[_c('div',[(_vm.chipAuto)?_c('v-icon',[_vm._v(" mdi-brightness-auto ")]):_vm._e(),(_vm.selected)?_c('v-icon',[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e(),(_vm.is_public != null)?[(_vm.is_public)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-eye ")]):_c('v-icon',[_vm._v(" mdi-eye-off ")])]:_vm._e(),_vm._v(" "+_vm._s(_vm.trimMaxLen(_vm.name))+" ")],2)])],1):_c('div',{staticClass:"d-flex flex-row align-center segment-chip",style:({
        background: _vm.backgroundColor || _vm.color && _vm.hexToRgbA(_vm.color, '0.15'),
        'max-width': _vm.maxWidth,
      })},[_c('div',{staticClass:"body-s-semibold overflow-hidden",style:({
      color: _vm.textColor || _vm.color,
      //background: backgroundColor || color && hexToRgbA(color, '0.15'),
    })},[(_vm.is_public != null)?[(_vm.is_public)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-eye ")]):_c('v-icon',[_vm._v(" mdi-eye-off ")])]:_vm._e(),(_vm.chipAuto)?[_c('v-icon',[_vm._v(" mdi-brightness-auto ")])]:_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.name)+" ")])],2),(_vm.close)?_c('div',{staticClass:"close-icon"},[_c('v-icon',{attrs:{"size":"14px"},on:{"click":_vm.clickClose}},[_vm._v(" mdi-close ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }