import { VIcon, VSpacer, VCol, VRow } from 'vuetify/lib/components'
import SelectPageLimit from '@/components/dialogs/SelectPageLimit'
import { existsField, declOfNum, filter } from './helpers'
import { wrapInArray } from 'vuetify/lib/util/helpers'
import BasePagination from '@/components/base/Pagination'

export function genDefaultHeader (vm, { props, on }) {
  const th = props.headers.map(function (header) {
    const thContent = [
      vm.$createElement('span', {
        staticClass: 'header-text body-s-medium neutral-600--text',
      }, header.text),
    ]
    if (header?.text?.length && isSortableCell(header)) {
      thContent.push(
        vm.$createElement(VIcon, {
          staticClass: 'icon',
          class: isSortableCell(header) && isHeaderSortedByDesc(header, props) ? 'desc' : '',
          props: {
            size: 13,
          },
        }, props.sortIcon ? props.sortIcon : '$iconify_feather-chevron-down'),
      )
    }

    return vm.$createElement('th', {
      key: header.text,
      attrs: {
        ariaLabel: header.text,
      },
      class: getCellClasses(header, props),
      style: {
        width: header.width,
        minWidth: header.width,
      },
      on: {
        click: () => clickSortCell(header, on),
      },
    }, thContent)
  })

  return vm.$createElement('thead', {
    staticClass: 'v-data-table-header',
  }, [
    vm.$createElement('tr', wrapInArray(th)),
  ])
}

export function genDefaultFooter (vm, { props }) {
  const colInfo = vm.$createElement(VCol, {
    props: {
      cols: 'auto',
    },
    style: {
      marginRight: '20px',
      paddingLeft: '0px',
    },
  }, [
    vm.$createElement('p', {
      staticClass: 'body-s-medium neutral-600--text mb-0',
    },
    [
      'Всего \n' +
      filter.call(vm, 'spacesBetweenNumbers', props.pagination.itemsLength) + '\n' +
      declOfNum(props.pagination.itemsLength, vm.$props.wordOperations) + '\n' +
      'на ' + props.pagination.pageCount + '\n' +
      declOfNum(props.pagination.pageCount, ['странице', 'страницах', 'страницах']),
    ]),
  ])

  const colSelectPageLimit = vm.$createElement(VCol, {
    props: {
      cols: 'auto',
    },
    style: {
      paddingLeft: '0px',
    },
  }, [
    vm.$createElement(SelectPageLimit, {
      props: {
        minWidth: '200px',
        items: vm.$props.paginationOptions,
        model: props.options.itemsPerPage,
        itemValue: 'value',
        itemLabel: 'text',
      },
      on: {
        'update:model': (event) => {
          props.options.itemsPerPage = event
          props.options.page = 1
          vm.updateOptions(props.options)
        },
      },
    }),
  ])

  const colPagination = vm.$createElement(VCol, {
    staticClass: 'text-end pa-0',
  }, [
    vm.$createElement(BasePagination, {
      staticClass: 'table-pagination',
      props: {
        value: props.options.page,
        length: props.pagination.pageCount,
      },
      on: {
        onChangeValue: (value) => {
          props.options.page = value
          vm.updateOptions(props.options)
        },
      },
    }),
  ])

    return vm.$createElement(VRow, {
    staticClass: 'table-pagination-block',
    props: {
      align: 'center',
    },
  }, [
    colInfo,
    colSelectPageLimit,
    vm.$createElement(VSpacer),
    colPagination,
  ])
}

function isHeaderSortedByDesc(header, props){
  
  let headerIndex = props.options.sortBy.indexOf(header.value)
  console.log('found header', props.options.sortBy[headerIndex], props.options.sortDesc[headerIndex]);
  if (headerIndex >= 0)
    return props.options.sortDesc[headerIndex]
}

function getCellClasses (header, props) {
  return [
    header.class ? header.class : '',
    !isSortableCell(header) ? 'non-sort' : '',
    props.options.sortBy && props.options.sortBy.includes(header.value) ? 'sortable' : '',
    header.align ? 'text-' + header.align : '',
  ]
}

function isSortableCell (header) {
  return !existsField(header, 'sortable') ||
    (existsField(header, 'sortable') && header.sortable)
}

function clickSortCell (header, on) {
  if (isSortableCell(header)) {
    on.sort(header.value)
  }
}
