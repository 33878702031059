import { VIcon, VExpandTransition } from 'vuetify/lib/components'
import SelfItem from './List.js'
import { deepClone } from '@/utils'
import { defaultProps } from './props'

export default {
  components: { SelfItem },
  props: {
    ...defaultProps,
    itemStates: {
      type: Object,
      default: () => {
        return {}
      },
    },
    itemsWithParentVal: {
      type: Array,
      default: function () {
        return []
      },
    },
    itemsLinear: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  render () {
    return this.genItems(this.items)
  },
  watch: {
    searchText (v) {
      this.$_.debounce(() => {
        this.selectByString(v)
      }, 300)()
    },
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    genItems (items) {
      if (items.length) {
         return this.$createElement('ul', { staticClass: 'category__list' }, [
          items.map(item => {
            return this.$createElement('li', { staticClass: 'category__item' }, [
              this.$createElement('div', {
                staticClass: 'item-text',
                class: [this.isClickItem(item) ? 'clicked' : ''],
              }, [
                this.$createElement('div', {
                  class: {
                    open: item[this.childKey].length && this.isOpen(item),
                  },
                  staticClass: 'left-block',
                }, [
                  this.genItemLeftIcon(item),
                  this.$createElement('div', {
                    staticClass : 'd-flex flex-row align-center'
                  },
                  [
                    this.$createElement('p' , {
                      staticClass: 'body-s-semibold neutral-600--text mb-0 mr-1',
                      on: {
                        click: ev => {
                          this.clickedItem(item, ev)
                        },
                      }
                    },
                    item[this.itemText]),
                    this.genItemAfterTextIcon(item),
                  ]),
                ]),
                this.$createElement('div', {
                  staticClass: 'right-block',
                  on: {
                    click: ev => {
                      this.clickedOpen(item)
                      this.$emit('clickOpen', item, ev)
                    },
                  },
                }, [this.genItemRightIcon(item)]),
              ]),
              this.$createElement(VExpandTransition, {}, [
                  this.$createElement(SelfItem, {
                    style: {
                      display: this.isOpen(item) ? 'block' : 'none',
                    },
                    props: {
                      itemStates: this.itemStates,
                      items: item[this.childKey],
                      itemsWithParentVal: this.itemsWithParentVal,
                      itemsLinear: this.itemsLinear,
                      itemValue: this.itemValue,
                      itemText: this.itemText,
                      childKey: this.childKey,
                    },
                    on: {
                      clickItem: (item, ev) => {
                        this.$emit('clickItem', item, ev)
                      },
                      clickOpen: (item, ev) => {
                        this.$emit('clickOpen', item, ev)
                      },
                      clickCheckbox: (item, ev) => {
                        this.$emit('clickCheckbox', item, ev)
                      },
                      editOpen: (item, ev) =>{
                        this.$emit('editOpen', item , ev)
                      }
                    },
                  }),
              ]),
            ])
          }),
        ])
      }
    },
    genItemAfterTextIcon(item){
      //console.log("CATEGORY LIST ITEM",item)
      if (item.isNullCategory) return
      let icon = '$iconify_feather-edit-2'

      return this.$createElement(VIcon, {
        staticClass: 'edit-icon',
        props: {
          size: '15',
          color:'neutral-500',
        },
        on: {
          click: ev => {
            this.$emit('editOpen', item, ev)
          },
          // mouseenter : ev =>{
          //   console.log("mouseOVerIcon", ev)
          //   if (ev.target.childNodes[0])
          //     ev.target.childNodes[0].style.visibility = "visible"
          // },
          // mouseleave : ev =>{
          //   console.log("mouseoutIcon")
          //   if (ev.target.childNodes[0])
          //     ev.target.childNodes[0].style.visibility = "hidden"
          // }
        },
      }, icon)
    },
    genItemLeftIcon (item) {
      if (!this.checkbox) return

      let icon = ''

      if (
        (
          (this.isSelected(item) && !this.isThereSelectedChilds(item)) ||
          (
            this.getParent(item) &&
            this.getParents(item).some(parent => {
              return this.isSelected(parent) &&
                !this.isThereSelectedChilds(parent)
            })
          )
        )
      ) {
        icon = '$icons_square-chechmark-outline'
      } else {
        icon = this.isThereSelectedChilds(item)
          ? '$icons_square-minus'
          : '$icons_square-outline'
      }

      return this.$createElement(VIcon, {
        staticClass: 'icon',
        props: {
          size: '21',
          color: this.isSelected(item) ? 'primary-base' : 'neutral-500',
        },
        on: {
          click: ev => {
            this.clickedSelect(item)
            this.$emit('clickCheckbox', item, ev)
          },
        },
      }, icon)
    },
    genItemRightIcon (item) {
      if (!item[this.childKey].length) return []

      const icon = this.isOpen(item) ? '$iconify_feather-minus' : '$iconify_feather-plus'
      return this.$createElement(VIcon, {
        props: {
          size: 15,
          color: 'neutral-500',
        },
      }, icon)
    },
    clickedOpen (item, mode = 'default') {
      let open = deepClone(this.itemStates.open)
      if ((this.isOpen(item) && mode === 'default') || mode === 'remove') {
        open = this.deleteItemInArr(open, item)
      } else if (!this.isOpen(item) && (mode === 'default' || mode === 'add')) {
        open = this.pushItemInArr(open, item)
      }
      this.itemStates.open = open
    },
    clickedSelect (item) {
      if (this.isSelected(item)) {
        this.takeOffItem(item)
      } else {
        this.selectItem(item)
      }
    },
    selectItem (item) {
      let selected = deepClone(this.itemStates.selected)

      if (this.isThereSelectedChilds(item)) {
        selected = this.deleteItemInArr(selected, item, ...this.getRecursiveChilds(item))
      } else {
        selected = this.pushItemInArr(selected, item)
      }

      const parents = this.getParents(item);

      [item].concat(parents).forEach(parent => {
        selected = this._parentOperations(selected, parent)
      })
      console.groupEnd()
      this.itemStates.selected = selected
    },
    takeOffItem (item) {
      let selected = deepClone(this.itemStates.selected)
      const parent = this.getParent(item)
      const parentChilds = this.getChilds(parent)

      if (parent && parentChilds.length === 1) {
        selected = this.deleteItemInArr(selected, parent, item)
      } else {
        selected = this.deleteItemInArr(
          selected,
          item,
          ...this.getRecursiveChilds(item),
        )
      }
      console.groupEnd()

      this.itemStates.selected = selected
    },
    _parentOperations (selected, item) {
      let cSelected = deepClone(selected)
      const parent = this.getParent(item)

      if (parent) {
        const parentChilds = this.getChilds(parent)

        if (this.isSelected(parent)) {
          cSelected = this.deleteItemInArr(cSelected, parent, item)

          parentChilds.forEach(child => {
            if (child[this.itemValue] !== item[this.itemValue]) {
              cSelected = this.pushItemInArr(cSelected, child)
            }
          })
        } else {
          if (
            parentChilds.every(child => {
              return cSelected.some(s => s[this.itemValue] === child[this.itemValue])
            })
          ) {
            cSelected = this.pushItemInArr(cSelected, parent)
            cSelected = this.deleteItemInArr(cSelected, ...parentChilds)
          }
        }
      }
      return cSelected
    },
    selectByString (str) {
      if (typeof str === 'string') {
        str = str.replace(/^\s*/, '')
                    .replace(/\s*$/, '')
      }

      const stateOpen = []
      const stateSelected = []

      if (str && str.length) {
        let found = []
        this.items.forEach(item => {
          found = found.concat(this.searchChildsByStr(item, str))
        })

        found.forEach(f => {
          this.getParents(f).forEach(o => {
            if (!this.isFind(stateOpen, o)) stateOpen.push(o)
            if (!this.isFind(stateSelected, o)) {
              stateSelected.push(o)
            }
          })

          stateOpen.push(f)
          stateSelected.push(f)
        })
      }

      this.$set(this.itemStates, 'open', [...stateOpen])
      this.$set(this.itemStates, 'selected', [...stateSelected])
    },
    getParents (item) {
      const parents = []

      const openIndex = this.itemsLinear.findIndex(el => el[this.itemValue] === item[this.itemValue])
      let parent = this.itemsLinear[openIndex]

      while (Object.prototype.hasOwnProperty.call(parent, 'parent_val')) {
        const parentIndex = this.itemsLinear.findIndex(el => el[this.itemValue] === parent.parent_val)
        parent = this.itemsLinear[parentIndex]
        parents.push(parent)
      }

      return parents
    },
    getParent (item) {
      const itemWithParentVal = this.itemsLinear.find(el => {
        return el[this.itemValue] === item[this.itemValue]
      })
      if (itemWithParentVal) {
        return this.itemsLinear.find(el => {
          return el[this.itemValue] === itemWithParentVal.parent_val
        })
      }
      return null
    },
    pushItemInArr (arr, item) {
      if (!item) return arr
      const cloneArr = deepClone(arr)
      if (!this.isFind(cloneArr, item)) cloneArr.push(item)
      return cloneArr
    },
    deleteItemInArr (arr, ...items) {
      if (!items.length) return arr
      let cloneArr = deepClone(arr)
      items.forEach(el => {
        if (this.isFind(cloneArr, el)) {
          cloneArr = cloneArr.filter(arrEl => {
            return arrEl[this.itemValue] !== el[this.itemValue]
          })
        }
      })
      return cloneArr
    },
    getRecursiveChilds (item) {
      const childrens = this.getChilds(item)
      let selectedChilds = [].concat(childrens)
      childrens.forEach(child => {
        selectedChilds = selectedChilds.concat(this.getRecursiveChilds(child))
      })
      return selectedChilds
    },
    getChilds (item) {
      if (!item || (item && !item[this.childKey].length)) return []
      return deepClone(item[this.childKey])
    },
    searchChildsByStr (item, str) {
      if (!item || !str.length) return []

      const childrens = item[this.childKey]
      let found = []

      if (
        item[this.itemText]
        .toLowerCase()
        .indexOf(str.toLowerCase()) !== -1
      ) {
        found.push(item)
      }

      childrens.forEach(child => {
        if (
          child[this.itemText]
            .toLowerCase()
            .indexOf(str.toLowerCase()) !== -1
        ) {
          found.push(child)
        }

        if (child[this.childKey].length) {
          found = found.concat(this.searchChildsByStr(child, str))
        }
      })
      return found
    },
    clickedItem (item, ev) {
      this.clickedOpen(item, 'add')
      this.$emit('clickItem', item, ev)
      this.itemStates.clickItem = item
    },
    isThereSelectedChilds (item) {
      if (item && item[this.childKey].length) {
        const childs = this.getRecursiveChilds(item)
        return childs.some(child => this.isSelected(child))
      }
      return false
    },
    isClickItem (item) {
      return this.itemStates.clickItem[this.itemValue] === item[this.itemValue]
    },
    isSelected (item) {
      return this.isFind(this.itemStates.selected, item)
    },
    isOpen (item) {
      return this.isFind(this.itemStates.open, item)
    },
    isFind (arr, item) {
      return arr.findIndex(el => el[this.itemValue] === item[this.itemValue]) !== -1
    },
  },
}
