import { VRow, VCol, VBtn, VIcon } from 'vuetify/lib/components'
import { getSlot } from 'vuetify/lib/util/helpers'

export default {
  methods: {
    genFooter (data) {
      return this.$createElement('div', { staticClass: 'footer__content' }, [
        this.$createElement(VRow, { props: { noGutters: true } }, [this.genFooterActions(data)]),
      ])
    },
    genFooterActions (data) {
      const vNodes = []

      if (!this.singleDatePicker) {
        vNodes.push(
          this.$createElement(VCol, {}, [
            getSlot(this, 'footer.left', data),
          ]),
        )
      }
      vNodes.push(
        this.$createElement(VCol, {}, [
          this.$createElement('div', { staticClass: 'actions' }, [
            this.$createElement(VBtn, {
              staticClass: 'btn-cancel',
              props: {
                color: 'primary-100',
              },
              on: {
                click: ev => this.$emit('clickCancel', ev),
              },
            }, [
              this.$createElement('p', { staticClass: 'btn-cancel__text mb-0 body-m-semibold primary-base--text' }, this.defaultLocaleData.cancelLabel),
            ]),
            this.$createElement(VBtn, {
              staticClass: 'btn-apply',
              props: {
                color: 'primary',
              },
              on: {
                click: ev => this.$emit('clickApply', ev),
              },
            }, [
              this.$createElement(VIcon, {
                staticClass: 'icon neutral-100--text',
                props: {
                  color: 'neutral-100',
                  size: 21,
                },
                }, '$iconify_ion-checkmark-circle-outline'),
              this.$createElement('p', { staticClass: 'btn-apply__text mb-0 body-m-semibold neutral-100--text' }, this.defaultLocaleData.applyLabel),
            ]),
          ]),
        ]),
      )
      return vNodes
    },
  },
}
