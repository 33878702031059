import ApiService from '@/api/api-client'

export default {
    namespaced: true,
    state: {
        name: 'widget_clients',
        loading: false,
        allUsers : true,
        segment_id : 0,
        widgetData: [],
        emptyData : {
            active : [
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
            ],
            new : [
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
            ],
            total : [
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
                {count : 0, date_end: "0", date_start:"0"},
            ],
        },

    },
    mutations: {
        clearState (state) {
            state.loading = false
            state.widgetData = state.emptyData
        },
        loading (state, payload) {
            state.loading = payload
        },
        widgetData (state, payload) {
            state.widgetData = payload
        },
        segment(state, payload){
            state.segment_id = payload.segment_id;
            console.log('save widget segment ' + state.name + '_' + payload.program_id, state.segment_id)
            localStorage.setItem(state.name +'_' + payload.program_id , state.segment_id)
        }
    },
    actions: {
        async widget ({ commit }, widget) {
            commit('loading', true)
            console.log("updateWidget",widget)
            const result = await ApiService.post('/api-cabinet/widget/dashboard/clients/count', widget)
            console.log("updateWidget result",result)
            commit('widgetData', result)
            commit('loading', false)
        },
        setDefault({commit}){
            commit('clearState')
        },
        saveSegment({commit}, payload){
            commit('segment', payload)
        },
        clearSegment({commit}, program_id){
            commit('segment', {segment_id : 0, program_id})
        }
    },
    getters: {
        loading (state) {
            return state.loading
        },
        widgetData (state) {
            const newData = []
            const totalData = []
            const activeData = []
            if (state.widgetData.new) {
                state.widgetData.new.forEach(item => {
                    newData.push({
                        count: item.count,
                        date_start: item.date_start,
                        date_end: item.date_end,
                    })
                })
            }
            if (state.widgetData.total) {
                state.widgetData.total.forEach(item => {
                    totalData.push({
                        count: item.count,
                        date_start: item.date_start,
                        date_end: item.date_end,
                    })
                })
            }
            if (state.widgetData.active) {
                state.widgetData.active.forEach(item => {
                    activeData.push({
                        count: item.count,
                        date_start: item.date_start,
                        date_end: item.date_end,
                    })
                })
            }
            return [
                newData.reverse(),
                totalData.reverse(),
                activeData.reverse(),
            ]
        },
        segment: state => program_id => {
            console.log('get widget segment ' + state.name + '_' + program_id, JSON.parse(localStorage.getItem(state.name + '_'+ program_id)))
            if (localStorage.getItem(state.name + '_'+ program_id) !== null && localStorage.getItem(state.name + '_'+ program_id) !== undefined)
                return JSON.parse(localStorage.getItem(state.name + '_'+ program_id))
            return state.segment_id
        }
    },
}
