<template>
  <v-navigation-drawer
    v-model="dialog"
    v-bind="$attrs"
    class="base-drawer-dialog"
    fixed
    right
    temporary
    :stateless="stateless"
    :width="isExpand ? draggableMoveWidth : width"
    :style="`min-width: ${width}px;` + `width: ` + isExpand ? draggableMoveWidth : width + 'px!important'"
  >
    <template v-slot:prepend>
      <div
        v-if="expand"
        class="resizeble-line"
        @mousedown="startMove($event)"
      />
      <v-row
        no-gutters
        class="dialog-header"
        align="center"
      >
        <v-col>
          <v-btn
            text
            color="primary"
            @click="close()"
          >
            <v-icon left>
              $iconify_arrow-left
            </v-icon>  Назад
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <slot name="action" />
        </v-col>
      </v-row>
      <v-row
        v-if="$slots.prepend"
        class="dialog-prepend-row"
      >
        <v-col> <slot name="prepend" />  </v-col>
      </v-row>
    </template>

    <v-container class="dialog-body">
      <v-row class="dialog-body_title">
        <v-col
          v-if="title"
          class="title-m-bold"
        >
          {{ title }}
        </v-col>
        <v-col v-else-if="$slots.title">
          <slot name="title" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>

  import dialogable from '@/mixins/dialogable.js'

  export default {
    components: {},
    mixins: [dialogable],
    props: {
      width: {
        type: [Number, String],
        default: 500,
      },
      title: {
        type: String,
        default: '',
      },
      stateless: Boolean,
      expand: Boolean,
    },
    data () {
      return {
        draggable: false,
        startOffset: 0,
        draggableMoveWidth: 0,
      }
    },
    computed: {
      isExpand () {
        return this.expand && this.draggableMoveWidth > this.width
      },
    },
    mounted () {
      window.addEventListener('keyup', this.onKeyUp)
      if (this.expand) {
        document.addEventListener('mouseup', this.stopMove)
        document.addEventListener('mousemove', this.move)
      }
    },
    beforeDestroy () {
      window.removeEventListener('keyup', this.onKeyUp)
      if (this.expand) {
        document.removeEventListener('mouseup', this.stopMove)
        document.removeEventListener('mousemove', this.stopMove)
      }
    },
    methods: {
      onKeyUp (e) {
        console.log('onKeyUp', e)
        if (e.key === 'Escape') {
          this.close()
        }
      },
      move (e) {
        if (this.draggable) {
          e.preventDefault()
          const newWidth = window.innerWidth - e.pageX
          this.draggableMoveWidth = newWidth
          // console.log('move', this.draggableMoveWidth)
        }
      },
      startMove (e) {
        this.draggable = true
        this.startOffset = this.width + e.pageX
      },
      stopMove (e) {
        e.stopPropagation()
        this.draggable = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.resizeble-line {
  height: 100%;
  width: 8px;
  position: absolute;
  cursor: w-resize;
}
.dialog-header{
  padding: 34px;
}
.dialog-prepend-row{
  padding-left: 34px;
  padding-right: 34px;
  margin-bottom: 10px;
}
.dialog-body {
  padding: 0px 34px 34px 34px;

}

</style>
