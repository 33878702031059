import ApiService from '@/api/api-client'


export default {
    namespaced: true,
    state: {
        loading: false,
        periodsUpdate : {
            client : true,
            nomen : true,
            shops : true
        },
        name: {
            base : 'widget_table',
            clients : 'clients',
            nomenclature : 'nomen',
            shops : 'shops'
        },
        clientSegmentIds : [],
        nomenclatureSegmentIds : [],
        shopSegmentIds : [],
        allUsers : true,
        segment_id : 0,
        widgetData: [],
        clientSegments : [],
        nomenclatureSegments : [],
        shops : [],
        emptyData : [{
            segmentName : "Выберите сегмент",
            shopName : "Выберите торговую точку",
            bgColor : "#B5B5C4",
            clientCount : 0, 
            clientActive : 0,
            salesSum : 0,
            salesCount : 0,
            avgCheck : 0,
            salesFreq : 0,
            clientNew : 0,
            bonusCredit: 0,
            bonusDebit: 0,
            bonusExpire: 0,
            bonusCreditSum: 0,
            bonusDebitSum: 0,
            bonusExpireSum: 0,
            bonusPercent : 0,
            itemsAll : 0,
            itemsCount : 0,
            income : 0,
            avgIncome : 0,
            avgCountInCheck : 0,
            avgDiscount : 0,
            itemsFreq : 0,
            itemsClientCount : 0,
            shopSalesCount : 0,
            shopIncome : 0,
            shopAvgIncome : 0,
            shopAvgCheck : 0,
            shopBonusCredit : 0,
            shopBonusDebit : 0,
            shopBonusExpire : 0,
            shopBonusCreditSum: 0,
            shopBonusDebitSum: 0,
            shopBonusExpireSum: 0,
            shopBonusPercent : 0,
            shopActivePromo : 0,
        }]
    },
    mutations: {
        clearState (state) {
            state.loading = false
            state.widgetData = state.emptyData
        },
        loading (state, payload) {
            state.loading = payload
        },
        widgetData (state, payload) {
            state.widgetData = payload
        },
        clientSegments (state, payload){
            state.clientSegments = payload
        },
        nomenclatureSegments(state, payload){
            state.nomenclatureSegments = payload
        },
        shops(state, payload){
            state.shops = payload
        },
        segment(state, payload){
            const saveArray = JSON.stringify(payload.segments_ids)
            console.log("SAVE ARRAY, payload.segments", saveArray, payload.segments_ids);
            if (payload.tab === 0){
                state.clientSegmentIds = payload.segments_ids
                console.log('save widget segment ' + state.name.base + '_' + state.name.clients + '_' + payload.program_id, state.clientSegmentIds)
                localStorage.setItem(state.name.base + '_' + state.name.clients + '_' + payload.program_id, saveArray)
            }
            if (payload.tab === 1){
                state.nomenclatureSegmentIds = payload.segments_ids
                console.log('save widget segment ' + state.name.base + '_' + state.name.nomenclature + '_' + payload.program_id, state.nomenclatureSegmentIds)
                localStorage.setItem(state.name.base + '_' + state.name.nomenclature + '_' + payload.program_id, saveArray)
            }
            if (payload.tab === 2 && payload.segments_ids !== undefined){
                state.shopSegmentIds = payload.segments_ids
                console.log('save widget segment ' + state.name.base + '_' + state.name.shops + '_' + payload.program_id, state.shopSegmentIds)
                localStorage.setItem(state.name.base + '_' + state.name.shops + '_' + payload.program_id, saveArray)
            }
        },
        updatePeriod(state, currentTab){
            console.log("TABLE UPDATED PERIOD");
            if (currentTab === 0){
                state.periodsUpdate.client = true
            }
            if (currentTab === 1){
                state.periodsUpdate.nomen = true
            }
            if (currentTab === 2){
                state.periodsUpdate.shops = true
            }
        },
        clearPeriod(state){
            state.periodsUpdate.client = false
            state.periodsUpdate.nomen = false
            state.periodsUpdate.shops = false
        }
    },
    actions: {
        async tabClient ({ commit }, data) {
            commit('loading', true)
            console.log("updateWidget TabClient",data)
            const result = await ApiService.post('/api-cabinet/widget/dashboard/table/clients', data)
            console.log("updateWidget TabClient result",result.segments)
            commit('clientSegments', result.segments)
            commit('segment', {program_id : data.program_id, segments_ids: data.segments_ids, tab : 0})
            commit('updatePeriod', 0)
            commit('loading', false)
        },
        async tabNomenclatures({commit}, data){
            commit('loading', true)
            console.log("updateWidget TabNomenclatures", data)
            const result = await ApiService.post('/api-cabinet/widget/dashboard/table/nomenclatures', data)
            console.log("updateWidget TabNomenclatures result",result.segments)
            commit('nomenclatureSegments', result.segments)
            commit('segment', {program_id : data.program_id, segments_ids: data.segments_ids, tab : 1})
            commit('updatePeriod', 1)
            commit('loading', false)
        },
        async tabShops({commit}, data){
            commit('loading', true)
            console.log("updateWidget TabShops", data)
            const result = await ApiService.post('/api-cabinet/widget/dashboard/table/shops', data)
            console.log("updateWidget TabShops result",result.shops)
            commit('shops', result.shops)
            commit('segment', {program_id : data.program_id, segments_ids: data.segments_ids, tab : 2})
            commit('updatePeriod', 2)
            commit('loading', false)
        },
        saveSegment({commit}, payload){
            commit('segment', {program_id : payload.program_id, segments_ids: payload.segments_ids, tab : payload.tab})
        },
        clearSegment({commit}, program_id){
            commit('segment', {segment_id : [], program_id})
        },
        updatePeriod({commit}, tab){
            commit('updatePeriod', tab)
        },
        clearPeriod({commit}){
            commit('clearPeriod')
        }

    },
    getters: {
        loading (state) {
            return state.loading
        },
        widgetDataClients (state) {
            let data = []
            console.log("TABLE widgetData", state.clientSegments)
            state.clientSegments.forEach((el, idx)=>{
                data.push({
                    segmentName : el.name, 
                    clientCount : el.quantity_all_clients, 
                    clientActive : el.quantity_active_clients,
                    salesSum : el.sum_sales_segment / 100,
                    salesCount : el.quantity_sales_segment,
                    avgCheck : el.quantity_sales_segment > 0 ? (el.sum_sales_segment / 100 / el.quantity_sales_segment) : 0,
                    salesFreq : el.quantity_active_clients > 0 ? el.quantity_sales_segment / el.quantity_active_clients : 0,
                    clientNew : el.quantity_new_clients,
                    bgColor: el.color,
                    bonusCredit: el.credit_bonus[0].sum ? el.credit_bonus[0].sum : 0,
                    bonusDebit: el.debit_bonus[0].sum ? -el.debit_bonus[0].sum : 0,
                    bonusExpire: el.expired_bonus[0].sum ? el.expired_bonus[0].sum : 0,
                    bonusCreditSum: el.sum_sales_segment > 0 ? (el.credit_bonus[0].sum / (el.sum_sales_segment / 100)) * 100 : 0,
                    bonusDebitSum: el.sum_sales_segment > 0 ? (-el.debit_bonus[0].sum / (el.sum_sales_segment / 100)) * 100 : 0,
                    bonusExpireSum: el.sum_sales_segment > 0 ? (el.expired_bonus[0].sum / (el.sum_sales_segment / 100)) * 100 : 0,
                    bonusPercent : el.credit_bonus[0].sum ? (-el.debit_bonus[0].sum / el.credit_bonus[0].sum) * 100 : 0
                })
            })
            return data
        },
        widgetDataNomenclature (state) {
            let data = []
            console.log("TABLE widgetData", state.nomenclatureSegments)
            state.nomenclatureSegments.forEach((el, idx) =>{
                data.push({
                    segmentName : el.name,
                    bgColor : el.color,
                    id : el.id,
                    itemsAll : 0,
                    itemsCount : el.quantity_sales_nomenclatures,
                    income : el.sum_sales_nomenclatures / 100,
                    avgIncome : el.quantity_sales_nomenclatures ? (el.sum_sales_nomenclatures / 100) / el.quantity_sales_nomenclatures : 0,
                    avgCountInCheck : el.average_quantity_nomenclatures_in_check,
                    avgDiscount : el.average_discount / 100,
                    itemsFreq : el.frequency_sales_nomenclatures,
                    itemsClientCount : el.quantity_unique_clients,
                })
            })
            return data
        },
        widgetDataShops(state){
            let data = []
            console.log("TABLE widgetData", state.shops)
            state.shops.forEach((el, idx)=>{
                data.push({
                    id : el.id,
                    shopName : el.name,
                    shopSalesCount : el.quantity_sales,
                    shopIncome : el.sum_sales_in_shop / 100,
                    shopAvgIncome : el.quantity_sales ? (el.sum_sales_in_shop / 100) / el.quantity_sales : 0,
                    shopAvgCheck : el.quantity_sales_nomenclatures ? (el.sum_sales_in_shop / 100) / el.quantity_sales_nomenclatures : 0,
                    shopBonusCredit : el.bonus_credits,
                    shopBonusDebit : -el.bonus_debits,
                    shopBonusExpire : el.bonus_expire,
                    shopBonusCreditSum: el.sum_sales_in_shop > 0 ? (el.bonus_credits / (el.sum_sales_in_shop / 100)) * 100 : 0,
                    shopBonusDebitSum: el.sum_sales_in_shop > 0 ? (-el.bonus_debits/ (el.sum_sales_in_shop / 100)) * 100 : 0,
                    shopBonusExpireSum: el.sum_sales_in_shop > 0 ? (el.bonus_expire / (el.sum_sales_in_shop / 100)) * 100 : 0,
                    shopBonusPercent : el.bonus_credits ? (-el.bonus_debits / el.bonus_credits) * 100 : 0,
                    shopActivePromo : el.quantity_promotion,
                })
            })
            return data
        },
        emptyData(state){
            return state.emptyData
        },
        segment: state => (program_id, tab) => {
            console.log('get segments table', program_id, tab);
            if (tab === 0){
                if (localStorage.getItem(state.name.base + '_' + state.name.clients + '_' + program_id) !== null && 
                    localStorage.getItem(state.name.base + '_' + state.name.clients + '_' + program_id) !== undefined)
                    
                return JSON.parse(localStorage.getItem(state.name.base + '_' + state.name.clients + '_' + program_id))
            }
            if (tab === 1){
                if (localStorage.getItem(state.name.base + '_' + state.name.nomenclature + '_' + program_id) !== null && 
                    localStorage.getItem(state.name.base + '_' + state.name.nomenclature + '_' + program_id) !== undefined)
                    
                return JSON.parse(localStorage.getItem(state.name.base + '_' + state.name.nomenclature + '_' + program_id))
            }
            if (tab === 2){
                if (localStorage.getItem(state.name.base + '_' + state.name.shops + '_' + program_id) !== null && 
                    localStorage.getItem(state.name.base + '_' + state.name.shops + '_' + program_id) !== undefined)
                
                return JSON.parse(localStorage.getItem(state.name.base + '_' + state.name.shops + '_' + program_id))
            }

            return []
        },
        period: state => tab => {
            if (tab === 0)
                return state.periodsUpdate.client
            if (tab === 1)
                return state.periodsUpdate.nomen 
            if (tab === 2)
                return state.periodsUpdate.shops
        }
    },
}
