<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <base-text-field
        v-model="dateFormatted"
        persistent-hint
        placeholder="дд.мм.гггг"
        v-bind="attrs"
        @blur="valueMutation = parseDate(dateFormatted)"
        v-on="on"
        :rules="rules"
        :readonly="readonly"
        clearable
      ></base-text-field>
    </template>
    <v-date-picker
      v-model="valueMutation"
      no-title
      locale="ru-ru"
      @input="menu = false"
      first-day-of-week="1"
      :readonly="readonly"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePickerField",
  props: {
    value: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "update",
  },
  computed: {
    valueMutation: {
      get: function () {
        return this.value;
      },
      set: function (v) {
        this.$emit("update", v);
      },
    },
  },
  watch: {
    valueMutation(val) {
      this.dateFormatted = this.formatDate(this.valueMutation);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  data() {
    return {
      menu: false,
      dateFormatted: "",
    };
  },
};
</script>
