import * as yup from 'yup'
import i18n from '@/i18n'
import { validPhone } from '@/utils/validate'

function formatMessage (key) {
    const regex = /^(\[(?<index>\d+)\]\.)?(?<field>\w+)$/

    return (error) => {
        const match = regex.exec(error.path)
        // if use .label() - path == label
        console.log('formatMessage', error, match)
        let attribute = error.label
        if (!attribute && match?.groups?.field) {
            const pathField = match?.groups?.field
            const attributeKey = 'attributes.' + pathField
            attribute = (i18n.te(attributeKey) ? i18n.t(attributeKey) : pathField)
        }

        const errorMessage = i18n.t('validation.' + key, { attribute, ...error })
        if (match && match.groups && match.groups.index) {
            const rowNum = ++match.groups.index
            return `Строка №${rowNum}: ${errorMessage}`
        } else {
            return errorMessage
        }
    }
}

yup.setLocale({
    // use constant translation keys for messages without values
    mixed: {
      default: 'field_invalid',
      required: formatMessage('required'), // ({ label, path }) => i18n.t('validation.required', { attribute: label || path }),
      oneOf: formatMessage('oneOf'),
      isDate: formatMessage('date'),

    },
    string: {
        email: formatMessage('email'),
        max: formatMessage('max.string'),
        min: formatMessage('min.string'),
        isDate: formatMessage('date'),
    },
    number: {
        integer: formatMessage('integer'),
    },
    date: {
        default: formatMessage('date'),
    },
    // use functions to generate an error object that includes the value from the schema
    // number: {
    //   min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
    //   max: ({ max }) => ({ key: 'field_too_big', values: { max } }),
    // },

  })

  yup.addMethod(yup.string, 'isDate', function (customMessage) {
    return this.test('isDate', customMessage, function (value) {
        const { path, createError } = this
        const message = customMessage || formatMessage('date')({ path })
        return yup.date().isValidSync(value) || createError({ message })
    })
})

  yup.addMethod(yup.string, 'isPhone', function (customMessage) {
    return this.test('isDate', customMessage, function (value) {
        const { path, createError } = this
        const message = customMessage || formatMessage('phone')({ path })
        return validPhone(value) || createError({ message })
    })
})

yup.addMethod(yup.mixed, 'fails', function (data) {
    if (Array.isArray(data)) {
       return data.map((item, index) => {
            try {
                this.validateSync(item)
                return []
              } catch (error) {
                return error.errors.map((msg) => i18n.t('row_num', { num: index + 1 }) + `: ${msg}`)
              }
        }).flat()
    } else {
        try {
            this.validateSync(data)
          } catch (error) {
            return error.errors
          }
    }
    return []
})

// Yup.addMethod(Yup.mixed, 'isDate', function (yourArgs) {
//     return this.test('isDate', message, function (value) {
//       // ...
//       this.date().isValidSync(value);

//       new Promise((resolve, reject) => {
//         // ... test logic
//         if (testHasFailed) {
//             reject(this.createError({ path, message }));
//         }
//         resolve(true);
//     });
//     });
//   });

//   new Promise((resolve, reject) => {
//     // ... test logic
//     if (testHasFailed) {
//         reject(this.createError({ path, message }));
//     }
//     resolve(true);
// });
