<template>
  <div :class="{ fullscreen }">
    <v-row class="control-panel w-100">
      <v-col>
        <v-btn-toggle
          v-model="internalMode"
          class="btn-toggle__mode"
          rounded
          mandatory
        >
          <v-btn :color="isMode('CONSTRUCT') ? 'primary neutral-100--text' : 'primary-100 primary-base--text'">
            Конструктор
          </v-btn>
          <v-btn :color="isMode('HTML') ? 'primary neutral-100--text' : 'primary-100 primary-base--text'">
            HTML
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col class="d-flex align-center justify-end pa-0">
        <slot name="append-action" />
        <v-btn
          v-if="isMode('HTML')"
          class="btn-import-of-construct"
          color="secondary"
          @click="clickImportOfConstruct"
        >
          Импорт из конструктора
        </v-btn>
        <v-btn
          v-if="isMode('HTML')"
          class="btn-import-of-file"
          color="secondary"
          @click="$refs.prisminput.click()"
        >
          Импорт из файла
        </v-btn>
        <v-btn
          v-if="isMode('CONSTRUCT')"
          class="btn-import"
          color="secondary"
          @click="$refs.emailinput.click()"
        >
          Импорт
        </v-btn>
        <v-btn
          v-if="isMode('HTML') || isMode('CONSTRUCT')"
          class="btn-export"
          color="secondary"
          @click="clickExport"
        >
          Экспорт
        </v-btn>
        <slot name="action" />
        <v-btn
          class="btn-fullscreen"
          color="primary-100"
          @click="fullscreen = !fullscreen"
        >
          <v-icon>{{ '$iconify_mdi-fullscreen' + (fullscreen ? '-exit' : '') }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <email-editor
      v-show="isMode('CONSTRUCT')"
      ref="emailEditor"
      class="email-editor"
      :appearance="appearance"
      :min-height="minHeight"
      :project-id="projectId"
      :locale="locale"
      :tools="tools"
      :options="options"
      @load="loadEmailEditor"
    />
    <input
      ref="emailinput"
      type="file"
      class="d-none"
      accept=".json"
      @change="changeEmailInput"
    >
    <form
      v-show="isMode('HTML')"
      ref="fileform"
      class="prism-form"
      :style="`min-height: ${minHeight}`"
    >
      <prism-editor
        :value="prismText"
        class="prism-editor"
        :highlight="highlighter"
        line-numbers
        @input="updatePrismText"
      />
      <input
        ref="prisminput"
        type="file"
        class="d-none"
        accept=".txt,.html,.htm"
        @change="changePrismInput"
      >
    </form>
    <v-alert
      v-show="!validate[0]"
      type="error"
      dense
      text
    >
      {{ validate[1] }}
    </v-alert>
  </div>
</template>

<script>
  // components
  import { EmailEditor } from 'vue-email-editor'
  import { PrismEditor } from 'vue-prism-editor'

  // utils
  import { trimLR } from '@/utils'

  // styles
  import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

  // import highlighting library (you can use any library you want just return html string)
  import { highlight, languages } from 'prismjs/components/prism-core'
  import 'prismjs/components/prism-markup'
  import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

  export default {
    components: {
      EmailEditor,
      PrismEditor,
    },
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      mode: {
        type: Number,
        validator: function (val) {
          return [0, 1].includes(val)
        },
        default: 0,
      },
      value: {
        type: Object,
        default: () => {
          return {}
        },
      },
      htmlText: {
        type: String,
        default: '',
      },
      exportFileName: {
        type: String,
        default: '',
      },
      rules: {
        type: [Array],
        default: function () {
          return []
        },
      },
      // EmailEditor
      options: {
        type: Object,
        default: () => {
          return {
            displayMode: 'email',
          }
        },
      },
      projectId: {
        type: Number,
        default: 0, // replace with your project id
      },
      tools: {
        type: Object,
        default: () => {
          return {
            // disable image tool
            image: {
              enabled: true,
            },
          }
        },
      },
      appearance: {
        type: Object,
        default: () => {
          return {
            theme: 'light',
            panels: {
              tools: {
                dock: 'right',
              },
            },
          }
        },
      },
      locale: {
        type: String,
        default: 'ru-RU',
      },
      // Other
      minHeight: {
        type: String,
        default: '700px',
      },
    },
    data () {
      return {
        fullscreen: false,
        dragAndDropCapable: false,
        oldPrismText: '',
        modes: {
          CONSTRUCT: 0,
          HTML: 1,
        },
        prismText: '',
        internalMode: 0,
        loadEditor: false,
        validate: [true, ''],
      }
    },
    watch: {
      mode (v) {
        this.internalMode = v
      },
      htmlText (v) {
        this.prismText = v
      },
      internalMode: {
        immediate: true,
        handler (v) {
          if (this.isMode('HTML')) {
            if (this.oldPrismText.length) {
              this.prismText = this.oldPrismText
              this.$emit('update:htmlText', this.oldPrismText)
            }

            this.changeModel(this.prismText)
          } else if (this.isMode('CONSTRUCT')) {
            this.changeModel()
          }

          this.$emit('update:mode', v)
        },
      },
    },
    mounted () {
      this.dragAndDropCapable = this.determineDragAndDropCapable()

      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
          this.$refs.fileform.addEventListener(evt, function (e) {
            e.preventDefault()
            e.stopPropagation()
          }, false)
        }.bind(this))

        this.$refs.fileform.addEventListener('drop', function (e) {
          if (e.dataTransfer.files.length) {
            const file = e.dataTransfer.files[0]
            if (file.type === 'text/plain' || file.type === 'text/html' || file.type === 'text/htm') {
              file.text().then((text) => {
                this.prismText = text
                this.$emit('update:htmlText', this.prismText)
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'HTML-редактор',
                text: 'Неподдерживаемый тип файла',
              })
            }
          }
        }.bind(this))
      }
    },
    methods: {
      // EmailEditor methods
      unlayerLoadDesign (design) { this.$refs.emailEditor.loadDesign(design) },
      unlayerSaveDesign (cbk) { this.$refs.emailEditor.saveDesign(cbk) },
      unlayerExport (cbk) { this.$refs.emailEditor.exportHtml(cbk) },
      loadEmailEditor () {
        this.loadEditor = true

        if (this.isMode('CONSTRUCT')) {
          this.$refs.emailEditor.editor.addEventListener('design:updated', () => {
            this.changeModel()
          })
          this.$refs.emailEditor.editor.addEventListener('design:loaded', () => {
            this.changeModel()
          })
        } else if (this.isMode('HTML')) {
          if (this.oldPrismText.length) {
            this.prismText = this.oldPrismText
            this.$emit('update:htmlText', this.oldPrismText)
          }

          this.changeModel(this.prismText)
        }

        this.$emit('loadEmailEditor')
      },
      // PrismEditor methods
      highlighter (code) {
        return highlight(code, languages.html) // languages.<insert language> to return html with markup
      },
      updatePrismText (v) {
        this.changeModel(v)
        this.prismText = this.oldPrismText = v
        this.$emit('update:htmlText', v)
      },
      clickImportOfConstruct () {
        this.unlayerExport((data) => {
          this.prismText = data.html
          this.$emit('update:htmlText', this.prismText)
        })
      },
      changePrismInput (e) {
        const file = e.target.files[0]
        file.text().then((text) => {
          console.log('this.prismText', text)
          this.prismText = text
          this.$emit('update:htmlText', this.prismText)
        })
      },
      changeEmailInput (e) {
        const file = e.target.files[0]
        file.text().then((text) => {
          try {
            if (text && text.length && this.isJsonString(text)) {
              this.unlayerLoadDesign(JSON.parse(text))
            } else {
              throw new Error('Invalid json')
            }
            this.$notify({
              type: 'success',
              title: 'Конструктор',
              text: 'Дизайн загружен',
            })
          } catch (err) {
            this.$notify({
              type: 'error',
              title: 'Конструктор',
              text: 'Неверный формат json',
            })
          }
        })
      },
      clickExport () {
        const fileName = (!this.exportFileName || !trimLR(this.exportFileName).length)
          ? 'Без названия'
          : this.exportFileName

        if (this.isMode('HTML')) {
          this.saveInFile(this.prismText, fileName + '.html')
        }

        if (this.isMode('CONSTRUCT')) {
          this.unlayerSaveDesign((design) => {
            this.saveInFile(JSON.stringify(design), fileName + '.json')
          })
        }
      },
      // Drop file methods
      determineDragAndDropCapable () {
        var div = document.createElement('div')
        return (('draggable' in div) ||
          ('ondragstart' in div && 'ondrop' in div)) &&
          'FormData' in window &&
          'FileReader' in window
      },
      // Other
      changeModel (html) {
        if (!this.loadEditor) return
        const _this = this
        this.unlayerExport((data) => {
          const ht = _this.isMode('HTML') ? html : data.html
          _this.validation(ht)
          _this.$emit('change', { html: ht, design: data.design })
        })
      },
      isMode (key) {
        return this.modes[key] === this.internalMode
      },
      validation (v) {
        let isValid = true
        let errText = ''
        this.rules.forEach(callback => {
          const check = callback(v)
          if (
            typeof check !== 'boolean' ||
            check !== true
          ) {
            isValid = false
            errText = check
          }
        })
        this.validate = [isValid, errText]
      },
      saveInFile (data, filename) {
        var a = document.createElement('a')
        var blob = new Blob([data], { type: 'octet/stream' })
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
      },
      isJsonString (str) {
        try {
          JSON.parse(str)
        } catch (e) {
          return false
        }
        return true
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '@/styles/vuetify-preset-plus/light_theme/_variables.sass';
@import '@/styles/_typography.sass';

.control-panel {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 14px;
  .btn-toggle__mode {
    & ::v-deep {
      .v-btn {
        height: 33px !important;
        min-height: 33px !important;
        &:nth-child(1) {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
        &:nth-child(2) {
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }
      }
    }
  }
  .btn-import-of-construct, .btn-import-of-file, .btn-export, .btn-import {
    margin-left: 14px;
  }
  .btn-fullscreen {
    min-width: 0;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-left: 34px;
    &.v-size--default {
      padding: 0 !important;
    }
  }
}

.email-editor {
  height: 700px;
}

.prism-form {
  height: 700px;
  .prism-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    height: 100%;
    & ::v-deep {
      .prism-editor__textarea:focus {
        outline: none;
      }
    }
  }
}

.fullscreen {
  position: fixed;
  background: $neutral-100;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  .email-editor {
    height: calc(100% - 63px);
  }
  .prism-form {
    height: calc(100% - 61px);
  }
  .control-panel {
    margin-left: 34px;
    margin-right: 34px;
  }
}
</style>
