import ApiService from '@/api/api-client'

export default {
    namespaced: true,
    state: {
        loading: false,
        tableData: [],
        totalCount: 0,
    },
    mutations: {
        clearState (state) {
            state.loading = false
            state.tableData = []
        },
        loading (state, payload) {
            state.loading = payload
        },
        tableData (state, payload) {
            state.tableData = payload
        },
        totalCount (state, payload) {
            state.totalCount = payload
        },
        REMOVE_ITEM_TABLE_DATA(state, id) {
            state.tableData = state.tableData.filter(item => item['id'] !== id)
        }
    },
    actions: {
        async table({commit}, table) {
            commit('loading', true)

            const result = await ApiService.post('/api-cabinet/program/sale/list', table)
            console.log('table data.......')
            console.log(result)
            console.log('table data.......')
            commit('tableData', result.items)
            commit('totalCount', result.total)
            commit('loading', false)
        },
        async delete({commit}, {programId, id}) {
            const result = await ApiService.delete('/api-cabinet/program/account/sales/delete', {
                params: {
                    program_id: programId,
                    id,
                    force: 1
                }
            })
            commit('REMOVE_ITEM_TABLE_DATA', id)
        },
        async exportSales({commit}, {programId, type, email, start_period, end_period}) {
            try {

                await ApiService.post(
                    '/api-cabinet/program/sales/export',
                    {
                        program_id: programId,
                        type,
                        start_period,
                        end_period,
                    })

                this._vm.$notify({
                    type: 'success',
                    title: 'Экспорт продаж',
                    text: 'Файл будет отправлен на почту ' + email,
                })
            } catch (err) {
                console.log(err)
            }
        },
    },
    getters: {
        loading (state) {
            return state.loading
        },
        tableData (state) {
            return state.tableData
        },
        totalCount (state) {
            return state.totalCount
        },
    },
}
