<template>
  <div class="tab-fields">
    <client-fields
      ref="clientFields"
      :has-changes.sync="hasChanges"
      :fields-data.sync="fieldsData"
      :loading-fields.sync="loadingFields"
      :loading-acc-fields.sync="loadingAccFields"
      :valid-form.sync="validForm"
    />
    <!-- {{ fieldsData }} -->
    <v-btn
      v-if="!loadingFields && (fields && fields.length)"
      class="btn"
      color="primary"
      :loading="loadingSave || loadingAccFields"
      :disabled="!hasChanges"
      @click="updateFields()"
    >
      <iconify-icon
        class="icon icon-success"
        icon="ion-checkmark-circle-outline"
        width="21"
      />
      <p
        class="body-m-semibold mb-0"
        :class="{'neutral-100--text': !validForm}"
      >
        Сохранить
      </p>
    </v-btn>
    <base-empty-block-page
      v-else
      class="empty-block"
      title="Здесь пока ничего нет :’("
    >
      <template v-slot:description>
        Здесь отображается дополнительные поля клиента
      </template>
      <template v-slot:image>
        <v-img
          src="@/assets/png/settings-dummy.png"
          width="186px"
          height="156px"
        />
      </template>
      <template v-slot:actions>
        <v-btn
          class="btn btn-setting align-self-center"
          color="primary"
          @click="routeSettings()"
        >
          <iconify-icon
            class="icon icon-success"
            icon="feather-settings"
            width="21"
          />
          <p
            class="body-m-semibold mb-0"
            :class="{'neutral-100--text': !validForm}"
          >
            Настроить
          </p>
        </v-btn>
      </template>
    </base-empty-block-page>
  </div>
</template>

<script>
  // other
  import { mapGetters, mapActions } from 'vuex'
  import ClientFields from '@/components/client/Fields'

  export default {
    name: 'TabFields',
    components: { ClientFields },
    props: {
      clientData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        loadingFields: false,
        loadingAccFields: false,
        loadingSave: false,
        fieldsData: {},
        hasChanges: false,
        validForm: false,
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        accountClient: 'crm/clientCard/client',
        settFields: 'settings/account-fields/fields',
      }),
      fields () {
        return this.settFields[this.programId]
      },
    },
    mounted () {
      //
    },
    methods: {
      ...mapActions({
        CreateAccountFields: 'crm/account-fields/CreateAccountFields',
      }),
      async updateFields () {
        if (this.$refs.clientFields.$refs.formFields.validate()) {
          const prepared = []

          Object.keys(this.fieldsData).forEach(key => {
            if (this.fieldsData[key] && typeof this.fieldsData[key] === 'object' && this.fieldsData[key].value) {
              prepared.push({ id: key, value: this.fieldsData[key].value })
            } else {
              prepared.push({ id: key, value: this.fieldsData[key] })
            }
          })

          console.log('updateFields', prepared)

          try {
            this.loadingSave = true
            await this.CreateAccountFields({ account_id: this.accountClient.id, fields: prepared })
            this.$notify({
              type: 'success',
              title: 'Клиенты',
              text: 'Клиент успешно обновлен',
            })
          } catch (error) {

          } finally {
            this.loadingSave = false
          }
        } else {
          this.$notify({
            type: 'error',
            title: 'Клиенты',
            text: 'Ошибки валидации',
          })
        }
      },
      routeSettings () {
        this.$router.push({ name: 'SettingsCRM' })
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '@/styles/vuetify-preset-plus/light_theme/_variables.sass';

.tab-fields {
  & ::v-deep {
    .v-text-field {
      .v-input__append-inner {
        margin-top: 10px!important;
        .v-icon {
          svg {
            color: $neutral-500;
          }
        }
        .v-input__icon--clear {
          padding-left: 23px;
        }
      }
    }
    .v-select {
      .v-input__append-inner {
        .v-input__icon--clear {
          padding-left: 10px;
          margin-top: 2px;
        }
      }
    }
    .base-date-text-field {
      .v-input__append-inner {
        .v-input__icon--clear {
          margin-top: -2px;
          margin-left: -42px;
        }
        .v-icon {
          svg {
            color: $neutral-500;
          }
        }
      }
    }
    .v-input--switch {
      display: inline-block!important;
    }
    .v-btn.v-btn--disabled {
      p {
        color: $neutral-100!important;
      }
    }
  }
  .fields__list {
    list-style: none;
    padding-left: 0;
    .fields__item {
      margin-bottom: 34px;
      text-overflow: ellipsis;
      .item-header {
        margin-bottom: 20px;
        p:first-child {
          margin-bottom: 6px;
          word-break: break-word;
        }
      }
    }
  }
  .btn {
    .icon {
      margin-right: 8px;
    }
  }
  .empty-block {
    margin-top: 34px;
    .btn-setting {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>
