<template>
  <div>
    <div
      v-if="loading"
    >
      <v-skeleton-loader
        type="list-item-avatar-two-line@3"
      />
    </div>
    <div
      v-else
    >
      <div
        v-if="transactionsStore && transactionsStore.length"
      >
        <v-expansion-panels
          v-model="panels"
          class="expansion-panel__transactions"
          multiple
        >
          <v-expansion-panel
            v-for="(category, idx) in transactionCategories"
            :key="idx"
          >
            <v-expansion-panel-header>
              <p class="body-l-semibold mb-0">
                {{ category.key }}
              </p>
              <template v-slot:actions>
                <v-icon color="neutral-700">
                  $iconify_feather-chevron-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="group in category.value"
                :key="group.key"
              >
                <div class="pls--transaction-timeline neutral-500--text">
                  {{ group.label }}
                </div>
                <div
                  v-for="(item, i) in group.items"
                  :key="i"
                  class="pls--transaction-card"
                >
                  <v-avatar
                    size="32"
                    class="pls--transaction-card-avatar"
                  >
                    <img
                      :src="item.subject.avatar"
                      alt="Иконка программы"
                      @error="e => e.target.src = $config.defaultPath.avatar404"
                    >
                  </v-avatar>
                  <div>
                    <div class="body-m-semibold">
                      {{ item.subject.name }}
                      <span class="body-m-regular neutral-500--text">в {{ getTime(item.created_at) }}</span>
                    </div>
                    <v-row
                      no-gutters
                      class="body-m-semibold"
                      :class="{ 'success-500--text': item.trans_type === 'CREDIT', 'error-500--text': item.trans_type === 'DEBIT' }"
                      align="center"
                    >
                      <span>
                        {{ getTransactionValue(item.trans_type, item.value) }}
                        <v-icon 
                          :color="getTransactionValue(item.trans_type, item.value) > 0 ? 'success' : 'error'" 
                          v-if="isUnitMain(item.bonus_unit_id)">
                          mdi-star
                        </v-icon>
                         {{ getUnitName(item.bonus_unit_id, item.value) }} 
                      </span>

                      <v-icon
                        v-if="item.is_expired"
                        color="error"
                      >
                        mdi-fire
                      </v-icon>
                    </v-row>
                  </div>
                  <v-col v-if="item.comment" class="pa-0 ml-3">
                    {{ item.comment }}
                  </v-col>                  
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div
        v-else
      >
        Операций пока нет
      </div>
    </div>
  </div>
</template>

<script>
  import ConvertorMixin from '@/mixins/convertor'
  import DateTimeFormatMixin from '@/mixins/dateTimeFormat'
  import { deepClone } from '@/utils'

  export default {
    name: 'TabOperation',
    mixins: [ConvertorMixin, DateTimeFormatMixin],
    props: {
      clientData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        loading: false,
        attrs: {
          class: 'mb-1',
          boilerplate: true,
          elevation: 0,
        },
        panels: [],
      }
    },
    computed: {
      accountClient () {
        return this.$store.getters['crm/clientCard/client']
      },
      transactionsStore () {
        return this.$store.getters['crm/clientCard/transactions']
      },
      accountExpiresScores () {
        return this.$store.getters['crm/clientCard/accountExpiresScores']
      },

      programs () {
        return this.$store.getters['company/program/programs']
      },
      expireTransactions () {
        return this.accountExpiresScores.map(item => {
          // const bonusUnit = this.$_.findWhere(this.bonusUnits, { id: item.units_id })
          const program = this.$_.findWhere(this.programs, { id: this.accountClient.program_id })
          return Object.assign({}, item, {
            is_expired: true,
            created_at: item.expire_at,
            value: Number(item.balance) ,
            trans_type: 'DEBIT',
            bonus_unit_id: item.units_id,
            subject: {
              id: program.id,
              name: program.name,
              avatar: program.logo,
            },
          })
        })
      },
      allTransactions () {
        return this.transactionsStore.concat(this.expireTransactions).map(item => {
          return Object.assign(item, {
            createdAtUnix: this.$moment(item.created_at).unix(),
          })
        }).sort((a, b) => b.createdAtUnix - a.createdAtUnix)
      },
      transactions () {
        const transactions = []
        this.allTransactions.forEach(item => {
          const key = this.$moment.utc(item.created_at).local()
          const keyFormat = key.format('YYYY-MM-DD')
          const label = key.format('dd, DD MMM YYYY')
          const unixTime = this.$moment(key).unix()
          const group = transactions.find(item => item.key === keyFormat)
          if (!group) {
            transactions.push({
              key: keyFormat,
              label,
              unixTime,
              items: [],
            })
          }
          for (let i = 0; i < transactions.length; i++) {
            if (transactions[i].key === keyFormat) {
              transactions[i].items.push(item)
            }
          }
        })
        return transactions
      },
      transactionCarried () {
        // console.log('transactionCarried', deepClone(this.transactions))
        const sort = deepClone(this.transactions).sort((a, b) => b.unixTime - a.unixTime)
        return sort.filter(trans => {
          const created = this.$moment.utc(trans.items[0].created_at).local()
          const current = this.$moment().utc().local()
          // console.log('transactionCarried', trans, trans.created_at, created.format('DD.MM.YYYY HH:MM:SS'))
          return created.diff(current, 'minutes') <= 0
        })
      },
      transactionUpcoming () {
        const sort = deepClone(this.transactions).sort((a, b) => a.unixTime - b.unixTime)
        return sort.filter(trans => {
          const created = this.$moment.utc(trans.items[0].created_at).local()
          const current = this.$moment().utc().local()
          return created.diff(current, 'minutes') > 0
        })
      },
      transactionCategories () {
        const categories = []

        if (this.transactionUpcoming.length) {
          categories.push({
            key: 'Предстоящие операции',
            value: this.transactionUpcoming,
          })
        }
        if (this.transactionCarried.length) {
          categories.push({
            key: 'Проведенные операции',
            value: this.transactionCarried,
          })
        }

        return categories
      },
      bonusUnits () {
        return this.$store.getters['company/bonus_units/bonusUnits']
      },
    },
    watch: {
      accountClient (value) {
        value && value.id && this.fetchData()
      },
    },
    async mounted () {
      this.$bus.$on('side-panel.editClient.tabOperation:fetchData', () => {
        this.fetchData()
      })
      await this.fetchData()
      this.panels = [...Object.keys(this.transactionCategories)].map((k, i) => i)
    },
    beforeDestroy () {
      this.$bus.$off('side-panel.editClient.tabOperation:fetchData')
    },
    methods: {
      getUnitName (id, value) {
        const unit = this.bonusUnits.find(item => item.id === id)
        value = value.toString()
        if (unit) {
          value = value.replace(/[+,-]/gi, '')
          value = Number(value)
          console.log('getUnitName', unit, value)
          if (!isNaN(value) &&
            unit.unit_name_ending &&
            unit.unit_name_ending.first &&
            unit.unit_name_ending.second &&
            unit.unit_name_ending.third
          ) {
            const titles = [
              unit.unit_name_ending.first,
              unit.unit_name_ending.second,
              unit.unit_name_ending.third,
            ]
            return this.declOfNum(value, titles)
          }
          return unit.name
        }
        return '-'
      },
      isUnitMain(id){
        const unit = this.bonusUnits.find(item => item.id === id)
        if (unit)
          return unit.is_main
      },
      getTransactionValue (type, value) {
        // value = value.toString()
        switch (type) {
          case 'CREDIT':
            // if (value[0] === '+') return value
            return '+' + Math.abs(value).toLocaleString()
          case 'DEBIT':
            // if (value[0] === '-') return value
            return '-' + Math.abs(value).toLocaleString()
        }
      },
      async fetchData () {
        if (this.loading || !this.accountClient) return
        try {
          this.loading = true
          await this.$store.dispatch('crm/clientCard/transactionsList', this.accountClient.id)
          await this.$store.dispatch('crm/clientCard/getAccountExpiresScores', this.accountClient.id)
          await this.$store.dispatch('company/bonus_units/loadBonusUnits', this.accountClient.program_id)
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "~@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.pls--transaction-timeline {
  font-size: 14px;
  border-bottom: 1px solid $neutral-500;
  margin: 25px 0 10px 0;
  padding-bottom: 5px;
}

.pls--transaction-card {
  display: flex;
  margin: 15px 0;
  overflow: hidden;
  .pls--transaction-card-avatar {
    margin-right: 15px;
  }
}

.expansion-panel__transactions {
  & ::v-deep {
    .v-expansion-panel {
      margin-bottom: 14px;
    }
    .v-expansion-panel-content__wrap {
      overflow: hidden;
    }
  }
}
</style>
