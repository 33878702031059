<template>
  <v-menu
    ref="menu"
    v-model="display"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="340px"
    width="340px"
  >
    <template v-slot:activator="{ on }">
      <base-text-field
        v-model="datetimeFormatted"
        v-mask="mask"
        :class="className"
        v-bind="$attrs"
        :readonly="readonly"
        error-style="tooltip"
        prepend-inner-icon="$iconify_feather-calendar"
        :rules="rules"
        @blur="inputDatetimeFormatted"
        v-on="on"
      >
        <!-- <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            />
          </slot>
        </template> -->
      </base-text-field>
      <!-- datetime = {{ JSON.stringify(datetime) }} -->
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs
          v-if="withTime"
          v-model="activeTab"
          fixed-tabs
        >
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>$iconify_feather-calendar</v-icon>
            </slot>
          </v-tab>
          <v-tab
            key="timer"
            :disabled="isDateSelected"
          >
            <slot name="timeIcon">
              <v-icon>$iconify_feather-clock</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="dateStr"
              v-bind="datePickerProps"
              full-width
              locale="ru-ru"
              first-day-of-week="1"
              :readonly="readonly"
              :max="maxDateComputed"
              @input="showTimePicker"
            />
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              v-model="timeStr"
              :use-seconds="useSeconds"
              class="v-time-picker-custom"
              v-bind="timePickerProps"
              locale="ru-ru"
              scrollable
              format="24hr"
              :readonly="readonly"
              full-width
            />
          </v-tab-item>
        </v-tabs>
        <v-date-picker
          v-else
          v-model="dateStr"
          v-bind="datePickerProps"
          full-width
          locale="ru-ru"
          first-day-of-week="1"
          :readonly="readonly"
          :max="maxDateComputed"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <slot
          name="actions"
          :parent="this"
        >
          <v-btn
            color="secondary"
            @click="display = false"
          >
            {{ cancelText }}
          </v-btn>
          <v-btn
            v-if="!readonly"
            color="primary"
            @click="okHandler"
          >
            {{ okText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
  // import { format, parse } from 'date-fns'
  import moment from 'moment'
  import { mask } from 'vue-the-mask'

  const DEFAULT_DATE = ''
  const DEFAULT_TIME = '00:00:00'
  const DEFAULT_DISPLAY_FORMAT_WITHOUT_TIME = 'DD.MM.YYYY'
  const DEFAULT_DISPLAY_FORMAT_WITHTIME = 'DD.MM.YYYY HH:mm'
  const DEFAULT_DISPLAY_FORMAT_WITHTIME_SECONDS = 'DD.MM.YYYY HH:mm:ss'

  const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
  const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
  const DEFAULT_CANCEL_TEXT = 'Отмена'
  const DEFAULT_OK_TEXT = 'OK'

  export default {
    name: 'DatetimePicker',
    directives: { mask },
    model: {
      prop: 'datetime',
      event: 'input',
    },
    props: {
      className: {
        type: String,
        default: '',
      },
      datetime: {
        type: [Date, String],
        default: null,
      },
      withTime: {
        type: Boolean,
        default: true,
      },
      useSeconds: {
        type: Boolean,
        default: true,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      displayFormat: {
        type: String,
        default: undefined,
      },
      maxDate: {
        type: [Date, String],
        default: undefined,
      },
      cancelText: {
        type: String,
        default: DEFAULT_CANCEL_TEXT,
      },
      okText: {
        type: String,
        default: DEFAULT_OK_TEXT,
      },
      rules: {
        type: Array,
        default: undefined,
      },
      datePickerProps: {
        type: Object,
        default: undefined,
      },
      timePickerProps: {
        type: Object,
        default: undefined,
      },
    },
    data () {
      return {
        display: false,
        activeTab: 0,
        selectedDateTime: null,
        datetimeFormatted: null,
      }
    },
    computed: {
      defaultDisplayFormat () {
        if (this.withTime) {
          return this.useSeconds ? DEFAULT_DISPLAY_FORMAT_WITHTIME_SECONDS : DEFAULT_DISPLAY_FORMAT_WITHTIME
        } else {
          return DEFAULT_DISPLAY_FORMAT_WITHOUT_TIME
        }
      },
      displayFormatComputed () {
        return this.displayFormat || this.defaultDisplayFormat
      },
      mask () {
        return this.displayFormatComputed.replace(/[DMYHhms]/g, '#')
      },
      datetimeMoment: {
        get () {
          return this.datetime ? moment(this.datetime) : null
        },
        set (val) {
          this.$emit('input', val ? val.toDate() : null)
        },
      },
      isDateSelected () {
        return !this.dateStr
      },
      dateStr: {
        get () { return this.selectedDateTime ? moment(this.selectedDateTime).format(DEFAULT_DATE_FORMAT) : DEFAULT_DATE },
        set (val) {
          if (val) {
            this.selectedDateTime = moment(`${val} ${this.timeStr}`)
          }
        },
      },
      timeStr: {
        get () { return this.selectedDateTime ? moment(this.selectedDateTime).format(DEFAULT_TIME_FORMAT) : DEFAULT_TIME },
        set (val) {
          if (val) {
            let timeStr = val
            if (timeStr.length === 5) {
              timeStr += ':00'
            }
            this.selectedDateTime = moment(`${this.dateStr} ${timeStr}`)
          }
        },
      },
      maxDateComputed () {
        return this.maxDate ? moment(this.maxDate).format(DEFAULT_DATE_FORMAT) : null
      },
    },
    watch: {
      datetimeMoment () {
        this.initTextField()
      },
      display (v) {
        v && this.initPickDialog()
      },
    },
    mounted () {
      this.initTextField()
    },
    methods: {
      initTextField () {
        this.datetimeFormatted = this.datetimeMoment?.format(this.displayFormatComputed) || null
      },
      initPickDialog () {
        this.resetPicker()
        const dialogDatetime = this.datetimeMoment || moment()

        this.selectedDateTime = dialogDatetime
      },
      inputDatetimeFormatted () {
        if (this.datetimeFormatted) {
          const inputMoment = moment(this.datetimeFormatted, this.displayFormatComputed)
          if (inputMoment && inputMoment.isValid()) {
            this.datetimeMoment = inputMoment
          } else {
            this.datetimeFormatted = this.datetimeMoment?.format(this.displayFormatComputed)
          }
        } else {
          this.datetimeMoment = null
        }
      },
      okHandler () {
        this.display = false
        this.datetimeMoment = this.selectedDateTime
      },
      resetPicker () {
        this.activeTab = 0
        if (this.$refs.timer) {
          this.$refs.timer.selectingHour = true
        }
      },
      showTimePicker () {
        this.activeTab = 1
      },
    },
  }
</script>
