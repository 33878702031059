export default {
  namespaced: true,
  state: {
    clientInfoPanel: {
      active: false,
      data: {},
    },
    showChatEditPanel: false,
  },
  mutations: {
    clientInfoPanel (state, payload) {
      state.clientInfoPanel = payload
    },
    showChatEditPanel (state, payload) {
      state.showChatEditPanel = payload
    },
  },
  actions: {
  },
  getters: {
    clientInfoPanel (state) {
      return state.clientInfoPanel
    },
    showChatEditPanel (state) {
      return state.showChatEditPanel
    },
  },
}
