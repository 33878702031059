import ApiService from '@/api/api-client'
import $moment from 'moment'

const state = {
    userAccountById: {},
    accountShortList: [],
}

const mutations = {
    userAccountById (state, payload) {
        state.userAccountById = payload
    },
    SET_ACCOUNT_SHORT_LIST (state, payload) {
        state.accountShortList = payload
    },
}

const actions = {
    async getUserAccountById ({ commit }, { userId, programId }) {
        const response = await ApiService.get(
            '/api-cabinet/account/findByUserId',
            {
                params: {
                    program_id: programId,
                    user_id: userId,
                },
            },
        )
        commit('userAccountById', response)
        return response
    },
    async getAccountShortList ({ commit }, { programId, withTrashed, search, limit, offset }) {
        const result = await ApiService.get(
            '/api-cabinet/program/account/short-list',
            {
                params: {
                    program_id: programId,
                    with_trashed: withTrashed ? 1 : 0,
                    search,
                    limit,
                    offset,
                },
            },
        )
        commit('SET_ACCOUNT_SHORT_LIST', result)
        return result
    },
    async exportAccountFields ({ commit, rootGetters }, { programId, type, email}) {
        try {
            const program = rootGetters['company/program/programs'].find(
                (p) => p.id === programId,
            )

            await ApiService.post(
                '/api-cabinet/program/account/export/async/fields',
                {
                    program_id: programId,
                    type,
                })

            this._vm.$notify({
                type: 'success',
                title: 'Экспорт клиентов',
                text: 'Файл будет отправлен на почту ' + email,
            })
        } catch (err) {
            console.log(err)
        }
    },
    async sendAccountCard ({ commit }, accountId) {
        await ApiService.post('/api-cabinet/program/account/wallet/sms/send', {
            account_id: accountId,
        })
        this._vm.$notify({
            type: 'success',
            title: 'Карта',
            text: 'Карта успешно отправлена',
        })
    },
    async sendNewPhone ({ commit }, { accountId, phone }) {
        await ApiService.post('/api-cabinet/crm/account/phone', {
            account_id: accountId,
            phone
        })
    }
}

const getters = {
    userAccountById: (state) => state.userAccountById,
    accountShortList: (state) => state.accountShortList,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
