<template>
    <div class="bonus-accounts">
        <div class="bonus-accounts__header">
            <p class="body-l-semibold">
                Карта
            </p>
        </div>

        <div class="tab-client tab-client__info">
      <ul class="info-list">
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            ID
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ clientData.id }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Номер
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ clientData.number }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Штрих-код
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ clientData.barcode }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Дата выпуска
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ this.$moment.utc(clientData.created_at).local().format(this.$config.date.DATETIME_FORMAT_MIN2)  }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Активация в PlusCards
          </p>
          <div class="right-text">
            <span class="body-m-medium neutral-700--text">{{ clientData.pluscards_activated_at ? this.$moment.utc(clientData.created_at).local().format(this.$config.date.DATETIME_FORMAT_MIN2) : "-" }}</span>
          </div>
        </li>
        <li class="info-item">
          <p class="body-s-semibold neutral-900--text">
            Регистратор
          </p>
          <div
            v-if="clientData.invite_source"
            class="right-text"
          >
            <v-row>
              <v-col
                v-if="clientData.invite_source.avatar_url"
                cols="auto"
              >
                <v-avatar size="25">
                  <img
                    :src="clientData.invite_source.avatar_url"
                    @error="$event.target.src = $config.app.fileStorage + 'users/avatars/default.png'"
                  >
                </v-avatar>
              </v-col>
              <v-col cols="auto">
                <span
                  v-if="clientData.invite_source.user_name"
                  class="body-m-medium neutral-700--text"
                >{{ clientData.invite_source.user_name }}</span>
                <span
                  v-else
                  class="body-m-medium neutral-700--text"
                > [{{ accountClient.invite_source_id }}]{{ accountClient.invite_source_type }}</span>
              </v-col>
            </v-row>
          </div>
          <div
            v-else
            class="right-text"
          >
            <span class="body-m-medium neutral-700--text">-</span>
          </div>
        </li>
      </ul>
    </div>

    </div>
</template>

<script>
export default {
    props: {
    clientData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
}
</script>

<style lang="scss">
  @import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
  @import "@/styles/_typography.sass";
  .bonus-accounts {
    margin-top: 16px;
    .bonus-accounts__header {
      margin-bottom: 8px;
    }

    p {
      margin-bottom: 0;
    }
  }
</style>
