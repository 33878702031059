import ApiService from '@/api/api-client'
import Vue from 'vue'
import { config } from '@/config'
import moment from 'moment'

import { v4 as uuidv4 } from 'uuid'

// eslint-disable-next-line no-unused-vars
const defaultCategory = Object.freeze({
    uuid: uuidv4(),
    name: 'Без категории',
})

export default {
    namespaced: true,
    state: {
        nomenclatures: [],
        categoryTree: [],
    },
    mutations: {
        SET_NOMENCLATURES (state, payload) {
            state.nomenclatures = payload
        },
        SET_CATEGORY_TREE (state, payload) {
            state.categoryTree = payload
        },
    },
    actions: {
        async SycnArrayNomenclature ({ commit }, data) {
            const result = await ApiService.post('/api-cabinet/program/nomenclature/sync', data)
            return result
        },
        async GetNomenclaruteList ({ commit }, programId) {
            const result = await ApiService.get(`/api-cabinet/program/nomenclature/list?program_id=${programId}`)
            commit('SET_NOMENCLATURES', result)
            return result
        },
        async getCategoryTree ({ commit, rootGetters }, programId = null) {
            const paramProgramId = programId || rootGetters.programId
            const result = await ApiService.get(`/api-cabinet/program/nomenclature/category/tree?program_id=${paramProgramId}`)
            commit('SET_CATEGORY_TREE', result)
            return result
        },
        async exportNomneclatureFields ({ commit }, { programId, email}) {
            try {
                await ApiService.post(
                    '/api-cabinet/program/nomenclature/export/async/fields',
                    {
                        program_id: programId
                    })

                this._vm.$notify({
                    type: 'success',
                    title: 'Экспорт номенклатуры',
                    text: 'Файл будет отправлен на почту ' + email,
                })
            } catch (err) {
                console.log(err)
            }
        }

    },
    getters: {
        nomenclatures: (state) => state.nomenclatures.map(item => {
            Vue.set(item, 'createdAtFormat', moment.utc(item.created_at).local().format(config.date.DATETIME_FORMAT))
            Vue.set(item, 'updatedAtFormat', moment.utc(item.updated_at).local().format(config.date.DATETIME_FORMAT))
            return item
        }),
        categoryTree: (state) => state.categoryTree,
    },
}
