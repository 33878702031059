module.exports = Object.freeze({
    title: 'Plus Cabinet',
    name: process.env.VUE_APP_NAME || 'pluscards-cabinet',
    version: process.env.VUE_APP_VERSION,
    build: 301, // Server-API
    env: process.env.NODE_ENV,
    apiHost: process.env.VUE_APP_BASE_API || 'https://test-backend.pluscards.ru',
    socketHost: process.env.VUE_APP_SOCKET_HOST || 'https://test-backend.pluscards.ru:6001',
    fileStorage: process.env.VUE_APP_FILE_HOST || 'https://storage.yandexcloud.net/plusstorage/',
    RECAPTCHA_SITE_KEY: process.env.VUE_APP_RECAPTCHA_SITE_KEY || '',
    cacheTTL: 5 * 60, // second
    onlyCountries:  ['ru', 'ua', 'by', 'kz', 'am', 'uz', 'kg', 'az', 'md', 'tr', 'cn', 'tj', 'za'], // Доступные страны
    onelinkBaseUrl: "https://go.onelink.me/H68R",
    maxInt32Value: 2147483647
})