<template>
  <v-row no-gutters>
    <v-col>
      <v-row
        class="stepper-row"
        no-gutters
      >
        <v-col style="margin-top: 5px;">
          <v-row class="stepper">
            <v-btn
              class="btn-back"
              color="secondary"
              :text="true"
              :ripple="false"
              @click="onBackClick"
            >
              <v-icon
                class="icon-left"
                color="primary-base"
                size="21"
              >
                $iconify_heroicons-outline-arrow-left
              </v-icon>
              <p class="mb-0 body-s-semibold primary-base--text">
                Назад
              </p>
            </v-btn>
            <template v-for="(item, index) in items">
              <p
                :key="'p' +index"
                class="stepper-block first-step body-l-semibold mb-0"
                :style="'color: ' + getCurrentColor(index)"
              >
                {{ item.title }}
              </p>
              <v-icon
                v-if="index+1<items.length"
                :key="'icon'+index"
                class="stepper__icon"
                color="neutral-400"
                size="15"
              >
                $iconify_feather-chevron-right
              </v-icon>
            </template>
          </v-row>

          <v-row
            class="stepper-progress-bar"
            no-gutters
          >
            <div
              class="progressLineFirst"
              :style="'width: ' + getCurrentWidth() + '%'"
            />
            <div
              class="progressLineSecond"
              :style="'width: ' + (100 - getCurrentWidth()) + '%'"
            />
          </v-row>
        </v-col>
      </v-row>

      <v-row
        style="margin-top: 0"
        no-gutters
      >
        <slot />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Stepper',
    model: {
      prop: 'value',
      event: 'change',
    },

    props: {
      items: {
        type: Array,
        default: () => [],
      },
      value: {
        type: Number,
        default: 0,
        required: true,
      },
    },
    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          if (val === this.value) return

          // console.log('set, ', val)
          // this.value = val

          this.$emit('change', val)
        },
      },
      vuetifyTheme () {
        return this.$vuetify.theme.isDark
          ? this.$vuetify.theme.themes.dark
          : this.$vuetify.theme.themes.light
      },
    },
    methods: {
      getCurrentWidth () {
        return Math.round((100 * (this.value + 1)) / (this.items.length))
      },
      onBackClick () {
        if (this.internalValue > 0) {
          this.$nextTick(() => (this.internalValue = this.internalValue - 1))
        // this.internalValue--
        } else {
          // console.log('close')
          this.$emit('close')
        }
        this.$emit('clickBack')
      },
      getStepStatus (step) {
        if (step === this.internalValue) {
          return 'active'
        } else if (step < this.internalValue) {
          return 'complite'
        } else {
          return ''
        }
      },
      getCurrentColor (step) {
        const status = this.getStepStatus(step)
        switch (status) {
          case 'active':
            return this.vuetifyTheme.secondary
          case 'complite':
            return this.vuetifyTheme['neutral-900']
          default:
            return this.vuetifyTheme['neutral-400']
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.stepper {
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  .btn-back {
    position: absolute;
    left: 0;
    .icon-left {
      margin-right: 4px;
    }
  }

}

.stepper__icon {
  margin-right: 12px;
  margin-left: 12px;
}

.stepper-row {

  background: $material-light-backgroung-color;
  z-index: 2;
}

.stepper-progress-bar {
  display: flex;
  flex-direction: row;
  margin-top: 49px;
  margin-left: -$page-content-padding-size;
  margin-right: -$page-content-padding-size;

  .progressLineFirst {
    height: 1px;
    border-radius: 0 100px 100px 0;
    background: #4776e6;
  }

  .progressLineSecond {
    height: 1px;
    background-color: #f2f2f7;
  }
}
</style>
