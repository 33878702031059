import date from './date'
import app from './app'
import chat from './chat'
import yandexMap from './yandex-map'
import help from './help'
import apiKeys from './api-keys'
import defaultPath from './default-path'

// console.log('app', app)

const config = Object.freeze({
    app,
    date,
    yandexMap,
    help,
    chat,
    apiKeys,
    defaultPath,
    tooltipButtonDelay: 1000,
    tooltipDelay: 500,
    MAX_TRAN_VALUE: 10000000000,
    MAX_DAYS: 999,
    ymId: Number(process.env.VUE_APP_YANDEX_METRIKA_ID||null), 
})

export { app, chat, date, yandexMap, help, config, defaultPath }

export default {
    config,
    install (Vue) {
        Vue.prototype.$config = config
    },
}
